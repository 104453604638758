import styled from 'styled-components';

export const StyledButton = styled.button`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 10.5px 12px;
  height: 40px;
  background: ${({ theme }) => theme.colors.ghostWhite};
  border: 1px solid ${({ theme }) => theme.colors.azureishWhite};
  border-radius: 12px;
  margin-top: 58px;

  &:hover {
    opacity: 0.5;
  }
`;
