export interface ReservationLink {
  url: string;
  reservation: string;
}

export interface Reservation {
  sourceCurrency: string;
  sourceAmount?: string;
  destCurrency: string;
  dest: string;
}

export enum ECountryCode {
  Us = 'US',
  Gb = 'GB',
  Es = 'ES',
  Nl = 'NL',
  Fr = 'FR',
  Ie = 'IE',
  Ca = 'CA',
  De = 'DE',
  It = 'IT',
  Pl = 'PL',
  Dk = 'DK',
  No = 'NO',
  Se = 'SE',
  Ee = 'EE',
  Lt = 'LT',
  Lv = 'LV'
}

export interface LinkToken {
  countryCodes: ECountryCode[];
}

export interface TokenResponse {
  expiration: Date;
  link_token: string;
  request_id: string;
}

export interface ExchangeToken {
  accountId: string;
  publicToken: string;
}

export interface IProcessorTokenCreateResponse {
  processor_token: string;
  request_id: string;
}

export interface BankPayment {
  dest: string;
  sourceCurrency: string;
  destCurrency: string;
  processorToken: string;
  sourceAmount: string;
  email: string;
}

export interface BankPayout {
  source: string;
  sourceCurrency: string;
  destCurrency: string;
  processorToken: string;
  sourceAmount: string;
  email: string;
}

export interface IHistory {
  image: React.FC<React.SVGProps<SVGSVGElement>>;
  name: string;
  coin: string;
  operation: string;
  amount: string;
  all: string;
}

export enum LoadingStatuses {
  SUBMITTED = 'submitted',
  REJECTED = 'rejected'
}

export interface IDataPayment {
  sourceCurrency: string;
  sourceAmount: string;
  destCurrency: string;
  dest: string;
}

export interface IBankPayment extends IDataPayment {
  email: string;
}
export interface ISuccessWyreResponse {
  data: {
    data: {
      accountId: string;
      blockchainNetworkTx: null;
      dest: string;
      destAmount: number;
      fees: string;
      orderId: string;
      transferId: string;
    };
  };
}
