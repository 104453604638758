import styled, { css } from 'styled-components';
import { FAMILIES, WEIGHTS } from '../../../theme/fonts.const';
import { ThemeColors } from '../../consts';
import { TTheme } from '../../types';

const Input = styled('input')<{
  isError: boolean;
  paddingRight?: string;
  isIcon?: boolean;
  isSuccessBorder?: boolean;
}>`
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 16px;
  border: 1px solid
    ${({ theme: { type }, isSuccessBorder }) =>
      isSuccessBorder
        ? ThemeColors.input[type as TTheme].successBorder
        : ThemeColors.input[type as TTheme].border};
  border-radius: 8px;
  outline: none;
  width: 100%;
  height: 100%;
  padding-right: ${({ paddingRight }) => paddingRight || '16px'};

  ${(props) =>
    props.isIcon &&
    css`
      padding-right: ${props.paddingRight || '30px'};
    `}

  background: ${({ theme: { type } }) => ThemeColors.input[type as TTheme].background};
  color: ${({ theme: { type } }) => ThemeColors.input[type as TTheme].color};

  ${(props) =>
    props.isError &&
    css`
      border-color: ${props.theme.colors.red};
    `}

  &::placeholder {
    font-family: ${FAMILIES.inter};
    font-weight: ${WEIGHTS.normal};
    font-size: 16px;
    line-height: 19px;
    color: ${({ theme: { type } }) => ThemeColors.input[type as TTheme].placeholder};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:active,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: ${({ theme: { type } }) =>
      ThemeColors.input[type as TTheme].color} !important;
    -webkit-box-shadow: 0 0 0 1000px
      ${({ theme: { type } }) => ThemeColors.input[type as TTheme].background} inset !important;
  }
`;

const Label = styled.label<{ lableTop?: string }>`
  position: absolute;
  font-weight: ${WEIGHTS.medium};
  font-size: 18px;
  text-align: center;
  color: ${({ theme: { type } }) => ThemeColors.input[type as TTheme].label};
  top: ${({ lableTop }) => lableTop || '16px'};
  right: 15px;
`;

const Div = styled.div<{ width?: string; height?: string }>`
  position: relative;
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || 'auto'};

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const IconBox = styled('div')`
  position: absolute;
  right: 15px;
  top: 51%;
  transform: translateY(-50%);
  cursor: pointer;
`;

export { Div, IconBox, Input, Label };
