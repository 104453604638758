import React, { FC, createContext, useContext, useEffect, useMemo, useState } from 'react';

import { useAuth } from '../../web3auth/web3auth.provider';
import { STORAGE_KEYS } from '../consts/app-keys.const';
import { FiatCurrency, matchFiatCurrencySymbol } from '../consts/fiat-symbol.matcher';
import { UserDTO } from '../types/user.type';
import { setLocalStorage } from '../utils/localstorage.util';

export interface CurrencyContextType {
  changeCurrency: (value: string) => void;
  currency: string;
  symbol: string;
}

export const CurrencyContext = createContext<CurrencyContextType>({} as CurrencyContextType);

export function useCurrency() {
  return useContext(CurrencyContext as React.Context<CurrencyContextType>);
}

type CurrencyProviderProps = {
  children: any;
};

export const CurrencyContextProvider: FC<CurrencyProviderProps> = ({ children }) => {
  const { userInfo } = useAuth<UserDTO>();
  const [currency, setCurrency] = useState<string>(userInfo?.currencyConversion || 'USD');
  const symbol = useMemo(() => matchFiatCurrencySymbol[currency as FiatCurrency], [currency]);
  const changeCurrency = (value: string) => {
    setCurrency(value);
    setLocalStorage(STORAGE_KEYS.DEFAULT_CURRENCY, value);
  };

  useEffect(() => {
    if (userInfo) {
      changeCurrency(userInfo.currencyConversion);
    }
  }, [userInfo]);

  const memoedData = useMemo(
    () => ({
      changeCurrency,
      currency,
      symbol
    }),
    [currency]
  );

  return <CurrencyContext.Provider value={memoedData}>{children}</CurrencyContext.Provider>;
};