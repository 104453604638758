import { FormikProps } from 'formik';
import { useAuth } from '../../web3auth/web3auth.provider';
import { TokenBalance } from '../types/balance.type';
import { CoinType } from '../types/transaction.type';
import { toFixedWithoutRounding } from '../utils/to-fixed-without-rounding.util';

export const useCalculateAmount = (
  choosedBalance: TokenBalance,
  setIsChecked: (value: React.SetStateAction<boolean>) => void,
  walletFee: number | undefined | void,
  setFillCurrentBalance?: (value: React.SetStateAction<boolean>) => void,
  fn?: () => void
) => {
  const { getGasCost } = useAuth();

  const checkBoxChange = async <T>(checked: boolean, { setFieldValue }: FormikProps<T>) => {
    if (!walletFee) return;
    if (checked) {
      const gasCost = await getGasCost();
      const calculatedFee = choosedBalance.balance * (walletFee / 100);
      const currentAmount =
        choosedBalance.symbol === CoinType.MATIC
          ? choosedBalance.balance - calculatedFee - gasCost
          : choosedBalance.balance - calculatedFee;
      setFieldValue('amount', toFixedWithoutRounding(currentAmount - 0.01, 5));
      setIsChecked(true);
      setFillCurrentBalance && setFillCurrentBalance(true);
    } else {
      setFieldValue('amount', undefined);
      setIsChecked(false);
      setFillCurrentBalance && setFillCurrentBalance(false);
      fn && fn();
    }
  };

  return { checkBoxChange };
};
