/* eslint-disable @typescript-eslint/no-shadow */
import { FormikProps } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { useCurrency } from '../../common/providers/currency.provider';
import { useTheme } from '../../common/providers/theme.provider';
import { IBodyUserUpdate, UserDTO } from '../../common/types/user.type';
import { userService } from '../../services/user.service';
import { useAuth } from '../../web3auth/web3auth.provider';
import { THEME_OPTIONS } from '../constants';
import { IThemeOption, IValues } from '../types';
import { findCurrency, findTheme } from '../utils';
import { CURRENCY_OPTIONS } from '../../common/consts/fiat-symbol.matcher';
import { ICurrencyOption } from '../../common/types/balance.type';

export const useSubmit = () => {
  const { changeTheme, getCurrentTheme } = useTheme();
  const { changeCurrency } = useCurrency();

  const { authState, userInfo, setUser } = useAuth<UserDTO>();

  const [theme, setTheme] = useState<IThemeOption>(findTheme(userInfo));
  const [currency, setCurrency] = useState<ICurrencyOption>(findCurrency(userInfo));

  const { mutateAsync: updateUser, isLoading: isMutationLodaing } = useMutation(
    async (body: IBodyUserUpdate) => userService.updateUserSettings(body)
  );

  const formValues = useMemo(
    () => ({
      name: userInfo?.name ?? '',
      email: userInfo?.email ?? '',
      currency: currency?.value,
      theme: userInfo?.theme ?? theme?.value
    }),
    [userInfo?.name, userInfo?.email, userInfo?.theme, currency, theme]
  );

  const submitHandler = (e: React.FormEvent<HTMLFormElement>, props: FormikProps<IValues>) => {
    e.preventDefault();
    props.handleSubmit(e);
  };

  const onSubmit = async ({ name, email }: IValues) => {
    changeTheme(getCurrentTheme(theme.value));
    changeCurrency(currency.value);

    await updateUser({
      name,
      email,
      theme: theme.value,
      currencyConversion: currency.value,
      pubKey: ''
    });

    userInfo &&
      setUser({ ...userInfo, name, email, theme: theme.value, currencyConversion: currency.value });
  };

  const clearHandler =
    (setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void) =>
    () => {
      const currency =
        CURRENCY_OPTIONS.find((el) => el.value === userInfo?.currencyConversion) ??
        CURRENCY_OPTIONS[0];

      setFieldValue('name', userInfo?.name);
      setFieldValue('email', userInfo?.email);
      setCurrency(currency);
      changeCurrency(currency.value);
      setTheme(THEME_OPTIONS.find((el) => el.value === userInfo?.theme) ?? THEME_OPTIONS[0]);
    };

  useEffect(() => {
    if (userInfo?.theme) {
      setTheme(findTheme(userInfo));
    }

    if (userInfo?.currencyConversion) {
      setCurrency(findCurrency(userInfo));
    }
  }, [userInfo?.theme, userInfo?.currencyConversion]);

  return {
    submitHandler,
    onSubmit,
    clearHandler,
    currency,
    setCurrency,
    theme,
    setTheme,
    formValues,
    isLoading: authState === 'LOADING' || !userInfo,
    IsEmail: userInfo?.provider === 'metamask',
    isMutationLodaing
  };
};
