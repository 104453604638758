import { FormikProps, FormikState } from 'formik';
import { useState } from 'react';
import { useModal } from '../../../../common/hooks/use-modal.hook';
import { IToken } from '../../../../common/types/balance.type';
import { IFilters } from '../../../../common/types/transaction.type';
import { IValues } from '../../types';

type TxType = 'ALL' | 'COIN' | 'CRYPTO';

export const useFilter = (setFilters: (value: IFilters) => void) => {
  const { isOpen, handleClose, handleOpen } = useModal();
  const [cryptoBalance, setCryptoBalance] = useState<IToken | null>(null);

  const submitHandler = (e: React.FormEvent<HTMLFormElement>, props: FormikProps<IValues>) => {
    e.preventDefault();
    props.handleSubmit(e);
  };

  const onSubmit = (values: IValues) => {
    console.log(values);
    setFilters({
      ...values,
      coin: cryptoBalance?.symbol ?? '',
      txType: values.group2.toUpperCase() as TxType
    });
  };

  const cleatHandler =
    (resetForm: (nextState?: Partial<FormikState<IValues>> | undefined) => void) => () => {
      setCryptoBalance(null);
      resetForm();
      setFilters({ from: '', to: '', relatedActivity: false, coin: '', txType: 'ALL' });
    };

  return {
    cryptoBalance,
    setCryptoBalance,
    submitHandler,
    isOpen,
    handleClose,
    handleOpen,
    onSubmit,
    cleatHandler
  };
};