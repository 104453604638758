import React, { useEffect, useMemo, useState } from 'react';
import Modal from '../../../common/components/modal';
import { TokenBalance } from '../../../common/types/balance.type';
import { LoadingStatuses } from '../../../common/types/payment.type';
import { EFormsCrypto } from '../../../common/types/transaction.type';
import { toggleState } from '../../../common/utils';
import { useAuth } from '../../../web3auth/web3auth.provider';
import { CRYPTO_BLOCKS } from '../../constants/crypto-blocks';
import { STATUS_TEXT } from '../../constants/modal';
import { StatusLoadingContent, StatusLoadingHeader } from '../status-loading-modal';
import { Header, StyledButton, StyledText } from './index.styled';

interface IProps {
  text: string;
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  IconHover: React.FC<React.SVGProps<SVGSVGElement>>;
  form: any;
  header: string;
  balances: TokenBalance[];
  setTxHash: (value: string) => void;
  setRejected: (value: string) => void;
  type: EFormsCrypto;
}

export const CrytoBlock = ({
  Icon,
  IconHover,
  text,
  form: Form,
  header,
  balances,
  type,
  setTxHash,
  setRejected
}: IProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isTopUpOpen, setIsTopUpOpen] = useState(false);
  const [iconHover, setIconHover] = useState(false);
  const [hide, hideModal] = useState(false);
  const [isSecondOpen, setIsSecondOpen] = useState(false);
  const [status, setStatus] = React.useState<LoadingStatuses | null>(null);

  const { isTransactionLoading } = useAuth();

  useEffect(() => {
    if (status) {
      toggleState(setIsSecondOpen)();
    }
  }, [status]);

  const handleCopleteForm = (statusCheck: LoadingStatuses | null) => {
    setStatus(statusCheck);
    setIsOpen(false);
  };
  const currentStatus = status ?? LoadingStatuses.REJECTED;

  const handleIconMouseOver = () => {
    setIconHover(true);
  };

  const handleIconMouseOut = () => {
    setIconHover(false);
  };

  const BlockIcon = useMemo(() => (iconHover ? IconHover : Icon), [iconHover]);
  const TopUpForm = CRYPTO_BLOCKS[1].form;
  useEffect(() => {
    if (!isSecondOpen) {
      setStatus(null);
    }
  }, [isSecondOpen]);

  return (
    <>
      <StyledButton
        disabled={balances.length === 0}
        type="button"
        onClick={toggleState(setIsOpen)}
        formType={type}
        onMouseEnter={handleIconMouseOver}
        onMouseLeave={handleIconMouseOut}
      >
        <BlockIcon />
        <StyledText formType={type}>{text}</StyledText>
      </StyledButton>

      <Modal
        open={isOpen}
        onClose={toggleState(setIsOpen)}
        header={<Header>{header}</Header>}
        hide={hide}
        closeButton={!isTransactionLoading}
        content={
          <Form
            hideModal={hideModal}
            openTopUp={toggleState(setIsTopUpOpen)}
            closeModal={toggleState(setIsOpen)}
            balances={balances}
            setTxHash={setTxHash}
            setRejected={setRejected}
            handleCopleteForm={handleCopleteForm}
          />
        }
      />

      {type === EFormsCrypto.SEND ? (
        <Modal
          open={isTopUpOpen}
          onClose={toggleState(setIsTopUpOpen)}
          header={<Header>{CRYPTO_BLOCKS[1].header}</Header>}
          content={
            <TopUpForm
              balances={balances}
              hideModal={hideModal}
              handleCopleteForm={handleCopleteForm}
              closeModal={toggleState(setIsTopUpOpen)}
              setTxHash={setTxHash}
              setRejected={() => {}}
              openTopUp={() => {}}
            />
          }
        />
      ) : null}

      <Modal
        open={isSecondOpen}
        onClose={toggleState(setIsSecondOpen)}
        header={<StatusLoadingHeader status={currentStatus} {...STATUS_TEXT[currentStatus]} />}
        content={<StatusLoadingContent {...STATUS_TEXT[currentStatus]} />}
      />
    </>
  );
};
