import { COLORS } from '../../../theme';

export const ThemeColors = {
  icon: { light: { color: COLORS.primary }, dark: { color: COLORS.white } },
  button: {
    light: { border: COLORS.azureishWhite, background: COLORS.ghostWhite },
    dark: { border: COLORS.lightPeriwinkle, background: COLORS.darkBlue100 }
  },
  status: {
    light: {
      success: COLORS.successGreen,
      error: COLORS.errorRed,
      submit: COLORS.darkBluePrimary
    },
    dark: {
      success: COLORS.successGreen50,
      error: COLORS.errorRed30,
      submit: COLORS.periwinkle
    }
  },
  blueText: {
    light: {
      color: COLORS.primaryBlue
    },
    dark: {
      color: COLORS.blue40
    }
  }
};
