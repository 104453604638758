import styled from 'styled-components';
import { Text } from '../../../index.styled';
import { SIZES } from '../../../../../theme/fonts.const';
import { ThemeColors } from '../../../../consts';
import { TTheme } from '../../../../types';

export const Balance = styled(Text)`
  font-size: ${SIZES.m};
  color: ${({ theme: { type } }) => ThemeColors.balance[type as TTheme].color};
`;
