import { Box } from '../../..';
import { DexCoin, actionIconMatcher, coinIconMatcher } from '../../../../consts/icons.const';
import { CoinType, EFormsCrypto, TransactionType } from '../../../../types/transaction.type';
import { Coin, ERC_20_IMAGES } from '../../constants';
import { StyledImage } from './index.styled';

interface IProps {
  first: EFormsCrypto;
  second: CoinType;
  txType: TransactionType;
}

export const RenderImages = ({ first, second, txType }: IProps) => {
  let images;
  if (txType === TransactionType.DEX_ERC0_TRANSFER) {
    images = [actionIconMatcher[first], DexCoin];
  } else {
    images =
      txType === TransactionType.DEX_INTERACTION
        ? ERC_20_IMAGES
        : [actionIconMatcher[first], coinIconMatcher[second] || Coin];
  }
  return (
    <Box width="80px" position="relative">
      {images.map((El, i) => (
        <StyledImage
          index={2 - i}
          key={i}
          left={i === 0 ? 0 : '26px'}
          formType={i === 0 ? first : null}
        >
          <El style={{ width: '41px', height: '41px' }} />
        </StyledImage>
      ))}
    </Box>
  );
};