import styled from 'styled-components';
import { Box } from '../../../common/components';
import { ThemeColors } from '../../theme';
import { TTheme } from '../../../common/types';

export const Container = styled(Box)`
  margin-top: 20px;

  & > div:first-child {
    margin-right: 12px;
  }

  & > div:last-child {
    margin-left: 12px;
  }
`;

export const CoinBalanceBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  svg {
    fill: ${({ theme: { type } }) => ThemeColors.balance[type as TTheme].svg};
  }
  &:hover {
    svg {
      fill: ${({ theme: { type } }) => ThemeColors.balance[type as TTheme].hover};
    }
  }
`;
