import { COLORS } from '../../theme';

export const ThemeColors = {
  container: {
    light: {
      background: COLORS.emptyLight,
      border: COLORS.blonde
    },
    dark: {
      background: COLORS.emptyDark,
      border: COLORS.bronzeYellow
    }
  }
};
