import styled, { keyframes } from 'styled-components';
import { ThemeColors } from '../../consts';
import { TTheme } from '../../types';

const spin = keyframes`
     to {
        -webkit-transform: rotate(360deg);
      }
`;

const Spinner = styled('div')<{ width?: string; margin?: string }>`
  display: block;
  width: ${({ width }) => width || '50px'};
  height: ${({ width }) => width || '50px'};
  border: ${({ width }) => (width ? `calc( ${width} / 10)` : '5px')} solid
    ${({ theme }) => theme.colors.princetonOrange};
  border-radius: 50%;
  border-top-color: ${({ theme }) => theme.colors.semiTransparentWhite};
  animation: ${spin} 1s linear infinite;
  margin: ${({ margin }) => margin || '0'};
`;

const SplashScreen = styled('div')<{ isLoading: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => ThemeColors.maincontainer[theme.type as TTheme].background};
  overflow: hidden;
  z-index: 100;

  ${({ isLoading }) =>
    isLoading &&
    `
    div:nth-child(4) {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
   
    & .top {
    position: absolute;
    width: 670px;

    left: 425.09px;
    top: -413.17px;
  }

  & .left {
    position: absolute;
    left: -300px;
    top: 83px;
    height: 1055px;
  }

  & .right {
    position: absolute;
    left: calc(100% - 421px);
    top: calc(100% - 549px);
    width: 670px;
  }
  
  `}
`;

export { Spinner, SplashScreen };
