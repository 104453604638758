import { CheckboxDisplay, CheckboxInput, CheckboxText, Label } from './index.styled';
import { IProps } from './types';

export const Checkbox = ({
  checked,
  text,
  active,
  onChange,
  width = '190px',
  ...res
}: IProps & { width?: string }) => (
  <Label width={width}>
    <CheckboxInput disabled={!active} onChange={onChange} {...{ checked }} {...res} />
    <CheckboxDisplay />
    <CheckboxText>{text}</CheckboxText>
  </Label>
);
