/* eslint-disable react/jsx-no-useless-fragment */

import { ITransaction } from '../../../../../../types/transaction.type';
import { Box } from '../../../../../index.styled';
import { Text } from '../transaction-details/index.styled';
import { LineContainer } from './index.styled';
import { generateExtraData } from './util';

export const ExtraInfo = (props: ITransaction['dexEvent']) => (
  <LineContainer>
    {generateExtraData(props).map(({ condition, text, margin, title }) => (
      <>
        {condition ? (
          <Box key={text} justify="space-between" margin={margin}>
            <Text>{title}</Text>
            <Text>{text}</Text>
          </Box>
        ) : null}
      </>
    ))}
  </LineContainer>
);
