import styled from 'styled-components';
import checkBoxArrow from '../../../../assets/icons/checkBoxArrow.svg';
import { FAMILIES, WEIGHTS } from '../../../theme/fonts.const';
import { StyledText } from '../index.styled';
import { IProps } from './types';

export const Label = styled.label<{ width: string }>`
  display: flex;
  gap: 5px;
  align-items: center;
  margin: 2px;
  width: ${({ width }) => width};
`;

export const CheckboxDisplay = styled.div``;
export const CheckboxText = styled(StyledText)`
  font-family: ${FAMILIES.inter};
  font-weight: ${WEIGHTS.normal};
  font-size: 14px;
`;

export const CheckboxInput = styled.input.attrs((props: IProps) => ({
  type: 'checkbox',
  ...props
}))`
  display: none;

  & + ${CheckboxDisplay} {
    box-sizing: border-box;
    background: ${(props) => props.theme.colors.transparent};
    border: 1px solid ${(props) => props.theme.colors.slateGray};
    border-radius: 2px;
    width: 16px;
    height: 16px;
  }

  &:hover + ${CheckboxDisplay} {
    opacity: 0.5;
  }

  &:disabled + ${CheckboxDisplay} {
    opacity: 0.5;
  }

  &:checked + ${CheckboxDisplay} {
    background-image: url(${checkBoxArrow});
    background-repeat: no-repeat;
    background-position: center;
  }
`;
