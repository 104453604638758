import { useNavigate } from 'react-router-dom';
import { Box, Spinner } from '../common/components';
import { RenderTransactions } from '../common/components/render-transactions';
import { usePagination } from '../common/hooks/use-pagination.hook';
import { useTransactionHistory } from '../common/hooks/use-transaction-history.hook';
import { RenderTable } from './components/table';

import { ReactComponent as Arrow } from '../../assets/icons/arrowLeft.svg';
import { IFilters } from '../common/types/transaction.type';
import { StyledBox, StyledButton } from './index.styled';

export const History = () => {
  const { page, handlePageChange, setPage } = usePagination();
  const { data, isLoading, setFilters } = useTransactionHistory(page, false);
  const navigate = useNavigate();
  const handleNavigate = () => navigate('/home');

  const updateFilters = (value: IFilters) => {
    setFilters(value);
    setPage(1);
  };

  return (
    <Box justify="space-between" changeDirection height="100%">
      <StyledBox changeDirection>
        <div>
          <StyledButton onClick={handleNavigate} type="button">
            <Arrow />
            Back to Home
          </StyledButton>
        </div>

        <RenderTable
          clickHandler={handlePageChange}
          page={page}
          isLoading={isLoading}
          tableTitle="Transaction History"
          total={data?.total ?? 0}
          setFilters={updateFilters}
        >
          {isLoading ? (
            <Box width="100%" justify="center">
              <Spinner />
            </Box>
          ) : (
            <RenderTransactions items={data?.transactions ?? []} isCrop={false} />
          )}
        </RenderTable>
      </StyledBox>
    </Box>
  );
};
