import styled from 'styled-components';

export const StyledDiv = styled.div`
  margin: 0 0 15px 0;
  display: flex;
  margin-top: 20px;

  button:first-child {
    margin-right: 12px;
  }

  button:last-child {
    margin-left: 12px;
  }

  button:nth-child(2) {
    margin: 0 12px;
  }
`;
