import { TermsAndConditions } from '../app-info/TermsAndConditions';
import { PrivacyPolicy } from '../app-info/PrivacyPolicy';
import { APP_KEYS } from '../common/consts';
import { History } from '../history';
import { TopCryptos } from '../history/top-cryptos.page';
import { Home } from '../home';
import { SettingPageContainer } from '../setting';

export const PRIVATE_ROUTES = [
  { path: APP_KEYS.ROUTER_KEYS.HOME, element: <Home /> },
  { path: APP_KEYS.ROUTER_KEYS.SETTINGS, element: <SettingPageContainer /> },
  { path: APP_KEYS.ROUTER_KEYS.HISTORY, element: <History /> },
  { path: APP_KEYS.ROUTER_KEYS.TOP_CRYPTOS, element: <TopCryptos /> },
  { path: APP_KEYS.ROUTER_KEYS.TERMS_AND_CODITIONS, element: <TermsAndConditions /> },
  { path: APP_KEYS.ROUTER_KEYS.PRIVACY_POLICY, element: <PrivacyPolicy /> }
];
