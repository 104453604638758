import { useState } from 'react';
import { addresseFormat } from '../../../../../../../../utils';
import { copyText } from '../../../../../../../../utils/copy-text.util';
import { Avatar } from '../../../../../../../avatar';

import { ReactComponent as Copy } from '../../../../../../../../../../assets/icons/copy.svg';

import { StyledIcon } from '../../../../../../../header/components/account-info/index.styled';
import { Box, StyledText } from '../../../../../../../index.styled';
import { ArrowTooltip } from '../../../../../../../tooltip';
import { AccountText, CopyButton } from '../../index.styled';

interface IProps {
  account: string;
  senderImage: string | null;
}

export const AccountSender = ({ account, senderImage }: IProps) => {
  const [isActive, setIsActive] = useState(false);

  const tooltipText = isActive ? 'Copied' : 'Copy Link';

  const CkickHandler = () => {
    setIsActive(true);
    copyText(account)();
  };

  const handleMouseLeave = () => {
    setTimeout(() => {
      setIsActive(false);
    }, 200);
  };

  return (
    <Box onMouseLeave={handleMouseLeave} align="center">
      <AccountText>
        <Avatar width={34} src={senderImage} /> {addresseFormat(account, 7, 6)}
      </AccountText>

      <ArrowTooltip
        title={
          <Box align="center">
            <StyledIcon isActive={isActive} />
            <StyledText fontSize="12px">{tooltipText}</StyledText>
          </Box>
        }
      >
        <CopyButton type="button" onClick={CkickHandler}>
          <Copy />
        </CopyButton>
      </ArrowTooltip>
    </Box>
  );
};
