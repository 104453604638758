import { getIn, useFormikContext } from 'formik';
import { FC, useEffect, useMemo } from 'react';
import { COLORS } from '../../../theme';
import { Text } from '../global-typography';
import * as Styled from './input.styled';

type InputProps = {
  placeholder?: string;
  name: string;
  label?: string | JSX.Element;
  Icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  type?: string;
  width?: string;
  height?: string;
  paddingRight?: string;
  onChange?: (value: any) => void;
  readonly?: boolean;
  pattern?: string;
  lableTop?: string;
  value?: string;
  isSuccessBorder?: boolean;
  disabled?: boolean;
  extraChange?: (amount: string) => () => Promise<void>;
};

export const Input: FC<InputProps> = ({
  placeholder,
  name,
  label,
  type,
  width,
  height,
  readonly,
  paddingRight,
  Icon,
  pattern,
  onChange,
  lableTop,
  value,
  isSuccessBorder,
  disabled = false,
  extraChange
}) => {
  const { values, setFieldValue, errors, setFieldTouched, touched } = useFormikContext();

  const isError = useMemo(
    () => getIn(errors, name) !== undefined && getIn(touched, name),
    [errors]
  );

  useEffect(() => {
    if (onChange && !isError) {
      onChange(getIn(values, name));
    }
  }, [getIn(values, name), value]);

  useEffect(() => {
    if (value) {
      setFieldValue(name, value);
    }
  }, [value]);

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldTouched(name, true);
    if (e.target.validity.valid) {
      setFieldValue(name, e.target.value);
    }

    extraChange && (await extraChange(e.target.value)());
  };

  return (
    <Styled.Div width={width} height={height}>
      {label && (
        <Styled.Label htmlFor={name} lableTop={lableTop}>
          {label}
        </Styled.Label>
      )}
      <Styled.Input
        isSuccessBorder={isSuccessBorder}
        type={type ?? 'text'}
        onChange={handleChange}
        value={getIn(values, name) ?? ''}
        readOnly={readonly}
        placeholder={placeholder}
        name={name}
        disabled={disabled}
        pattern={pattern}
        isIcon={Icon !== undefined}
        isError={isError}
        paddingRight={paddingRight}
      />

      {Icon && (
        <Styled.IconBox>
          <Icon />
        </Styled.IconBox>
      )}

      {isError && (
        <Text
          style={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            position: 'relative',
            top: '10px'
          }}
          margin={0}
          align="right"
          fontWeight={400}
          fontSize="12px"
          color={COLORS.red}
        >
          {getIn(errors, name)}
        </Text>
      )}
    </Styled.Div>
  );
};
