import React from 'react';
import { useQueryClient } from 'react-query';
import { QUERY_KEYS } from '../../../../common/consts/app-keys.const';
import { useTransak } from '../../../../common/hooks/use-transak.hook';
import { useCurrency } from '../../../../common/providers/currency.provider';
import { LoadingStatuses } from '../../../../common/types/payment.type';
import { IOption } from '../../../../common/types/services.type';
import { UserDTO } from '../../../../common/types/user.type';
import { useAuth } from '../../../../web3auth/web3auth.provider';
import { COINS } from '../../../constants/coin';
import { IValues } from '../types';
import { useUnlimit } from '../../../../common/hooks/use-unlimit.hook';
import { CoinType } from '../../../../common/types/transaction.type';

export const useCashout = (
  handleCopleteForm: (statusCheck: LoadingStatuses | null) => void,
  hideModal: (value: boolean) => void
) => {
  const { balance } = useAuth<UserDTO>();
  const { currency } = useCurrency();
  const [selectValue, setSelectValue] = React.useState<IOption>(COINS[0]);
  const [isDisplayed, setIsDisplayed] = React.useState(false);
  const queryClient = useQueryClient();

  const onWidgetClose = () => {
    hideModal(false);
  };

  const onOrderSuccess = () => {
    hideModal(false);
    queryClient.invalidateQueries([QUERY_KEYS.HISTORY]);
    handleCopleteForm(LoadingStatuses.SUBMITTED);
  };

  const onOrderFailed = () => {
    hideModal(false);
    handleCopleteForm(LoadingStatuses.REJECTED);
  };

  const { initTransak } = useTransak(onWidgetClose, onOrderSuccess, onOrderFailed);
  const { initUnlimit } = useUnlimit();

  const handleSubmit = async (values: IValues) => {
    if (!isDisplayed) {
      setIsDisplayed(true);
    }

    if (isDisplayed && values.group2) {
      if (values.group2 === 'transak') {
        try {
          initTransak({
            ramp: 'SELL',
            cryptoCurrency: values.token,
            fiatCurrency: currency,
            amount: parseFloat(values.amount)
          });
        } catch (e) {
          console.error(e);
          handleCopleteForm(LoadingStatuses.REJECTED);
        }
      }
      if (values.group2 === 'unlimit') {
        try {
          initUnlimit({
            ramp: 'SELL',
            cryptoCurrency: values.token as CoinType,
            fiatCurrency: currency,
            amount: parseFloat(values.amount)
          });
        } catch (e) {
          console.error(e);
          handleCopleteForm(LoadingStatuses.REJECTED);
        }
      }
    }
  };

  return {
    balance,
    selectValue,
    setSelectValue,
    isDisplayed,
    handleSubmit,
    isLoading: false,
    tranferLoading: false
  };
};
