import styled from 'styled-components';
import { StyledText } from '../../../../../common/components';

export const Title = styled(StyledText)`
  min-width: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 16px;
  font-weight: 500;
`;
