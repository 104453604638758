import styled from 'styled-components';
import { ThemeColors } from '../../theme';
import { TTheme } from '../../../../../../types';
import { FAMILIES } from '../../../../../../../theme/fonts.const';

export const Text = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme: { type } }) => ThemeColors.status[type as TTheme].text};
  display: flex;
  align-items: center;
  font-family: ${FAMILIES.inter};

  svg {
    margin-right: 5px;
    stroke: ${({ theme: { type } }) => ThemeColors.status[type as TTheme].text};
  }
`;
