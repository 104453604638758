import toast, { Toaster } from 'react-hot-toast';
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { MagicSDKAdditionalConfiguration } from 'magic-sdk';
import { OAuthExtension } from '@magic-ext/oauth';
import { MainRouter } from '../navigation';

import '../../__mocks__';
import '../../style.css';

import { CurrencyContextProvider } from '../common/providers/currency.provider';
import { ThemeContextProvider } from '../common/providers/theme.provider';
import { AuthProvider } from '../web3auth/web3auth.provider';
import * as Styled from './app.styled';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      cacheTime: Infinity
    }
  },
  queryCache: new QueryCache({
    onError: (error: any, query) => {
      // 🎉 only show error toasts if we already have data in the cache
      // which indicates a failed background update
      if (query.state.data !== undefined) {
        toast.error(`Something went wrong: ${error.message}`);
      }
    }
  })
});

const API_KEY = process.env.REACT_APP_MAGIC_LINK_API_KEY || '';
const config: MagicSDKAdditionalConfiguration<string, OAuthExtension[]> = {
  extensions: [new OAuthExtension()],
  network: {
    rpcUrl: process.env.REACT_APP_RPC_TARGER || '',
    chainId: Number(process.env.REACT_APP_CHAIN_ID) || 137
  },
  deferPreload: true
};
const AppContainer = () => (
  <AuthProvider apiKey={API_KEY} config={config}>
    <ThemeContextProvider>
      <CurrencyContextProvider>
        <QueryClientProvider client={queryClient}>
          <Styled.GlobalStyles />
          <MainRouter />
          <ReactQueryDevtools initialIsOpen={false} />
          <Toaster position="top-right" reverseOrder />
        </QueryClientProvider>
      </CurrencyContextProvider>
    </ThemeContextProvider>
  </AuthProvider>
);

export default AppContainer;
