import React from 'react';
import { RenderLinks } from '../../../home/components/render-links';
import { WEIGHTS } from '../../../theme/fonts.const';
import { Container, StyledText } from './index.styled';

export const Footer = () => (
  <Container>
    <StyledText fontSize="12px" fontWeight={WEIGHTS.normal}>
      Copyright © 2024 All Rights Reserved by Watt2Trade
    </StyledText>
    <RenderLinks />
  </Container>
);
