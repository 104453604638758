import { EnhancedWithAuthHttpService } from '../common/services/http-auth.service';
import { HttpFactoryService } from '../common/services/http-factory.service';
import { ESort } from '../common/types';
import { ExpectedFromQuery } from '../common/types/services.type';
import {
  CoinType,
  IGetTransactionBody,
  IHistoryResponse,
  TopCryptosResponse
} from '../common/types/transaction.type';

class TransactionService {
  constructor(private httpService: EnhancedWithAuthHttpService) {}

  async getHistory(
    pubKey: string,
    skip: number,
    sort: ESort,
    volumeFrom: string,
    volumeTo: string,
    selectedCoin: string,
    relatedActivity: boolean,
    rate: string,
    txType: 'ALL' | 'COIN' | 'CRYPTO'
  ): Promise<ExpectedFromQuery<IHistoryResponse>> {
    return this.httpService.post<IHistoryResponse, IGetTransactionBody>(
      `transaction/me?rate=${rate}`,
      {
        pubKey,
        sort,
        skip,
        limit: 10,
        txType,
        onlyDex: relatedActivity,
        volumeFrom: volumeFrom !== '' ? parseFloat(volumeFrom) : undefined,
        volumeTo: volumeTo !== '' ? parseFloat(volumeTo) : undefined,
        selectedCoin: selectedCoin !== '' ? (selectedCoin as CoinType) : undefined
      }
    );
  }

  async sendTransfer(transferId: string, address: string, type: string) {
    return this.httpService.post(`transaction/bank-transfer/${transferId}/${address}`, { type });
  }

  async getTopCryptos(
    pubKey: string,
    skip: number,
    nativeBalance: number,
    rate: string
  ): Promise<ExpectedFromQuery<TopCryptosResponse>> {
    return this.httpService.post<TopCryptosResponse, any>(
      `analytics?rate=${rate}&limit=10&skip=${skip}`,
      {
        pubKey,
        nativeBalance
      }
    );
  }
}
const httpService = HttpFactoryService.createAuthHttpService();
export const transactionService = new TransactionService(httpService);