/* eslint-disable max-len */
import Select from 'react-select';
import styled from 'styled-components';
import { FAMILIES, WEIGHTS } from '../../../theme/fonts.const';
import { ThemeColors } from '../../consts';
import { TTheme } from '../../types';

export const StyledSelect = styled(Select)<{ isBattery: boolean; isCrop: boolean }>`
  min-width: 10.9375rem;

  .Select__single-value {
    color: ${({ theme: { type } }) => ThemeColors.text[type as TTheme].color} !important;
  }

  .Select__control {
    cursor: pointer;
    position: relative;
    border: 1px solid ${({ theme: { type } }) => ThemeColors.select[type as TTheme].border};
    width: 100%;
    height: 3.5rem;
    border-radius: 12px;
    min-height: auto;
    background-color: ${({ theme: { type } }) => ThemeColors.select[type as TTheme].background};

    &:hover {
      background-color: ${({ theme: { type } }) =>
        ThemeColors.select[type as TTheme].hoverBackground};
      border: ${({ isBattery, theme }) =>
        `${isBattery ? 'none' : `1px solid ${theme.colors.azureishWhite}`}`};
    }

    ${({ isBattery, theme }) =>
      isBattery &&
      `
      border: none;

      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 5px;
        height: 23px;
        right: -8px;
        background: linear-gradient(135deg, #ffd63f 0%, #ffb11a 52.89%, #ffd63f 100%);
        border-radius: 0px 5px 5px 0px;
      }

      &::before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 12px;
        padding: 1px;
        width: 100%;
        background: linear-gradient(135deg, #ffd63f 0%, #ffb11a 52.89%, #ffd63f 100%);
        -webkit-mask: linear-gradient(${theme.colors.white} 0 0) content-box, linear-gradient(${theme.colors.white} 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        pointer-events: none;
        }
    `}
  }
  ${({ isCrop }) =>
    isCrop &&
    `
    .Select__single-value {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100px;
      position: relative;
    }`}

  .Select__control--is-focused {
    border-color: ${({ isBattery }) =>
      isBattery ? 'none' : ({ theme }) => theme.colors.azureishWhite}!important;
    box-shadow: none !important;
  }

  .Select__input-containe {
    padding: 0;
  }

  .Select__value-container {
    height: 100%;
    display: inline-grid;
    align-items: center;
    padding: 14px 16px;
    position: relative;
  }

  ${({ isCrop, theme: { type } }) =>
    isCrop &&
    `
    &::after {
      content: '...';
      position: absolute;
      top: 16px;
      right: 51px;
      color: ${ThemeColors.text[type as TTheme].color}!important;    }
  `}

  .Select__value-container--has-value {
    position: relative;
  }
  .Select__indicators {
    height: 100%;
  }
  .Select__placeholder {
    font-family: ${FAMILIES.inter};
    font-weight: ${WEIGHTS.normal};
    font-size: 0.875rem;
    color: ${({ theme: { type } }) => ThemeColors.text[type as TTheme].color} !important;
  }

  .Select__single-value {
    p {
      font-family: ${FAMILIES.inter};
      font-weight: ${WEIGHTS.medium};
      font-size: 0.875rem;
      color: ${({ theme: { type } }) => ThemeColors.text[type as TTheme].color}!important;
    }
  }

  .Select__input-container {
    font-family: ${FAMILIES.inter};
    font-weight: ${WEIGHTS.normal};
    font-size: 0.875rem;
    color: ${({ theme: { type } }) => ThemeColors.text[type as TTheme].color}!important;
  }
  .css-1u9des2-indicatorSeparator {
    display: none;
  }
  .Select__menu {
    font-family: ${FAMILIES.inter};
    font-weight: ${WEIGHTS.normal};
    font-size: 0.875rem;
    background-color: ${({ theme: { type } }) =>
      ThemeColors.select[type as TTheme].background} !important;
    color: ${({ theme: { type } }) => ThemeColors.text[type as TTheme].color}!important;
  }

  .Select__dropdown-indicator {
    height: 100%;
    align-items: center;
  }

  .Select__option {
    z-index: 10000;
    position: relative;
    background-color: ${({ theme: { type } }) =>
      ThemeColors.select[type as TTheme].background} !important;
    color: ${({ theme: { type } }) => ThemeColors.text[type as TTheme].color}!important;

    &:hover {
      background-color: ${({ theme: { type } }) =>
        ThemeColors.select[type as TTheme].hoverBackground} !important;
    }

    p {
      color: ${({ theme: { type } }) => ThemeColors.text[type as TTheme].color}!important;
      font-size: 13px;
    }
  }

  .css-tj5bde-Svg {
    color: ${({ theme: { type } }) => ThemeColors.text[type as TTheme].color}!important;
  }
`;
