import styled from 'styled-components';

import { Box, Text } from '..';
import { FAMILIES, WEIGHTS } from '../../../theme/fonts.const';
import { TransactionStatusTypes } from '../../types/transaction.type';
import { TTheme } from '../../types';
import { ThemeColors } from './theme';

import { StyledContainer } from '../../../home/index.styled';

export const StyledTitle = styled(Text)<{ status: TransactionStatusTypes }>`
  color: ${({ status, theme: { type } }) => {
    if (status === TransactionStatusTypes.CANCELED) {
      return ThemeColors.status[type as TTheme].error;
    }
    if (status === TransactionStatusTypes.SUBMITED) {
      return ThemeColors.status[type as TTheme].submit;
    }
    return ThemeColors.status[type as TTheme].success;
  }};

  font-family: ${FAMILIES.inter};
  font-size: 14px;
  font-weight: ${WEIGHTS.normal};
`;

export const ViewDetails = styled.button<{ isDetails: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 13px 10px 12px;
  cursor: pointer;
  color: ${({ theme: { type } }) => ThemeColors.icon[type as TTheme].color};
  margin-left: 32px;
  background: ${({ theme: { type } }) => ThemeColors.button[type as TTheme].background};
  border: 1px solid ${({ theme: { type } }) => ThemeColors.button[type as TTheme].border};
  border-radius: 12px;

  display: ${({ isDetails }) => (isDetails ? 'block' : 'none')};

  &:hover {
    opacity: 0.5;
  }

  svg {
    margin-left: 10px;
    fill: ${({ theme: { type } }) => ThemeColors.icon[type as TTheme].color};
  }
`;

export const Container = styled(StyledContainer)`
  overflow: hidden;

  &:hover {
    .action {
      transform: translateX(0);
    }
  }
`;

export const Action = styled(Box)<{ isDetails: boolean }>`
  transition: 0.3s ease-in-out;
  transform: ${({ isDetails }) => (isDetails ? 'translateX(155px)' : 'inherit')};
`;
