import styled from 'styled-components';
import { ThemeColors } from '../../../../consts';
import { TTheme } from '../../../../types';
import { Text } from '../../../index.styled';
import { SIZES } from '../../../../../theme/fonts.const';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.75rem;
  border: 1px solid ${({ theme: { type } }) => ThemeColors.container[type as TTheme].border};
  padding: 0.25rem 0.75rem;
  height: fit-content;
  margin: 0 2rem 0;
`;

export const TotalBalance = styled(Text)`
  font-size: ${SIZES.s};
  color: ${({ theme: { type } }) => ThemeColors.balance[type as TTheme].color};
`;
