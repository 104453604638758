export const getBorder = (index: number, length: number) => {
  if (length === 1) {
    return '12px';
  }

  if (index === 0) return '12px 12px 0px 0px';

  if (length === 2 && index === length - 1) {
    return '0px 0px 12px 12px';
  }

  if (index === length - 1) return '0px 0px 12px 12px';

  return '0';
};
