/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { useState } from 'react';
import { useMutation } from 'react-query';
import { useAuth } from '../../web3auth/web3auth.provider';
import { QUERY_KEYS, STORAGE_KEYS } from '../consts/app-keys.const';
import { authService } from '../services';
import { CreateUserDTO, UserDTO } from '../types/user.type';
import { userService } from '../../services/user.service';
import { deleteLocalStoage, setLocalStorage } from '../utils/localstorage.util';

export const useVerify = () => {
  const [isVerifying, setIsVerifying] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { setLoginError, login, logout, setUser, redirectHandler, setAuthState } =
    useAuth<UserDTO>();

  const logoutUser = async () => {
    deleteLocalStoage(STORAGE_KEYS.TOKEN);
    logout();
  };

  const { mutateAsync: verifyTokenAsync } = useMutation(
    [QUERY_KEYS.TOKEN, 'verify'],
    () => authService.verifyToken(),
    {
      onSuccess: (data) => {
        if (data) {
          setUser(data);
          setIsVerifying(false);
        }
      },
      onError: async (err: any) => {
        if (err.response.status === 401) {
          setIsVerifying(false);
          await logoutUser();
        }
        if (err.response.status === 403) {
          setLoginError(err.response.data.message);
          setIsVerifying(false);
          await logoutUser();
        }
      }
    }
  );
  const { mutateAsync: loginAsync } = useMutation(
    ['auth'],
    (payload: CreateUserDTO) => authService.login(payload),
    {
      onSuccess: (data) => {
        if (data) {
          setUser(data);
          setLocalStorage(STORAGE_KEYS.TOKEN, data.jwtToken);
        }
      },
      onError: async (err: any) => {
        if (err.response.status === 403) {
          setLoginError(err.response.data.message);
          setIsVerifying(false);
          await logout();
        }
      }
    }
  );

  const handleLoginWithEmail = async (email: string) => {
    setIsVerifying(true);
    const result = await login('email_passwordless', email);
    if (result) {
      const payload = {
        email: result.email ?? '',
        name: result.email ?? '',
        loginProvider: 'jwt',
        avatar: '',
        wallet: result.publicAddress ?? '',
        didToken: result.didToken
      };
      loginAsync(payload);
    }
  };

  const handleLoginWithRedirect = async (
    oauthProvider: 'google' | 'twitter' | 'linkedin' | 'facebook' | 'discord' | 'apple'
  ) => {
    await login(oauthProvider);
  };

  const handleRedirectResult = async () => {
    setIsVerifying(true);
    const result = await redirectHandler();
    if (result) {
      const payload = {
        email: result.oauth.userInfo.email ?? '',
        name:
          result.oauth.userInfo.name ??
          result.oauth.userInfo.preferredUsername ??
          result.oauth.userInfo.email ??
          '',
        loginProvider: result.oauth.provider,
        avatar: result.oauth.userInfo.picture ?? '',
        wallet: result.magic.userMetadata.publicAddress ?? '',
        didToken: result.magic.idToken
      };
      loginAsync(payload);

      setAuthState('LOGGED');
    }
  };

  const deleteAccount = async (pubKey: string) => {
    setIsDeleting(true);
    await userService.removeAccount(pubKey);
    await logoutUser();
    setIsDeleting(false);
  };

  const verifyToken = () => {
    setIsVerifying(true);
    verifyTokenAsync();
  };

  return {
    handleLoginWithEmail,
    handleLoginWithRedirect,
    handleRedirectResult,
    logout: logoutUser,
    verify: verifyToken,
    isVerifying,
    deleteAccount,
    isDeleting
  };
};
