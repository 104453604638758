import React, { FC } from 'react';
import { Grid } from '@mui/material';
import Modal from '../../common/components/modal';
import { Box, Spinner, StyledButton, StyledText } from '../../common/components';
import { BorderedButton } from '../../common/components/header/components/account-info/index.styled';
import { ButtonContainer } from './index.styled';
import { useVerify } from '../../common/hooks/use-verify.hooks';

type ConfirmModalProps = {
  isOpen: boolean;
  handleClose: () => void;
};
export const ConfirmModal: FC<ConfirmModalProps> = ({ isOpen, handleClose }) => {
  const { deleteAccount, isDeleting } = useVerify();

  const handleClickConfirm = () => {
    deleteAccount('');
  };

  return (
    <Modal
      header={
        <Grid container justifyContent="center" alignItems="center">
          <StyledText textAlign="center" fontSize="22px" lineHeight="27px" fontWeight="500">
            Are you sure?
          </StyledText>
        </Grid>
      }
      open={isOpen}
      closeButton={false}
      onClose={handleClose}
      content={
        <ButtonContainer
          container
          paddingTop={4}
          direction="row"
          columnGap={2}
          justifyContent="center"
          alignItems="center"
        >
          <BorderedButton disabled={isDeleting} onClick={handleClose}>
            Cancel
          </BorderedButton>
          <StyledButton disabled={isDeleting} onClick={handleClickConfirm}>
            {isDeleting ? (
              <Box justify="center">
                <Spinner width="25px" />
              </Box>
            ) : (
              'Confirm'
            )}
          </StyledButton>
        </ButtonContainer>
      }
    />
  );
};
