import { Link, useLocation } from 'react-router-dom';
import { Box } from '../../../common/components';
import { SIZES, WEIGHTS } from '../../../theme/fonts.const';
import { DESCRIPTION_LINKS } from '../../constants/description-links';
import { StyledBorder, StyledBox, StyledLink, StyledText, UnderlineText } from './index.styled';

type BottomLinkProps = {
  route?: string | null;
  text: string;
  src?: string;
  children: any;
};

const BottomLink = ({ route, text, src, children }: BottomLinkProps) =>
  route ? (
    <Link to={route} key={text} style={{ textDecoration: 'none' }}>
      {children}
    </Link>
  ) : (
    <a href={src}>{children}</a>
  );

export const RenderLinks = () => {
  const location = useLocation();

  return (
    <StyledBox>
      {DESCRIPTION_LINKS.map(({ src, text, isBorder, secondText, route, showOnlyRoute }) => (
        <BottomLink key={text} route={route} src={src} text={text}>
          <StyledLink>
            <Box align="center">
              <StyledText fontSize={SIZES.l} fontWeight={WEIGHTS.normal}>
                {!showOnlyRoute.isShow
                  ? text
                  : showOnlyRoute.route === location.pathname
                  ? text
                  : ''}
              </StyledText>

              {isBorder && <StyledBorder />}

              {secondText && (
                <UnderlineText margin="0 0 0 6px" fontSize={SIZES.l} fontWeight={WEIGHTS.normal}>
                  {secondText}
                </UnderlineText>
              )}
            </Box>
          </StyledLink>
        </BottomLink>
      ))}
    </StyledBox>
  );
};
