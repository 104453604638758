import { TransactionStatusTypes } from '../../../common/types/transaction.type';
import { COLORS } from '../../../theme';

export const ThemeColors = {
  operation: {
    light: {
      [TransactionStatusTypes.CANCELED]: COLORS.darkBluePrimary,
      [TransactionStatusTypes.CONFIRMED]: COLORS.primary,
      [TransactionStatusTypes.SUBMITED]: COLORS.darkBluePrimary
    },
    dark: {
      [TransactionStatusTypes.CANCELED]: COLORS.periwinkle,
      [TransactionStatusTypes.CONFIRMED]: COLORS.white,
      [TransactionStatusTypes.SUBMITED]: COLORS.periwinkle
    }
  },

  amount: {
    light: {
      color: COLORS.darkBluePrimary
    },
    dark: {
      color: COLORS.periwinkle
    }
  }
};
