import styled, { css } from 'styled-components';
import { Box, ContainedButton } from '../common/components';
import { FAMILIES, WEIGHTS } from '../theme/fonts.const';
import { TTheme } from '../common/types';
import { ThemeColors as MainTheme } from '../common/consts';

export const StyledBox = styled(Box)`
  margin: 34px 146px;

  @media screen and (max-width: 1000px) {
    margin: 0 2.25rem;
  }
`;

export const Ol = styled.ol`
  list-style: decimal !important;
  margin-left: 3em !important;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  li {
    max-width: 80%;
    &::marker {
      color: ${({ theme: { type } }) => MainTheme.list[type as TTheme].color};
    }
  }
`;

export const Ul = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  li {
    max-width: 80%;
    display: flex;
    column-gap: 10px;
  }
`;

export const Li = styled.li<{ showBullet?: boolean }>`
  &::before {
    ${(props) =>
      props.showBullet &&
      css`
        margin-left: 2em;
        content: '•';
        color: ${({ theme: { type } }) => MainTheme.list[type as TTheme].color};
      `}
  }
  li {
    margin-left: 2em;
    &::before {
      content: '○';
      color: ${({ theme: { type } }) => MainTheme.list[type as TTheme].color};
    }
  }
`;

export const StyledTitle = styled.h3`
  font-family: ${FAMILIES.inter};
  font-weight: ${WEIGHTS.medium};
  font-size: 30px;
  line-height: 22px;
  position: relative;
  color: ${({ theme: { type } }) => MainTheme.title[type as TTheme].color};
  text-align: center;
`;

export const StyledSectionTitle = styled.h4`
  font-family: ${FAMILIES.inter};
  font-weight: ${WEIGHTS.medium};
  font-size: 25px;
  line-height: 22px;
  position: relative;
  color: ${({ theme: { type } }) => MainTheme.title[type as TTheme].color};
`;

export const StyledSubTitle = styled.h4`
  font-family: ${FAMILIES.inter};
  font-weight: ${WEIGHTS.medium};
  font-size: 20px;
  line-height: 22px;
  position: relative;
  color: ${({ theme: { type } }) => MainTheme.title[type as TTheme].color};
`;

export const Text = styled.p`
  text-align: justify;
  font-family: ${FAMILIES.inter};
  font-weight: ${WEIGHTS.normal};
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme: { type } }) => MainTheme.text[type as TTheme].color};
`;

export const StyledButton = styled(ContainedButton)`
  padding: 10px 12px;
  width: auto;
  margin-bottom: 27px;
  font-family: ${FAMILIES.inter};

  svg {
    margin-right: 10px;
  }
`;
