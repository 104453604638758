import { useEffect, useState } from 'react';
import { useAuth } from '../../web3auth/web3auth.provider';
import { UNLIMIT_CRYPTOCURRENCY_CODES } from '../consts/fiat-symbol.matcher';
import { CoinType } from '../types/transaction.type';

type ProductType = 'SELL' | 'BUY';

type UnlimitWidgetOpts = {
  ramp: ProductType;
  cryptoCurrency: CoinType;
  fiatCurrency: string;
  amount: number;
};

export const useUnlimit = () => {
  const { address } = useAuth();
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (address) {
      setIsInitialized(true);
    }
  }, [address]);

  const getOpts = ({ ramp, cryptoCurrency, fiatCurrency, amount }: UnlimitWidgetOpts): any => {
    if (ramp === 'SELL') {
      return {
        merchantId: process.env.REACT_APP_PARTNER_ID_UNLIMIT || '',
        cryptoCurrency: UNLIMIT_CRYPTOCURRENCY_CODES[cryptoCurrency],
        cryptoAmount: amount,
        wallet: address
      };
    }
    return {
      merchantId: process.env.REACT_APP_PARTNER_ID_UNLIMIT || '',
      cryptoCurrency: UNLIMIT_CRYPTOCURRENCY_CODES[cryptoCurrency],
      fiatAmount: amount,
      fiatCurrency,
      wallet: address
    };
  };

  const initUnlimit = (opts: UnlimitWidgetOpts) => {
    if (!isInitialized) return;
    const options = getOpts(opts);
    const queryParams = new URLSearchParams(options).toString();
    const url =
      opts.ramp === 'BUY'
        ? process.env.REACT_APP_UNLIMIT_URL
        : process.env.REACT_APP_UNLIMIT_URL_OFFRAMP;
    window.open(`${url}/?${queryParams}`, 'Unlimit', 'width=600,height=800');
  };

  return {
    initUnlimit,
    isInitialized
  };
};
