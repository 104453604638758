import { Box } from '../../../common/components';

import { TransactionStatusTypes } from '../../../common/types/transaction.type';
import { Operation } from './index.styled';

interface IProps {
  orderId: string;
  status: TransactionStatusTypes;
}

export const OrderId = ({ orderId, status }: IProps) => (
  <Box changeDirection justify="center">
    <Operation status={status}>#{orderId} </Operation>
  </Box>
);

