import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useBalance } from '../../../home/hooks/use-balance.hook';
import { useAuth } from '../../../web3auth/web3auth.provider';
import { ROUTER_KEYS } from '../../consts/app-keys.const';
import { Footer } from '../footer';
import { Header } from '../header';
import { Box } from '../index.styled';
import { Loader } from '../loader';

export const Container = () => {
  const { data: balance, isLoading } = useBalance();
  const { authState } = useAuth();
  const [showLoader, setShowLoader] = useState(true);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (pathname !== ROUTER_KEYS.ROOT) {
      setShowLoader(false);
    }
  }, [pathname]);

  useEffect(() => {
    if (authState === 'LOGGED') {
      navigate(ROUTER_KEYS.HOME);
    }
    if (authState === 'UNLOGGED') {
      navigate(ROUTER_KEYS.AUTH);
    }
  }, [authState]);

  return (
    <Box changeDirection width="100%" zIndex={1} justify="space-between">
      <Header balance={balance?.totalAmount} isBalanceLoading={isLoading} />
      <Loader isLoading={showLoader} isLoader />
      <Outlet />
      <Footer />
    </Box>
  );
};
