import * as Yup from 'yup';

export const cashOutFormValidationSchema = Yup.object().shape({
  amount: Yup.string().test('checkAmount', 'Insufficient funds', function (value) {
    if (value === undefined) {
      return true;
    }
    const { balance } = this.parent;

    return !!value && parseFloat(value) <= parseFloat(balance);
  }),

  group2: Yup.string().test('checkAmount', 'Required', function () {
    const amount = parseFloat(this.parent.amount);
    return !!amount;
  })
});
