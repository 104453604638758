import styled from 'styled-components';
import { TTheme } from '../../../common/types';
import { ThemeColors } from '../../../common/consts';

export const StyledIcon = styled('div')`
  cursor: pointer;

  svg path {
    fill: ${({ theme: { type } }) => ThemeColors.text[type as TTheme].color};
  }

  &:hover {
    svg path {
      fill: ${({ theme: { type } }) => ThemeColors.containedButton[type as TTheme].hoverColor};
    }
  }
`;
