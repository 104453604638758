import { useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { ArrowTooltip, Box, Spinner } from '../../../common/components';
import { IFilters } from '../../../common/types/transaction.type';
import { toggleState } from '../../../common/utils';
import { Filter } from '../filter';

import { ReactComponent as ArrowLeft } from '../../../../assets/icons/arrowLeft.svg';
import { ReactComponent as FilterIcon } from '../../../../assets/icons/filter.svg';
import { ReactComponent as ArrowRight } from '../../../../assets/image/arrowRight.svg';

import { QUERY_KEYS } from '../../../common/consts/app-keys.const';
import { useOutsideClick } from '../../../common/hooks/use-outside-click.hook';
import { useBalance } from '../../../home/hooks/use-balance.hook';
import { useFilter } from '../filter/hooks/use-filter.hook';
import {
  ContainedButton,
  ContainedContainer,
  RefreshButton,
  StyledBox,
  StyledTitle
} from './index.styled';

interface IProps {
  clickHandler: (type: string) => () => void;
  tableTitle: string;
  children: JSX.Element;
  margin?: string;
  page: number;
  isLoading: boolean;
  total: number;
  setFilters: (value: IFilters) => void;
}

export const RenderTable = ({
  clickHandler,
  tableTitle,
  margin,
  children,
  page,
  isLoading,
  total,
  setFilters
}: IProps) => {
  const [isFilterActive, setIsFilterActive] = useState(false);
  const { isLoading: isBalanceLoading, data: balance } = useBalance();
  const queryClient = useQueryClient();

  const filterProps = useFilter(setFilters);
  const closePopup = () => setIsFilterActive(false);

  const modalRef = useRef<HTMLDivElement>(null);
  const filterBtnRef = useRef<HTMLButtonElement>(null);
  const ref = useRef<HTMLDivElement>(null);
  useOutsideClick(ref, closePopup, [modalRef, filterBtnRef]);

  const totalPages = Math.ceil(total / 10);

  const handleRefreshHistory = () => {
    queryClient.invalidateQueries([QUERY_KEYS.HISTORY, false]);
  };

  return (
    <Box changeDirection margin={margin}>
      <Box margin="0 0 18px 0" justify="space-between" align="center">
        <StyledTitle>{tableTitle} </StyledTitle>

        {isLoading ? null : (
          <StyledBox>
            <ContainedContainer>
              {isLoading ? (
                <Spinner width="20px" />
              ) : (
                <>
                  {page} of {totalPages}
                </>
              )}
              <button
                type="button"
                onClick={clickHandler('dec')}
                disabled={isLoading || page === 1}
              >
                <ArrowLeft />
              </button>
              <button
                type="button"
                onClick={clickHandler('inc')}
                disabled={isLoading || totalPages === page}
              >
                <ArrowRight />
              </button>
            </ContainedContainer>

            <ContainedButton
              ref={filterBtnRef}
              onClick={toggleState(setIsFilterActive)}
              type="button"
              minWidth="98px"
              disabled={isLoading || isBalanceLoading}
            >
              Filter <FilterIcon />
            </ContainedButton>
            <ArrowTooltip title="Refresh Now">
              <RefreshButton onClick={handleRefreshHistory} />
            </ArrowTooltip>
          </StyledBox>
        )}
      </Box>
      {isLoading || !balance ? (
        <Box justify="center">
          <Spinner />
        </Box>
      ) : (
        <Box position="relative" width="100%" changeDirection>
          <div ref={ref}>
            <Filter
              dexCoins={balance.dexTokenBalances.map((x) => ({ name: x.name, symbol: x.symbol }))}
              modalRef={modalRef}
              isActive={isFilterActive}
              {...filterProps}
            />
          </div>
          {children}
        </Box>
      )}
    </Box>
  );
};