import { Box, StyledText } from '../../common/components';
import { Input } from '../../common/components/input';
import { CustomSelect } from '../../common/components/select';
import { CURRENCY_OPTIONS } from '../../common/consts/fiat-symbol.matcher';
import { ICurrencyOption } from '../../common/types/balance.type';
import { THEME_OPTIONS } from '../constants';
import { IThemeOption, IValues } from '../types';

interface IProps {
  isEmail?: boolean;
  currency: ICurrencyOption;
  setCurrency: (value: ICurrencyOption) => void;
  theme: IThemeOption;
  setTheme: React.Dispatch<React.SetStateAction<IThemeOption>>;
  formValues: IValues;
}

export const RenderInputs = ({
  isEmail,
  currency,
  theme,
  setCurrency,
  setTheme,
  formValues
}: IProps) => (
  <>
    <Box changeDirection width="292px" padding="27px 0 0">
      <StyledText padding="0 0 8px">Username</StyledText>
      <Input name="name" placeholder="Username" value={formValues.name} />
    </Box>

    {isEmail ? (
      <Box changeDirection width="292px" padding="18px 0 0">
        <StyledText padding="0 0 8px">Email for Notifications</StyledText>
        <Input name="email" placeholder="Enter Email" value={formValues.email} />
      </Box>
    ) : null}

    <Box changeDirection width="292px" padding="18px 0 0">
      <StyledText padding="0 0 8px">Currency Conversion</StyledText>
      <CustomSelect<ICurrencyOption>
        name="currency"
        options={CURRENCY_OPTIONS}
        selectValue={currency}
        setSelectValue={setCurrency}
        placeholder="USD - United States Dollar"
      />
    </Box>

    <Box changeDirection width="292px" padding="18px 0 0">
      <StyledText padding="0 0 8px">Theme</StyledText>
      <CustomSelect<IThemeOption>
        options={THEME_OPTIONS}
        selectValue={theme}
        setSelectValue={setTheme}
        name="theme"
        placeholder="System"
      />
    </Box>
  </>
);
