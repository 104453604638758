import { Box, Spinner } from '../../../common/components';

import { ReactComponent as ArrowLeft } from '../../../../assets/icons/arrowLeft.svg';
import { ReactComponent as ArrowRight } from '../../../../assets/image/arrowRight.svg';
import { ContainedContainer, StyledBox, StyledTitle } from './index.styled';

interface IProps {
  clickHandler: (type: string) => () => void;
  tableTitle: string;
  margin?: string;
  page: number;
  children: JSX.Element;
  isLoading: boolean;
  total: number;
}

export const RenderTableWithoutFilter = ({
  clickHandler,
  tableTitle,
  margin,
  page,
  children,
  isLoading,
  total
}: IProps) => {
  const totalPages = Math.ceil(total / 10);

  return (
    <Box changeDirection margin={margin}>
      <Box margin="0 0 18px 0" justify="space-between" align="center">
        <StyledTitle>{tableTitle} </StyledTitle>

        {isLoading ? null : (
          <StyledBox>
            <ContainedContainer>
              {isLoading ? (
                <Spinner width="20px" />
              ) : (
                <>
                  {page} of {totalPages}
                </>
              )}
              <button
                type="button"
                onClick={clickHandler('dec')}
                disabled={isLoading || page === 1}
              >
                <ArrowLeft />
              </button>
              <button
                type="button"
                onClick={clickHandler('inc')}
                disabled={isLoading || totalPages === page}
              >
                <ArrowRight />
              </button>
            </ContainedContainer>
          </StyledBox>
        )}
      </Box>
      {isLoading ? (
        <Box justify="center">
          <Spinner />
        </Box>
      ) : (
        <Box position="relative" width="100%" changeDirection>
          {children}
        </Box>
      )}
    </Box>
  );
};
