import { COLORS } from '../../../theme';

export const ThemeColors = {
  title: {
    light: {
      color: COLORS.secondaryOrange
    },
    dark: {
      color: COLORS.gargoyleGas
    }
  }
};
