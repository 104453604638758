import { COLORS } from '../../../theme';

export const ThemeColors = {
  CoinWrapper: {
    light: {
      background: COLORS.antiFlashWhite,
      hoverBackground: COLORS.antiFlashWhite
    },
    dark: {
      background: COLORS.primary,
      hoverBackground: COLORS.darkBlue100
    }
  },
  SelectButtonWrapper: {
    light: {
      background: COLORS.white,
      hoverBackground: COLORS.antiFlashWhite,
      disabledBackground: COLORS.white,
      borderBattery: COLORS.azureishWhite,
      svg: COLORS.primary
    },
    dark: {
      background: COLORS.primary,
      hoverBackground: COLORS.darkElectricBlue,
      disabledBackground: COLORS.white,
      borderBattery: COLORS.darkElectricBlue,
      svg: COLORS.white
    }
  },
  coin: {
    light: {
      color: COLORS.coolGrey
    },
    dark: {
      color: COLORS.lightPeriwinkle
    }
  }
};
