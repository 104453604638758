/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unstable-nested-components */
import React, { FC } from 'react';
import { Content, Term, TermType } from './content.type';
import { Box } from '../../common/components';
import { StyledSectionTitle, StyledSubTitle, Text } from '../index.styled';
import { For } from '../../common/components/for/for.component';
import { match } from '../../common/utils/match.util';
import { BulletList, List } from './List';
import { TableComponent } from './Table';

type SectionProps = {
  title: string;
  terms: Term[];
};

export const Section: FC<SectionProps> = ({ title, terms }) => {
  const matchTerm = match({
    [TermType.PARAGRAPH]: (content: Content) => <Text>{content}</Text>,
    [TermType.LIST]: (content: Content) => <BulletList items={content as (string | string[])[]} />,
    [TermType.SUBTITLE]: (content: Content) => <StyledSubTitle>{content}</StyledSubTitle>,
    [TermType.TABLE]: (content: any) => (
      <TableComponent headers={content.headers} rows={content.rows} />
    ),
    [TermType.ORDERED_LIST]: (content: Content) => <List items={content as (string | string[])[]} />
  });
  return (
    <Box changeDirection gap="15px">
      <StyledSectionTitle>{title}</StyledSectionTitle>
      <Box changeDirection gap="10px">
        <For each={terms.map((x, idx) => ({ ...x, idx }))}>
          {({ type, content, idx }) => <div key={`${idx}-${type}`}>{matchTerm[type](content)}</div>}
        </For>
      </Box>
    </Box>
  );
};
