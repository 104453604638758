import { Grid } from '@mui/material';
import { FC } from 'react';

import { ICurrencyOption } from '../../../../../common/types/balance.type';
import {
  ArrowContainer,
  SelectButtonWrapper
} from '../../../../../common/components/select-modal/index.styled';
import { Title } from './index.styled';

import { ReactComponent as ArrowDown } from '../../../../../../assets/icons/arrow-down.svg';

type SelectModalButtonProps = {
  selectedCoin?: ICurrencyOption;
  disabled?: boolean;
  onClick: () => void;
  isSmaller?: boolean;
};

export const SelectFiatBtn: FC<SelectModalButtonProps> = ({
  selectedCoin,
  onClick,
  disabled,
  isSmaller
}) => (
  <SelectButtonWrapper
    isSmaller={isSmaller}
    type="button"
    disabled={disabled}
    onClick={onClick}
    height="56px"
  >
    <Grid container justifyContent="space-between" flexWrap="nowrap">
      <Grid xs={10} item container alignItems="center" columnGap={1} flexWrap="nowrap">
        <Title>{selectedCoin?.label}</Title>
      </Grid>

      <ArrowContainer paddingRight="5px" item alignSelf="center">
        <ArrowDown />
      </ArrowContainer>
    </Grid>
  </SelectButtonWrapper>
);
