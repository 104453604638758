import styled from 'styled-components';
import { Box, ContainedButton } from '../common/components';
import { FAMILIES, WEIGHTS } from '../theme/fonts.const';
import { ThemeColors as MainTheme } from '../common/consts';
import { TTheme } from '../common/types';
import { ThemeColors } from './constants/theme';

export const StyledBox = styled(Box)`
  margin: 34px 146px;

  @media screen and (max-width: 1000px) {
    margin: 0 2.25rem;
  }
`;

export const GoBack = styled(ContainedButton)`
  padding: 10px 12px;
  width: auto;
  margin-bottom: 27px;

  svg {
    margin-right: 10px;
  }
`;

export const Title = styled.h3`
  font-family: ${FAMILIES.inter};
  font-weight: ${WEIGHTS.medium};
  font-size: 18px;
  line-height: 22px;
  position: relative;
  color: ${({ theme: { type } }) => MainTheme.title[type as TTheme].color};

  &::after {
    content: '';
    position: absolute;
    display: block;
    height: 2px;
    background: ${({ theme: { type } }) => MainTheme.title[type as TTheme].color};
    width: 147px;
    bottom: -8px;
  }
`;

export const Container = styled.form`
  padding: 29px 47px 32px;
  background: ${({ theme: { type } }) => ThemeColors.container[type as TTheme].background};
  border: 1px solid ${({ theme: { type } }) => ThemeColors.container[type as TTheme].border};
  box-shadow: 0px 1px 4px rgba(255, 132, 39, 0.08);
  border-radius: 12px;
`;

export const ButtonsBox = styled(Box)`
  justify-content: end;
  margin-top: 24px;

  & > button {
    border-radius: 12px;
    width: 172px;
    margin: 0;
  }

  & > button:first-child {
    margin-right: 24px;
  }
`;

export const ButtonContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  align-items: end;

  & > button {
    margin: 0;
    width: 200px;
    padding: 12px 20px;
  }
`;
