import { Slide } from '@mui/material';
import { FC } from 'react';
import { LogoComponent } from '../logo';
import * as Styled from './styles.styled';

import { ReactComponent as TopShadow } from '../../../../assets/image/topShadow.svg';
import { ReactComponent as LeftShadow } from '../../../../assets/image/leftShadow.svg';
import { ReactComponent as RightShadow } from '../../../../assets/image/rightShadow.svg';
import { Box } from '../index.styled';

type LoaderProps = {
  isLoading: boolean;
  isLoader?: boolean;
};

type SpinnerProps = {
  width?: string;
  margin?: string;
};

export const Spinner = (props: SpinnerProps) => <Styled.Spinner {...props} />;

export const Loader: FC<LoaderProps> = ({ isLoading, isLoader }) => (
  <Slide direction="down" style={{ animationDuration: '800ms' }} in={isLoading}>
    <Styled.SplashScreen isLoading={isLoading}>
      <TopShadow className="top" />
      <LeftShadow className="left" />
      <RightShadow className="right" />
      <Box changeDirection>
        <LogoComponent isBig />
        {isLoader ? <Spinner margin="20px 0 0 " /> : null}
      </Box>
    </Styled.SplashScreen>
  </Slide>
);
