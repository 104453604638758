import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(relativeTime);

export const generateDate = (date: string) => {
  const dateTime = dayjs(date);
  const formattedDate = dateTime.format('MMM-DD-YYYY hh:mm:ss A');

  if (dateTime.isSame(dayjs(), 'day')) {
    const minutesDiff = dayjs().diff(dateTime, 'minute');

    if (minutesDiff < 60) {
      return `${
        minutesDiff === 1 ? `${1} min` : `${minutesDiff} mins`
      }  ago (${formattedDate} +UTC)`;
    }
    const hoursDiff = dayjs().diff(dateTime, 'hour');

    return `${hoursDiff} hour${hoursDiff === 1 ? '' : 's'} ago (${formattedDate} +UTC)`;
  }

  const daysDiff = dayjs().diff(dateTime, 'day');

  return `${
    daysDiff === 0 || daysDiff === 1 ? `${1} day` : `${daysDiff} days`
  }  ago (${formattedDate} +UTC)`;
};

const addOrdinalIndicator = (day: number) => {
  if (day > 3 && day < 21) return `${day}th`;

  switch (day % 10) {
    case 1:
      return `${day}st`;
    case 2:
      return `${day}nd`;
    case 3:
      return `${day}rd`;
    default:
      return `${day}th`;
  }
};

export const generateDateWithIndicator = (timestamp: number) => {
  const date = dayjs.unix(timestamp);
  const day = date.format('D');
  const month = date.format('MMMM');
  const year = date.format('YYYY');

  return `${month} ${addOrdinalIndicator(parseInt(day, 10))}, ${year}`;
};
