/* eslint-disable prefer-arrow-callback */
import * as Yup from 'yup';

import { TokenBalance } from '../types/balance.type';
import { CoinType } from '../types/transaction.type';

export const sendCryptoStep1 = (ownAddress: string) =>
  Yup.object().shape({
    address: Yup.string()
      .matches(/^0x[a-fA-F0-9]{40}$/g, 'Invalid address')
      .test('ownAddress', 'Do not enter your own wallet address', function (value) {
        return value?.toLowerCase() !== ownAddress.toLowerCase();
      })
      .required('Required')
  });

export const sendCryptoStep2 = (balances: TokenBalance[]) =>
  Yup.object().shape({
    token: Yup.mixed<CoinType>().oneOf(Object.values(CoinType)),
    amount: Yup.number()
      .moreThan(0, 'Invalid amount')
      .test('checkBalance', 'Insufficient funds', function (value) {
        const { token } = this.parent; // Get the selected token from the parent object
        const selectedTokenBalance = balances.find((balance) => balance.symbol === token)?.balance;

        if (typeof selectedTokenBalance === 'number' && value !== undefined) {
          return value <= selectedTokenBalance;
        }

        return false; // Fallback in case the token balance is not found
      })
      .required('Required')
      .typeError('Invalid amount')
  });
