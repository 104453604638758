import { useEffect } from 'react';

import { useAuth } from '../../../../../web3auth/web3auth.provider';
import { useFetchUserAvatar } from '../../../../hooks/use-fetch-user-avatar.hook';
import { ITransaction, TransactionType } from '../../../../types/transaction.type';
import { UserDTO } from '../../../../types/user.type';
import { Actions, TransactionDetails, TransactionSender } from './components';
import { Statuses } from './components/statuses';
import { getTransactionValue } from './utils/transaction-value.util';

import { Box } from '../../../index.styled';

interface IProps {
  transaction: ITransaction;
  isOpen: boolean;
}

export const DetailsModal = ({ transaction, isOpen }: IProps) => {
  const { address, userInfo } = useAuth<UserDTO>();
  const otherAccount =
    address?.toLocaleLowerCase() === transaction.from.toLocaleLowerCase()
      ? transaction.to
      : transaction.from;

  const { avatar, isLoading, refetch } = useFetchUserAvatar(otherAccount, transaction._id, false);
  const isWyre =
    transaction.txType === TransactionType.CASH_OUT ||
    transaction.txType === TransactionType.TOP_UP;

  useEffect(() => {
    if (isOpen) {
      refetch();
    }
  }, [isOpen]);

  return (
    <Box changeDirection>
      {!isWyre ? <Actions hash={transaction.txHash} type={transaction.status} /> : null}

      {transaction.txType !== TransactionType.DEX_INTERACTION ? (
        <Statuses
          type={transaction.status}
          submittedData={transaction.submittedDate}
          canceledData={transaction.confirmedDate}
        />
      ) : null}

      <TransactionSender
        txType={transaction.txType}
        isLoading={isLoading}
        otherAccount={otherAccount}
        isForYou={(address?.toLocaleLowerCase() ?? '') === transaction.to.toLocaleLowerCase()}
        currentIamge={userInfo?.avatar ?? ''}
        otherImage={avatar ?? ''}
      />

      <TransactionDetails
        {...transaction}
        value={getTransactionValue(transaction)}
        isWyre={isWyre}
        txType={transaction.txType}
        token={transaction.tokenTicker ? transaction.tokenTicker.toString() : 'MATIC'}
      />
    </Box>
  );
};
