import { EnhancedWithAuthHttpService } from './http-auth.service';
import { HttpFactoryService } from './http-factory.service';

class RateService {
  constructor(private readonly httpService: EnhancedWithAuthHttpService) {}

  async convert(from: string, to: string, amount: number) {
    return this.httpService.post<number, { amount: number }>(`rate/convert?from=${from}&to=${to}`, {
      amount
    });
  }
}

const httpService = HttpFactoryService.createAuthHttpService();
export const rateService = new RateService(httpService);
