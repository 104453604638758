import { CoinWrapper } from '../../../../../common/components/select-modal/index.styled';
import { Box, StyledText } from '../../../../../common/components';

import Modal from '../../../../../common/components/modal';
import { Header } from '../../../crypto-block/index.styled';
import { ICurrencyOption } from '../../../../../common/types/balance.type';

interface IProps {
  setSelectValue: (option: ICurrencyOption) => void;
  selectValue?: ICurrencyOption;
  options: ICurrencyOption[];
  handleClose: () => void;
  open: boolean;
}

export const SelectFiatModal = ({
  selectValue,
  setSelectValue,
  options,
  handleClose,
  open
}: IProps) => {
  const handleChange = (newValue: ICurrencyOption) => () => {
    setSelectValue(newValue);
    handleClose();
  };

  return (
    <Modal
      open={open}
      header={<Header>Fiat</Header>}
      onClose={handleClose}
      background="dark"
      content={
        <Box changeDirection>
          {options.map((el, i) => (
            <CoinWrapper
              key={`crypto-balance${i}`}
              active={selectValue?.value === el.value}
              onClick={handleChange(el)}
            >
              <Box justify="space-between" align="center" height="37px">
                <StyledText> {el.label}</StyledText>
                <StyledText>{el.coin}</StyledText>
              </Box>
            </CoinWrapper>
          ))}
        </Box>
      }
    />
  );
};
