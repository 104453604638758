import React from 'react';
import { Box } from '../../../../../index.styled';
import { ReactComponent as Watch } from '../../../../../../../../assets/icons/watch.svg';
import { Text } from './index.styled';
import { generateDate } from '../../utils/date.utils';
import { TransactionStatusTypes } from '../../../../../../types/transaction.type';
import { TITLES } from '../../../../constants';

interface IProps {
  submittedData: string;
  canceledData: string;
  type: TransactionStatusTypes;
}

export const Statuses = ({ submittedData, canceledData, type }: IProps) => (
  <Box changeDirection margin="24px 0 0 0">
    <Box justify="space-between" align="center" margin="0 0 8px 0">
      <Text>
        <Watch />
        {TITLES[TransactionStatusTypes.SUBMITED].title}
      </Text>
      <Text>{generateDate(submittedData)}</Text>
    </Box>

    {type !== TransactionStatusTypes.SUBMITED && (
      <Box justify="space-between" align="center">
        <Text>
          <Watch /> {TITLES[type].title}
        </Text>
        <Text>{generateDate(canceledData)}</Text>
      </Box>
    )}
  </Box>
);
