import styled from 'styled-components';

import { ReactComponent as RefreshIcon } from '../../../../assets/icons/refresh-icon.svg';
import { ThemeColors as MainTheme } from '../../../common/consts';
import { TTheme } from '../../../common/types';
import { FAMILIES, SIZES, WEIGHTS } from '../../../theme/fonts.const';

import { Box } from '../../../common/components';
import { COLORS } from '../../../theme';

export const RefreshButton = styled(RefreshIcon)`
  path {
    transition: stroke 275ms ease;
  }
  &:hover {
    cursor: pointer;
    path {
      stroke: ${COLORS.secondaryOrange};
    }
  }
`;

export const StyledTitle = styled.h3`
  font-family: ${FAMILIES.inter};
  font-weight: ${WEIGHTS.medium};
  font-size: 18px;
  line-height: 22px;
  position: relative;
  color: ${({ theme: { type } }) => MainTheme.title[type as TTheme].color};

  &::after {
    content: '';
    position: absolute;
    display: block;
    height: 2px;
    background: ${({ theme: { type } }) => MainTheme.title[type as TTheme].color};
    width: 100%;
    bottom: -8px;
  }
`;

export const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
  gap: 14px;
  button:last-child {
    margin-left: 14px;
  }
`;

export const ContainedContainer = styled.div`
  border: 1px solid ${({ theme: { type } }) => MainTheme.containedButton[type as TTheme].border};
  padding: 10px 12px;
  background: ${({ theme: { type } }) => MainTheme.containedButton[type as TTheme].background};
  font-weight: ${WEIGHTS.medium};
  font-size: ${SIZES.l};
  color: ${({ theme: { type } }) => MainTheme.containedButton[type as TTheme].textColor};
  border-radius: 12px;
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  transition: color 275ms ease;
  min-width: 168px;
  font-family: ${FAMILIES.inter};

  button:first-child {
    margin-left: 34px;
  }

  button {
    cursor: pointer;
    border: none;
    background: none;
    svg {
      margin-left: 10px;
      fill: ${({ theme: { type } }) => MainTheme.containedButton[type as TTheme].textColor};
    }
    &:hover {
      color: ${({ theme: { type } }) => MainTheme.containedButton[type as TTheme].hoverColor};

      svg {
        fill: ${({ theme: { type } }) => MainTheme.containedButton[type as TTheme].hoverColor};
      }
    }
  }

  &:hover {
    color: ${({ theme: { type } }) => MainTheme.containedButton[type as TTheme].hoverColor};
  }
`;

export const ContainedButton = styled.button<{ minWidth?: string }>`
  border: 1px solid ${({ theme: { type } }) => MainTheme.containedButton[type as TTheme].border};
  padding: 10px 12px;
  background: ${({ theme: { type } }) => MainTheme.containedButton[type as TTheme].background};
  font-weight: ${WEIGHTS.medium};
  font-size: ${SIZES.l};
  color: ${({ theme: { type } }) => MainTheme.containedButton[type as TTheme].textColor};
  border-radius: 12px;
  cursor: pointer;
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  transition: color 275ms ease;
  min-width: ${({ minWidth }) => minWidth ?? 'auto'};
  font-family: ${FAMILIES.inter};

  svg {
    margin-left: 10px;
    stroke: ${({ theme: { type } }) => MainTheme.containedButton[type as TTheme].textColor};
  }

  &:hover {
    color: ${({ theme: { type } }) => MainTheme.containedButton[type as TTheme].hoverColor};

    svg {
      stroke: ${({ theme: { type } }) => MainTheme.containedButton[type as TTheme].hoverColor};
    }
  }
`;
