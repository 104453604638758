import { useEffect, useState } from 'react';
import { useAuth } from '../../web3auth/web3auth.provider';

type ProductType = 'SELL' | 'BUY';
const environment = 'PRODUCTION';

type TransakWidgetOpts = {
  ramp: ProductType;
  cryptoCurrency: string;
  fiatCurrency: string;
  amount: number;
};

export const useTransak = (
  onClose?: (orderData: any) => void,
  onSucess?: (orderData: any) => void,
  onFailed?: (orderData: any) => void
) => {
  const { address } = useAuth();
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (address) {
      setIsInitialized(true);
    }
  }, [address]);

  const getOpts = ({ ramp, cryptoCurrency, fiatCurrency, amount }: TransakWidgetOpts): any => {
    if (ramp === 'SELL') {
      return {
        apiKey: process.env.REACT_APP_TRANSAK_API_KEY || '',
        cryptoCurrencyCode: cryptoCurrency,
        defaultCryptoAmount: amount,
        network: 'polygon',
        walletAddress: address,
        disableWalletAddressForm: true,
        productsAvailed: ramp
      };
    }
    return {
      apiKey: process.env.REACT_APP_TRANSAK_API_KEY || '',
      cryptoCurrencyCode: cryptoCurrency,
      defaultFiatAmount: amount,
      fiatCurrency,
      network: 'polygon',
      walletAddress: address,
      disableWalletAddressForm: true,
      productsAvailed: ramp
    };
  };

  const initTransak = (opts: TransakWidgetOpts) => {
    if (!isInitialized) return;
    const options = getOpts(opts);
    const queryParams = new URLSearchParams(options).toString();
    window.open(`https://global.transak.com/?${queryParams}`, 'Transak', 'width=600,height=800');
  };

  return {
    initTransak,
    isInitialized
  };
};
