import styled from 'styled-components';

import { FONTS } from '../../theme';
import { ThemeColors } from '../consts';
import { TTheme } from '../types';

export const Box = styled.div<{
  margin?: string;
  padding?: string;
  justify?: string;
  align?: string;
  changeDirection?: boolean;
  wrap?: string;
  width?: string;
  maxWidth?: string;
  height?: string;
  position?: string;
  borderRadius?: string;
  border?: string;
  backgroundColor?: string;
  gap?: string;
  zIndex?: number | string;
  cursor?: string;
}>`
  display: flex;
  margin: ${({ margin }) => margin || 0};
  padding: ${({ padding }) => padding || 0};
  justify-content: ${({ justify }) => justify || 'normal'};
  align-items: ${({ align }) => align || 'normal'};
  flex-direction: ${({ changeDirection }) => (!changeDirection ? 'row' : 'column')};
  flex-wrap: ${({ wrap }) => wrap || 'nowrap'};
  max-width: ${({ maxWidth }) => maxWidth || 'auto'};
  width: ${({ width }) => width || 'auto'};
  height: ${({ height }) => height || 'auto'};
  position: ${({ position }) => position || 'static'};
  border: ${({ border }) => border || 'none'};
  border-radius: ${({ borderRadius }) => borderRadius || '0'};
  background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
  gap: ${({ gap }) => gap || 0};
  z-index: ${({ zIndex }) => zIndex || 'unset'};
  cursor: ${({ cursor }) => cursor || 'auto'};
`;

export const Text = styled.p<{
  color?: string;
  fontFamily?: string;
  fontSize?: string;
  fontWeight?: string;
  lineHeight?: string;
  margin?: string;
  padding?: string;
  textAlign?: string;
  wordBreak?: string;
  textTransform?: string;
  width?: string;
  maxWidth?: string;
  display?: string;
}>`
  color: ${({ color, theme }) => color || theme.colors.darkGunmetal};
  font-family: ${({ fontFamily }) => fontFamily || `${FONTS.FAMILIES.inter}}`};
  font-size: ${({ fontSize }) => fontSize || `${FONTS.SIZES.l}`};
  font-weight: ${({ fontWeight }) => fontWeight || `${FONTS.WEIGHTS.normal}`};
  line-height: ${({ lineHeight }) => lineHeight || 'auto'};
  margin: ${({ margin }) => margin || '0'};
  padding: ${({ padding }) => padding || '0'};
  text-align: ${({ textAlign }) => textAlign || 'start'};
  word-break: ${({ wordBreak }) => wordBreak || 'normal'};
  text-transform: ${({ textTransform }) => textTransform || 'none'};
  width: ${({ width }) => width || 'auto'};
  max-width: ${({ maxWidth }) => maxWidth || 'auto'};
  display: ${({ display }) => display || 'block'};
`;

export const MainContainer = styled(Box)`
  overflow: hidden;
  background: ${({ theme: { type } }) => ThemeColors.maincontainer[type as TTheme].background};
  height: 100%;
  position: relative;
  min-height: 100vh;

  & .top {
    position: absolute;
    width: 670px;

    left: 425.09px;
    top: -413.17px;
  }

  & .left {
    position: absolute;
    left: -300px;
    top: 83px;
    height: 1055px;
  }

  & .right {
    position: absolute;
    left: calc(100% - 421px);
    top: calc(100% - 549px);
    width: 670px;
  }
`;

export const StyledText = styled(Text)`
  color: ${({ theme: { type } }) => ThemeColors.text[type as TTheme].color};
`;
