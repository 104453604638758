import { CoinType } from '../../../common/types/transaction.type';

export const initialValues = {
  coin: '',
  amount: '',
  group2: 'unlimit',
  calculated: 15,
  balance: '',
  token: CoinType.MATIC
};
