import { ROUTER_KEYS } from '../../common/consts/app-keys.const';
import { LoadingStatuses } from '../../common/types/payment.type';

export const STATUS_TEXT = {
  [LoadingStatuses.REJECTED]: {
    title: 'Transaction Canceled',
    desc: 'Oops! Your transaction was canceled.',
    buttonText: 'Try Again',
    navigateTo: '#'
  },
  [LoadingStatuses.SUBMITTED]: {
    title: 'Transaction Submitted',
    desc: 'Transaction received! Please wait for confirmation.',
    buttonText: 'View details',
    navigateTo: ROUTER_KEYS.HISTORY
  }
};
