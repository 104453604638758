import { convertArr } from '../../../common/components/render-transactions/helper';
import { CryptoAnalytics } from '../../../common/types/transaction.type';
import { TableWrapper } from '../../index.styled';
import { getBorder } from '../../utils/get-border.util';
import { History } from '../history';

interface IProps {
  items: CryptoAnalytics[];
  isCrop?: boolean;
  toCrop?: number;
  isTopCryptos?: boolean;
}

export const RenderHistory = ({ items, isTopCryptos, isCrop = true, toCrop = 2 }: IProps) => {
  const arr = convertArr(items, isCrop, toCrop);

  return (
    <TableWrapper>
      {arr.map((el, i) => (
        <History
          isTopCryptos={isTopCryptos}
          border={getBorder(i, arr.length)}
          {...el}
          key={`${el.ticker}analytics${i.toString()}`}
        />
      ))}
    </TableWrapper>
  );
};