import { Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader, LogoComponent, StyledText } from '../common/components';
import { StyledButton } from '../common/components/button';
import { For } from '../common/components/for/for.component';
import { Input } from '../common/components/input';
import { useVerify } from '../common/hooks/use-verify.hooks';
import { signInValidationSchema } from '../common/validation-schemas/sign-in.validation-schema';
import { WarningModal } from '../home/components/warning-modal';
import { WEIGHTS } from '../theme/fonts.const';
import { useAuth } from '../web3auth/web3auth.provider';
import { SocialLogin, loginProviderMatcher } from './login-provider.matcher';

import { ReactComponent as LeftShadow } from '../../assets/image/leftShadow.svg';
import { ReactComponent as RightShadow } from '../../assets/image/rightShadow.svg';
import { ReactComponent as TopShadow } from '../../assets/image/topShadow.svg';

import * as Styled from './styles.styled';
import { Checkbox } from '../common/components/checkbox';
import { APP_KEYS } from '../common/consts';
import { getLocalStorage, setLocalStorage } from '../common/utils/localstorage.util';
import { ROUTER_KEYS } from '../common/consts/app-keys.const';

type SignInFormValues = {
  email: string;
};

export const AuthContainer = () => {
  const initValues: SignInFormValues = {
    email: ''
  };
  const [checked, setChecked] = useState(false);

  const showCheckbox =
    getLocalStorage('accepted_terms-and-conditions') === null ||
    Boolean(getLocalStorage('accepted_terms-and-conditions')) === false;
  useEffect(() => {
    const accepted = getLocalStorage('accepted_terms-and-conditions');
    if (accepted) {
      setChecked(Boolean(accepted));
    }
  }, []);

  const loginProviders = [
    SocialLogin.FACEBOOK,
    SocialLogin.GOOGLE,
    SocialLogin.TWITTER,
    SocialLogin.DISCORD
  ];

  const { handleLoginWithEmail, handleLoginWithRedirect, isVerifying } = useVerify();

  const { loginError, setLoginError, authState } = useAuth();
  const navigate = useNavigate();
  const handleClose = () => {
    setLoginError(null);
  };

  const handleClickSubmit = (values: SignInFormValues) => {
    handleLoginWithEmail(values.email);

    setLocalStorage('accepted_terms-and-conditions', String(true));
  };

  const handleClickSocialLogin =
    (type: 'google' | 'twitter' | 'linkedin' | 'facebook' | 'discord' | 'apple') => () => {
      if (checked) {
        handleLoginWithRedirect(type);

        setLocalStorage('accepted_terms-and-conditions', String(true));
      }
    };

  const handleClickMetamask = () => {};

  const handleChangeCheckbox = (e: any) => {
    if (e.target) {
      setChecked(e.target.checked);
    }
  };

  useEffect(() => {
    if (authState === 'LOGGED') {
      navigate(ROUTER_KEYS.HOME);
    }
  }, [authState]);

  return (
    <Styled.AuthContainer container justifyContent="center" alignItems="center">
      <Loader isLoading={isVerifying} isLoader />
      <TopShadow className="top" />
      <LeftShadow className="left" />
      <RightShadow className="right" />
      <WarningModal message={loginError || ''} isOpen={loginError !== null} onClose={handleClose} />
      <Styled.FormContainer item xs={4} container direction="column">
        <Grid
          paddingBottom="50px"
          container
          item
          alignItems="center"
          justifyContent="space-between"
        >
          <StyledText fontSize="22px" lineHeight="27px" fontWeight={WEIGHTS.medium}>
            Sign in
          </StyledText>
          <LogoComponent />
        </Grid>
        <Grid paddingX="50px" rowGap="24px" container alignItems="center" direction="column">
          <StyledText fontSize="16px" lineHeight="19px" fontWeight={WEIGHTS.medium}>
            Continue with
          </StyledText>
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            rowGap="10px"
            columnGap="35px"
            paddingBottom="30px"
          >
            <For each={loginProviders.map((x, idx) => ({ ...loginProviderMatcher[x], idx }))}>
              {({ type, Icon, idx }) => (
                <Styled.SocialBtn
                  disabled={!checked}
                  key={`icon-${idx}`}
                  onClick={handleClickSocialLogin(type)}
                >
                  <Icon />
                </Styled.SocialBtn>
              )}
            </For>
          </Grid>
          <Formik
            initialValues={initValues}
            validationSchema={signInValidationSchema}
            onSubmit={handleClickSubmit}
          >
            {({ isValid }) => (
              <Form style={{ width: '100%' }}>
                <Grid container paddingBottom="24px" direction="column" rowGap="24px" width="100%">
                  <Input name="email" placeholder="Enter your Email" />
                  <StyledButton
                    width="100%"
                    disabled={!isValid || !checked}
                    type="submit"
                    padding="13px 0"
                    margin="0"
                  >
                    Continue with Email
                  </StyledButton>
                </Grid>
              </Form>
            )}
          </Formik>
          {showCheckbox && (
            <Grid container alignSelf="start" width="100%">
              <Checkbox
                onChange={handleChangeCheckbox}
                active
                text="I hereby accept the "
                name="accept"
              />
              <Styled.StyledLink to={APP_KEYS.ROUTER_KEYS.TERMS_AND_CODITIONS}>
                Terms & Conditions
              </Styled.StyledLink>
            </Grid>
          )}
        </Grid>
      </Styled.FormContainer>
    </Styled.AuthContainer>
  );
};
