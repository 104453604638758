import { Grid } from '@mui/material';

import { For } from '../../../common/components/for/for.component';
import Modal from '../../../common/components/modal';
import { CoinWrapper } from '../../../common/components/select-modal/coin';
import { DexCoin, coinIconMatcher } from '../../../common/consts/icons.const';
import { IToken } from '../../../common/types/balance.type';
import { Header } from '../../../home/components/crypto-block/index.styled';

interface IProps {
  setSelectValue: (option: IToken) => void;
  selectValue?: IToken | null;
  options: IToken[];
  handleClose: () => void;
  open: boolean;
  isBalance?: boolean;
  modalRef?: React.RefObject<HTMLDivElement>;
}

export const CryptoCurrencyModal = ({
  selectValue,
  setSelectValue,
  options,
  handleClose,
  open,
  isBalance = true,
  modalRef
}: IProps) => {
  const handleChange = (newValue: IToken) => () => {
    setSelectValue(newValue);
    handleClose();
  };

  return (
    <Modal
      open={open}
      header={<Header>Select Cryptocurrency</Header>}
      onClose={handleClose}
      maxWidth="33vw"
      background="dark"
      modalRef={modalRef}
      content={
        <Grid paddingTop="34px" container>
          <For each={options}>
            {(tokenOpts) => (
              <CoinWrapper
                key={`${tokenOpts.symbol}-crypto-balance`}
                active={selectValue?.symbol === tokenOpts.symbol}
                onClick={handleChange(tokenOpts)}
                Icon={coinIconMatcher[tokenOpts.symbol] || DexCoin}
                {...tokenOpts}
                isBalance={isBalance}
              />
            )}
          </For>
        </Grid>
      }
    />
  );
};