import { IThemeOption } from '../types';

export const THEME_OPTIONS: IThemeOption[] = [
  { value: 'system', label: 'System' },
  {
    value: 'light',
    label: 'Light'
  },
  {
    value: 'dark',
    label: 'Dark'
  }
];
