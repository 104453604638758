import { Formik, FormikProps, FormikState } from 'formik';
import { IToken } from '../../../common/types/balance.type';
import { INITIAL_VALUES } from '../constants';

import { IValues } from '../types';
import { Form } from './form';

interface IProps {
  isActive: boolean;
  cryptoBalance: IToken | null;
  setCryptoBalance: React.Dispatch<React.SetStateAction<IToken | null>>;
  submitHandler: (e: React.FormEvent<HTMLFormElement>, props: FormikProps<IValues>) => void;
  isOpen: boolean;
  handleClose: () => void;
  handleOpen: () => void;
  cleatHandler: (
    resetForm: (nextState?: Partial<FormikState<IValues>> | undefined) => void
  ) => () => void;
  onSubmit: (values: IValues) => void;
  modalRef: React.RefObject<HTMLDivElement>;
  dexCoins: IToken[];
}

export const Filter = ({ onSubmit, ...props }: IProps) => (
  <Formik initialValues={INITIAL_VALUES} onSubmit={onSubmit}>
    <Form {...props} />
  </Formik>
);