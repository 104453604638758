import { HttpFactoryService } from '../common/services/http-factory.service';
import { EnhancedWithAuthHttpService } from '../common/services/http-auth.service';
import { ExpectedFromQuery } from '../common/types/services.type';
import { IBodyUserUpdate } from '../common/types/user.type';

class UserService {
  constructor(private httpService: EnhancedWithAuthHttpService) {}

  async getUserAvatarByWallet(wallet: string): Promise<ExpectedFromQuery<string>> {
    return this.httpService.get<string>(`user/avatar/${wallet}`);
  }

  async updateUserSettings(body: IBodyUserUpdate) {
    return this.httpService.put<IBodyUserUpdate, {}>('user/me', body);
  }

  async removeAccount(pubKey: string) {
    return this.httpService.post<{ pubKey: string }, any>('user/me', {
      pubKey
    });
  }
}

const httpService = HttpFactoryService.createAuthHttpService();
export const userService = new UserService(httpService);
