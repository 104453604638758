import { ITransaction, TransactionType } from '../../../../../types/transaction.type';
import { priceFormat } from '../../../../../utils';

export const getTransactionValue = (transaction: ITransaction) => {
  if (transaction.txType === TransactionType.DEX_INTERACTION) {
    return `${priceFormat(transaction.value, 3)} USDC`;
  }
  return `${
    transaction.tokenTicker
      ? `${transaction.tokensAmount} ${transaction.tokenTicker}`
      : `${priceFormat(transaction.value, 3)} MATIC`
  }`;
};
