import { Box, StyledText } from '../../../../common/components';
import { LoadingStatuses } from '../../../../common/types/payment.type';
import { COLORS } from '../../../../theme';
import { WEIGHTS } from '../../../../theme/fonts.const';
import { STATUS_IMAGES } from '../constants';

interface IProps {
  status: LoadingStatuses;
  title: string;
  desc: string;
}

export const StatusLoadingHeader = ({ status, title, desc }: IProps) => (
  <Box align="start">
    {STATUS_IMAGES[status]}
    <Box margin="0 0 0 17px" changeDirection>
      <StyledText color={COLORS.darkBlue} fontWeight={WEIGHTS.medium} fontSize="22px">
        {title}
      </StyledText>
      <StyledText color={COLORS.darkBlue} fontWeight={WEIGHTS.normal} fontSize="17px">
        {desc}
      </StyledText>
    </Box>
  </Box>
);
