import styled from 'styled-components';

import { FAMILIES, WEIGHTS } from '../../../theme/fonts.const';
import { ThemeColors } from './theme';
import { ThemeColors as MainTheme } from '../../consts';
import { TTheme } from '../../types';

export const StyledTitle = styled.h3`
  font-family: ${FAMILIES.inter};
  font-weight: ${WEIGHTS.medium};
  font-size: 18px;
  line-height: 22px;
  position: relative;
  color: ${({ theme: { type } }) => ThemeColors.title[type as TTheme].color};

  &::after {
    content: '';
    position: absolute;
    display: block;
    height: 2px;
    background: ${({ theme: { type } }) => ThemeColors.title[type as TTheme].color};
    width: 100%;
    bottom: -8px;
  }
`;

export const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme: { type } }) => MainTheme.emptyBlock[type as TTheme].color};
  padding: 19px 0;
  background: ${({ theme: { type } }) => MainTheme.emptyBlock[type as TTheme].background};

  border: 1px solid ${({ theme: { type } }) => MainTheme.emptyBlock[type as TTheme].border};
  border-radius: 12px;

  svg {
    margin-bottom: 13px;
  }
`;
