import { IOption } from '../../types/services.type';
import { StyledSelect } from './index.styled';

interface IProps<T> {
  setSelectValue: (option: T) => void;
  selectValue?: T;
  options: T[];
  name?: string;
  isBattery?: boolean;
  isCrop?: boolean;
  placeholder?: string;
}

export const CustomSelect = <T extends IOption = IOption>({
  selectValue,
  setSelectValue,
  options,
  name,
  isBattery,
  isCrop,
  placeholder
}: IProps<T>) => {
  const handleChange = (newValue: unknown): void => {
    setSelectValue(newValue as T);
  };

  return (
    <StyledSelect
      name={name}
      options={options}
      value={selectValue}
      onChange={handleChange}
      classNamePrefix="Select"
      placeholder={placeholder ?? ''}
      isSearchable={false}
      isBattery={!!isBattery}
      isCrop={!!isCrop}
    />
  );
};
