import styled from 'styled-components';

import { Box } from '../../../../../index.styled';
import { TTheme } from '../../../../../../types';
import { ThemeColors } from '../../theme';
import { FAMILIES, WEIGHTS } from '../../../../../../../theme/fonts.const';

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: 24px 0 0 0;
  background-color: ${({ theme: { type } }) => ThemeColors.container[type as TTheme].background};
  border: 1px solid ${({ theme: { type } }) => ThemeColors.container[type as TTheme].border};

  margin-bottom: 24px;
  border-radius: 12px;
  padding: 0 8px 10px;
`;

export const HeadBlock = styled.div`
  display: flex;
  border-bottom: 1px solid ${({ theme: { type } }) => ThemeColors.container[type as TTheme].border};
  padding: 8px 0 12px;
  justify-content: space-between;
  color: ${({ theme: { type } }) => ThemeColors.container[type as TTheme].header};
  font-family: ${FAMILIES.inter};

  p > {
    font-weight: ${WEIGHTS.medium};
    font-size: 18px;
    line-height: 22px;
    font-family: ${FAMILIES.inter};
  }
`;

export const ArrowBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 12px;
  gap: 4px;
  width: 32px;
  height: 32px;
  background: ${({ theme: { type } }) => ThemeColors.container[type as TTheme].arrowBackground};
  border: 1px solid ${({ theme: { type } }) => ThemeColors.container[type as TTheme].arrowBorder};
  border-radius: 8px;

  svg {
    fill: ${({ theme: { type } }) => ThemeColors.container[type as TTheme].arrowColor};
  }
`;

export const CopyButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  display: flex;

  svg {
    fill: ${({ theme: { type } }) => ThemeColors.container[type as TTheme].copyIcon};
  }
`;

export const AccountText = styled.p`
  font-weight: ${WEIGHTS.normal};
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: ${({ theme: { type } }) => ThemeColors.container[type as TTheme].header};
  display: flex;
  align-items: center;
  font-family: ${FAMILIES.inter};
`;
