import { FormikProps, FormikState, useFormikContext } from 'formik';
import React, { useMemo } from 'react';
import { SelectModalButton, StyledButton } from '../../../common/components';
import { Checkbox } from '../../../common/components/checkbox';
import { BorderedButton } from '../../../common/components/header/components/account-info/index.styled';
import { Input } from '../../../common/components/input';
import { FiatCurrency, matchFiatCurrencySymbol } from '../../../common/consts/fiat-symbol.matcher';
import { useCurrency } from '../../../common/providers/currency.provider';
import { useTheme } from '../../../common/providers/theme.provider';
import { IToken } from '../../../common/types/balance.type';
import { match } from '../../../common/utils/match.util';
import { COLORS } from '../../../theme';
import { COINS, DEX_INPUTS_FILTER, INPUTS_FILTER } from '../constants';
import { CryptoCurrencyModal } from '../crypto-сurrency-modal';
import { IValues } from '../types';
import { ButtonsBox, Container, InputBlock, InputsBox, Text } from './index.styled';
import { RadioGroup } from './radio-group';

interface IProps {
  isActive: boolean;
  cryptoBalance: IToken | null;
  setCryptoBalance: React.Dispatch<React.SetStateAction<IToken | null>>;
  submitHandler: (e: React.FormEvent<HTMLFormElement>, props: FormikProps<IValues>) => void;
  isOpen: boolean;
  handleClose: () => void;
  handleOpen: () => void;
  cleatHandler: (
    resetForm: (nextState?: Partial<FormikState<IValues>> | undefined) => void
  ) => () => void;
  modalRef: React.RefObject<HTMLDivElement>;
  dexCoins: IToken[];
}

export const Form = ({
  isActive,
  cryptoBalance,
  setCryptoBalance,
  submitHandler,
  isOpen,
  handleClose,
  handleOpen,
  cleatHandler,
  modalRef,
  dexCoins
}: IProps) => {
  const props = useFormikContext<IValues>();
  const { currentTheme } = useTheme();
  const { currency } = useCurrency();
  const changeCryptoBalance = (newState: IToken) => {
    setCryptoBalance(newState);
  };

  const selectOptions = useMemo(() => {
    if (props.values.group2 === 'crypto') {
      return COINS;
    }
    return dexCoins;
  }, [props.values.group2]);

  const inputFilters = useMemo(() => {
    if (props.values.group2 === 'crypto') {
      return INPUTS_FILTER;
    }
    const color = currentTheme === 'light' ? COLORS.primary : COLORS.white;
    return DEX_INPUTS_FILTER(color);
  }, [props.values.group2, currentTheme]);

  const showFilters = useMemo(() => props.values.group2 !== 'all', [props.values.group2]);
  const symbol = useMemo(() => matchFiatCurrencySymbol[currency as FiatCurrency], [currency]);
  const matchLabelPadding = match({
    [FiatCurrency.USD]: '30px',
    [FiatCurrency.GBP]: '30px',
    [FiatCurrency.MXN]: '70px',
    [FiatCurrency.EUR]: '30px',
    [FiatCurrency.CAD]: '50px'
  });
  const padding = useMemo(() => matchLabelPadding[currency as FiatCurrency], [currency]);

  return (
    <Container className={isActive ? 'active' : ''} onSubmit={(e) => submitHandler(e, props)}>
      <RadioGroup handleChange={props.handleChange} active={props.values.group2} />
      {showFilters && props.values.group2 !== 'coin' && (
        <SelectModalButton
          onClick={handleOpen}
          selectedCoin={cryptoBalance}
          isSmaller
          height="56px"
          width={11}
          defaultIcon={props.values.group2}
          placeholder="Select cryptocurrency"
        />
      )}
      <CryptoCurrencyModal
        isBalance={false}
        setSelectValue={changeCryptoBalance}
        selectValue={cryptoBalance}
        options={selectOptions}
        handleClose={handleClose}
        open={isOpen}
        modalRef={modalRef}
      />
      {showFilters && (
        <InputBlock>
          <Text>Select transaction volume</Text>
          <InputsBox>
            {inputFilters.map((el) => (
              <Input
                paddingRight={padding}
                pattern="\d*(\.\d*)?"
                key={el.placeholder}
                {...el}
                label={symbol}
                name={el.placeholder}
              />
            ))}
          </InputsBox>
        </InputBlock>
      )}
      <Checkbox
        active
        text="Only transactions related to trading activity"
        name="relatedActivity"
        checked={props.values.relatedActivity}
        onChange={props.handleChange}
        width="100%"
      />
      <ButtonsBox>
        <BorderedButton type="button" onClick={cleatHandler(props.resetForm)}>
          Clear
        </BorderedButton>
        <StyledButton type="submit">Apply</StyledButton>
      </ButtonsBox>
    </Container>
  );
};
