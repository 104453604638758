import { ReactComponent as FoxIcon } from '../../../../../../assets/icons/fox.svg';
import { FONTS } from '../../../../../theme';
import { UserDTO } from '../../../../types/user.type';
import { addresseFormat } from '../../../../utils';
import { Avatar } from '../../../avatar';
import { ArrowTooltip } from '../../../tooltip';

import { Box, StyledText } from '../../../index.styled';
import { Balance } from './index.styled';

interface IProps {
  user: UserDTO | null;
  address?: string;
  onHover: () => void;
}

export const Account = ({ user, address, onHover }: IProps) => (
  <Box align="end" onClick={onHover} cursor="pointer">
    <Avatar src={user?.avatar || ''} />
    <ArrowTooltip title="View Account">
      <Box changeDirection cursor="pointer">
        <StyledText fontWeight={FONTS.WEIGHTS.medium} fontSize={FONTS.SIZES.xl}>
          {user?.name}
        </StyledText>
        <Box gap="6px" cursor="pointer">
          <Balance>{address && addresseFormat(address)}</Balance>
          {user?.provider === 'metamask' && <FoxIcon />}
        </Box>
      </Box>
    </ArrowTooltip>
  </Box>
);
