import styled from 'styled-components';
import { Box } from '../../../common/components';
import { TTheme } from '../../../common/types/index';
import { FAMILIES } from '../../../theme/fonts.const';
import { ThemeColors } from './theme';

export const Container = styled.form`
  display: none;
  flex-direction: column;
  background-color: ${({ theme: { type } }) => ThemeColors.filter[type as TTheme].background};
  border-radius: 12px;
  border: 1px solid ${({ theme: { type } }) => ThemeColors.filter[type as TTheme].border};
  padding: 12px 10px;
  position: absolute;
  z-index: 10000000000;
  min-width: 346px;
  right: 0;

  &.active {
    display: flex;
  }

  &>div: first-child {
    max-width: 285px;

    button {
      div {
        padding: 7px 2px;
        &>div: first-child {
          svg: first-child {
            height: 24px;
            width: 24px;
          }
        }
      }
    }
  }
`;

export const Text = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme: { type } }) => ThemeColors.filter[type as TTheme].text};

  font-family: ${FAMILIES.inter};
`;

export const InputBlock = styled(Box)`
  flex-direction: column;
  border-top: 1px solid ${({ theme: { type } }) => ThemeColors.filter[type as TTheme].lineBorder};
  margin: 12px 0;
  padding: 12px 0;

  border-bottom: 1px solid ${({ theme: { type } }) => ThemeColors.filter[type as TTheme].lineBorder};

  p {
    padding-bottom: 12px;
  }
`;

export const InputsBox = styled(Box)`
  width: 100%;
  & > div {
    margin-right: 12px;
  }
`;

export const ButtonsBox = styled(Box)`
  justify-content: end;
  margin-top: 24px;

  & > button {
    border-radius: 12px;
    width: 108px;
    margin: 0;
  }

  & > button:first-child {
    margin-right: 24px;
  }
`;
