import { Formik } from 'formik';
import { Box, SelectBalanceModal, StyledButton, Text } from '../../../common/components';
import { CURRENCY_OPTIONS } from '../../../common/consts/fiat-symbol.matcher';
import { useModal } from '../../../common/hooks/use-modal.hook';
import { ICurrencyOption, TokenBalance } from '../../../common/types/balance.type';
import { LoadingStatuses } from '../../../common/types/payment.type';
import { getBalance } from '../../../common/utils/get-balance.util';
import { topUpFormValidationSchema } from '../../../common/validation-schemas/top-up-form.validation-schema';
import { COLORS } from '../../../theme';
import { WEIGHTS } from '../../../theme/fonts.const';
import { RadioGroup } from '../radio-group';
import { AmountInput, FiatInput, SelectFiatModal } from './components';
import { initialValues } from './constatns';
import { useTopup } from './hooks/use-topup.hook';

import { StyledForm } from './index.styled';

interface IProps {
  handleCopleteForm: (statusCheck: LoadingStatuses | null) => void;
  balances: TokenBalance[];
  hideModal: (value: boolean) => void;
}

export const TopUpForm = ({ handleCopleteForm, balances, hideModal }: IProps) => {
  const { isOpen, handleClose, handleOpen } = useModal();
  const {
    isOpen: isSecondOpen,
    handleClose: handleSecondClose,
    handleOpen: handleSecondOpen
  } = useModal();

  const {
    selectAmount,
    setSelectAmount,
    isDisplayed,
    handleSubmit,
    isLoading,
    tranferLoading,
    choosedBalance,
    setBalance
  } = useTopup(handleCopleteForm, balances, hideModal);
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={topUpFormValidationSchema}
    >
      {(props) => (
        <Box width="100%">
          <StyledForm onSubmit={props.handleSubmit}>
            <AmountInput
              choosedBalance={choosedBalance}
              handleOpen={handleOpen}
              currency={selectAmount.value}
            />

            <FiatInput
              handleSecondOpen={handleSecondOpen}
              selectAmount={selectAmount}
              choosedBalance={choosedBalance}
            />

            <Text color={COLORS.coolGrey} fontSize="12px" fontWeight={WEIGHTS.normal}>
              {getBalance(choosedBalance)}
            </Text>

            <RadioGroup handleChange={props.handleChange} />
            {isDisplayed && (
              <Text color={COLORS.darkerGrey}>
                To top-up crypto funds, you will be redirected to another window.
              </Text>
            )}

            <StyledButton
              type="submit"
              disabled={(tranferLoading || isLoading) && !props.isValid && !props.dirty}
            >
              Top-Up
            </StyledButton>
          </StyledForm>

          <SelectBalanceModal
            setSelectValue={(balance: TokenBalance) => {
              setBalance(balance);
              props.setFieldValue('coinSelect', balance.symbol);
            }}
            selectValue={choosedBalance}
            options={balances.filter((balance) => !['WBTC'].includes(balance.symbol))}
            handleClose={handleClose}
            open={isOpen}
            isBalance={false}
          />

          <SelectFiatModal
            setSelectValue={(amount: ICurrencyOption) => {
              setSelectAmount(amount);
            }}
            selectValue={selectAmount}
            options={CURRENCY_OPTIONS}
            handleClose={handleSecondClose}
            open={isSecondOpen}
          />
        </Box>
      )}
    </Formik>
  );
};
