import styled from 'styled-components';
import { ThemeColors } from '../../../../consts';
import { TTheme } from '../../../../types';
import { EFormsCrypto } from '../../../../types/transaction.type';

export const StyledImage = styled.div<{
  index: number;
  left: number | string;
  formType: EFormsCrypto | null;
}>`
  z-index: ${({ index }) => index};
  position: absolute;
  width: 40px;
  left: ${({ left }) => left};

  ${({ formType, theme: { type } }) =>
    !formType
      ? `svg {
    fill: ${ThemeColors.containedButton[type as TTheme].color};
    width: 40px;
    height: 40px;
  }`
      : `  
      svg {
        width: 40px;
        height: 40px;
        fill: ${ThemeColors.cryptoIcon[type as TTheme].color};
        rect:first-child {
          fill: ${ThemeColors.cryptoIcon[type as TTheme][formType!]};
      }
  }`}
`;
