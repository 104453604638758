import React, { ChangeEvent } from 'react';
import { ArrowTooltip, Box, StyledText } from '../../../common/components';
import { SIZES, WEIGHTS } from '../../../theme/fonts.const';
import { RadioButton } from '../../../common/components/radio-button';
import { ReactComponent as UnlimitLogo } from '../../../../assets/icons/unlimit.svg';
import { ReactComponent as UnlimitWhiteLogo } from '../../../../assets/icons/unlimitWhite.svg';
import { ReactComponent as TransakLogo } from '../../../../assets/icons/transak.svg';
import { ReactComponent as Tooltip } from '../../../../assets/icons/tooltip.svg';
import { useTheme } from '../../../common/providers/theme.provider';
import { StyledIcon } from './index.styled';

interface IProps {
  handleChange: (e: string | ChangeEvent<any>) => void;
  // temporary prop
  isShow?: boolean;
}

export const RadioGroup = ({ handleChange, isShow = true }: IProps) => {
  const { currentTheme } = useTheme();
  const tooltipText =
    // eslint-disable-next-line max-len
    'Different providers offer different support for countries and banks. If you run into issues with a provider, switching providers might help.';
  return (
    <Box changeDirection margin="24px 0 0">
      <Box align="center" gap="6px">
        <StyledText fontSize={SIZES.l} fontWeight={WEIGHTS.normal}>
          Select Payment Provider
        </StyledText>
        <StyledIcon>
          <ArrowTooltip title={tooltipText}>
            <Tooltip />
          </ArrowTooltip>
        </StyledIcon>
      </Box>

      <Box role="group" changeDirection width="100%" margin="8px 0 0 0">
        {isShow && (
          <RadioButton id="1" checked name="group2" value="unlimit" handleChange={handleChange}>
            {currentTheme === 'light' ? <UnlimitLogo /> : <UnlimitWhiteLogo />}
          </RadioButton>
        )}
        <RadioButton
          checked={!isShow}
          handleChange={handleChange}
          id="2"
          name="group2"
          value="transak"
        >
          <TransakLogo />
        </RadioButton>
      </Box>
    </Box>
  );
};
