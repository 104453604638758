import styled from 'styled-components';
import { Box, ContainedButton } from '../common/components';
import { FAMILIES } from '../theme/fonts.const';

export const StyledBox = styled(Box)`
  margin: 34px 146px;

  @media screen and (max-width: 1000px) {
    margin: 0 2.25rem;
  }
`;

export const StyledButton = styled(ContainedButton)`
  padding: 10px 12px;
  width: auto;
  margin-bottom: 27px;
  font-family: ${FAMILIES.inter};

  svg {
    margin-right: 10px;
  }
`;
