import { ITransaction, TransactionType } from '../../types/transaction.type';
import { priceFormat } from '../../utils';

export const getValue = (el: Partial<ITransaction>) => {
  if (el.txType === TransactionType.DEX_INTERACTION) {
    return `${priceFormat(el.value?.toString() ?? '', 3)} USDC`;
  }
  const walletFee = el?.appFee || 0;

  return el?.tokenTicker && el?.tokensAmount
    ? `${priceFormat((parseFloat(el.tokensAmount) - walletFee).toString(), 4)} ${el?.tokenTicker} `
    : `${priceFormat((parseFloat(el?.value || '0') - walletFee).toString() ?? '', 3)} MATIC`;
};
