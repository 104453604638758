import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';

import { Box, Spinner, StyledButton } from '../common/components';
import { BorderedButton } from '../common/components/header/components/account-info/index.styled';
import { RenderInputs } from './components';
import { useSubmit } from './hooks/submit.hook';

import { ReactComponent as Arrow } from '../../assets/icons/arrowLeft.svg';
import { settingsValidationSchema } from '../common/validation-schemas/settings-validation-schema';
import { ButtonContainer, ButtonsBox, Container, GoBack, StyledBox, Title } from './index.styled';
import { useModal } from '../common/hooks/use-modal.hook';
import { ConfirmModal } from './components/confirm-modal';

export const SettingPageContainer = () => {
  const navigate = useNavigate();
  const handleNavigate = () => navigate('/home');

  const {
    submitHandler,
    onSubmit,
    clearHandler,
    currency,
    setCurrency,
    theme,
    setTheme,
    formValues,
    isLoading,
    IsEmail,
    isMutationLodaing
  } = useSubmit();

  const { isOpen, handleClose, handleOpen } = useModal();

  return (
    <Box changeDirection height="100%">
      <ConfirmModal isOpen={isOpen} handleClose={handleClose} />
      <StyledBox changeDirection>
        <div>
          <GoBack onClick={handleNavigate} type="button">
            <Arrow />
            Back to Home
          </GoBack>
        </div>

        <Formik
          initialValues={{ name: '', email: '', currency: 'USD', theme: 'system' }}
          validationSchema={settingsValidationSchema}
          onSubmit={onSubmit}
        >
          {(props) => (
            <Container onSubmit={(e) => submitHandler(e, props)}>
              <Title>General Settings</Title>

              {isLoading ? (
                <Box align="center" justify="center" margin="25px 0">
                  <Spinner />
                </Box>
              ) : (
                <RenderInputs
                  theme={theme}
                  setCurrency={setCurrency}
                  setTheme={setTheme}
                  currency={currency}
                  isEmail={IsEmail}
                  formValues={formValues}
                />
              )}
              <ButtonContainer>
                <BorderedButton
                  disabled={isLoading || isMutationLodaing}
                  type="button"
                  onClick={handleOpen}
                >
                  Delete account
                </BorderedButton>
                <ButtonsBox>
                  <BorderedButton
                    disabled={isLoading || isMutationLodaing}
                    type="button"
                    onClick={clearHandler(props.setFieldValue)}
                  >
                    Cancel
                  </BorderedButton>
                  <StyledButton disabled={isLoading || isMutationLodaing} type="submit">
                    Save
                  </StyledButton>
                </ButtonsBox>
              </ButtonContainer>
            </Container>
          )}
        </Formik>
      </StyledBox>
    </Box>
  );
};
