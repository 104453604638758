import styled from 'styled-components';

import { FAMILIES, SIZES, WEIGHTS } from '../../../theme/fonts.const';
import { ThemeColors } from '../../consts';
import { TTheme } from '../../types';

export const StyledLabel = styled.label`
  padding: 6px;
  border-radius: 50px;
  cursor: pointer;
  margin: 8px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14px 24px 14px 16px;
  gap: 14px;
  width: 100%;
  height: 47px;
  color: ${({ theme: { type } }) => ThemeColors.input[type as TTheme].color};

  background: ${({ theme: { type } }) => ThemeColors.input[type as TTheme].background};

  border: 1px solid ${({ theme: { type } }) => ThemeColors.input[type as TTheme].border};
  border-radius: 8px;

  &:hover,
  &:focus-within {
    background: rgba(${({ theme }) => theme.colors.lightPeriwinkle}, 0.1);
  }

  input {
    vertical-align: middle;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background: none;
    border: 0;
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.colors.lightPeriwinkle};
    appearance: none;
    padding: 0;
    margin: 0;
    transition: box-shadow 150ms cubic-bezier(0.95, 0.15, 0.5, 1.25);

    &:focus {
      outline: none;
    }

    &:checked {
      box-shadow: inset 0 0 0 6px rgba(255, 214, 63, 1);
    }
  }

  span {
    display: inline-block;
    line-height: 20px;
    padding: 0 8px;
    font-family: ${FAMILIES.inter};
    font-weight: ${WEIGHTS.normal};
    font-size: ${SIZES.l};
    color: ${({ theme: { type } }) => ThemeColors.input[type as TTheme].color};
  }
`;
export const StyledLabelBorderless = styled.label`
  padding: 6px;
  border-radius: 50px;
  cursor: pointer;
  margin: 8px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 14px;
  width: 100%;
  height: 47px;
  color: ${({ theme: { type } }) => ThemeColors.input[type as TTheme].color};

  &:hover,
  &:focus-within {
    background: rgba(${({ theme }) => theme.colors.lightPeriwinkle}, 0.1);
  }

  input {
    vertical-align: middle;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background: none;
    border: 0;
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.colors.lightPeriwinkle};
    appearance: none;
    padding: 0;
    margin: 0;
    transition: box-shadow 150ms cubic-bezier(0.95, 0.15, 0.5, 1.25);

    &:focus {
      outline: none;
    }

    &:checked {
      box-shadow: inset 0 0 0 6px rgba(255, 214, 63, 1);
    }
  }

  span {
    display: inline-block;
    line-height: 20px;
    padding: 0 8px;
    font-family: ${FAMILIES.inter};
    font-weight: ${WEIGHTS.normal};
    font-size: ${SIZES.l};
    color: ${({ theme: { type } }) => ThemeColors.input[type as TTheme].color};
  }
`;