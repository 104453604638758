import { useNavigate } from 'react-router-dom';
import { useTheme } from '../../../../providers/theme.provider';

import { ArrowTooltip } from '../../../tooltip';
import { SETTINGS_ICON } from './constants';
import { Container, Styledbutton } from './index.styled';
import { useAuth } from '../../../../../web3auth/web3auth.provider';
import { UserDTO } from '../../../../types/user.type';

export const SettingComponent = () => {
  const history = useNavigate();
  const { userInfo } = useAuth<UserDTO>();
  const { changeTheme } = useTheme();

  const lickHandler = (navigate?: string) => async () => {
    if (navigate) {
      history(navigate);
      return;
    }

    changeTheme(undefined, '', userInfo);
  };

  return (
    <Container>
      {SETTINGS_ICON.map(({ name, navigate, el: Icon, tooltip }) => (
        <ArrowTooltip key={name} title={tooltip}>
          <Styledbutton onClick={lickHandler(navigate)}>
            <Icon />
          </Styledbutton>
        </ArrowTooltip>
      ))}
    </Container>
  );
};
