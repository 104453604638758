import styled from 'styled-components';
import { FAMILIES, WEIGHTS } from '../../../../../theme/fonts.const';
import { ThemeColors } from '../../../../consts';
import { TTheme } from '../../../../types';
import { ReactComponent as SuccessIcon } from '../../../../../../assets/icons/success.svg';

export const BorderedButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 51px;
  width: 173px;
  height: 46px;
  background: ${({ theme: { type } }) => ThemeColors.BorderedButton[type as TTheme].background};
  border: 1px solid ${({ theme: { type } }) => ThemeColors.BorderedButton[type as TTheme].border};
  border-radius: 8px;
  font-weight: ${WEIGHTS.medium};
  font-size: 18px;
  line-height: 22px;
  color: ${({ theme: { type } }) => ThemeColors.BorderedButton[type as TTheme].color};
  cursor: pointer;
  font-family: ${FAMILIES.inter};
  margin-left: 12px;

  &:hover {
    background: ${({ theme: { type } }) =>
      ThemeColors.BorderedButton[type as TTheme].hoverBackground};
  }
`;

export const StyledIcon = styled(SuccessIcon)<{ isActive: boolean }>`
  margin-right: 6px;
  display: ${({ isActive }) => (isActive ? 'block' : 'none')};
`;
