import { TableWrapper } from '../../../home/index.styled';
import { getBorder } from '../../../home/utils/get-border.util';
import { ITransaction } from '../../types/transaction.type';
import { Transaction } from '../transaction';
import { convertArr } from './helper';

interface IProps {
  items: ITransaction[];
  isCrop?: boolean;
}

export const RenderTransactions = ({ items, isCrop = true }: IProps) => {
  const arr = convertArr(items, isCrop);

  return (
    <TableWrapper>
      {arr.map((el, i) => (
        <Transaction border={getBorder(i, arr.length)} {...el} key={el._id} />
      ))}
    </TableWrapper>
  );
};