type Sublist = string[];
type TableRow = string[];
export type Table = {
  headers: string[];
  rows: TableRow[];
};
type List = (string | Sublist)[];

export type Content = string | List | Table;
export const enum TermType {
  PARAGRAPH = 'paragraph',
  TABLE = 'table',
  LIST = 'list',
  ORDERED_LIST = 'ordered-list',
  SUBTITLE = 'subtitle'
}

export type Term = {
  type: TermType;
  content: Content;
};
