import React from 'react';
import { Box, ContainedButton, Spinner } from '..';
import { EmptyContainer, StyledTitle } from './index.styled';
import { ReactComponent as Arrow } from '../../../../assets/image/arrowRight.svg';
import { ReactComponent as Empty } from '../../../../assets/icons/empty.svg';

interface IProps {
  clickHandler: () => void;
  emptyDataText?: string;
  buttonText: string;
  tableTitle: string;
  children: JSX.Element;
  margin?: string;
  isButtonDisplay?: boolean;
  isLoading?: boolean;
}

export const RenderTable = ({
  clickHandler,
  buttonText,
  tableTitle,
  emptyDataText = 'No transactions found',
  margin,
  children,
  isButtonDisplay = true,
  isLoading
}: IProps) => (
  <Box changeDirection margin={margin}>
    <Box margin="0 0 18px 0" justify="space-between" align="center">
      <StyledTitle>{tableTitle} </StyledTitle>

      {isButtonDisplay && !isLoading ? (
        <ContainedButton onClick={clickHandler} type="button">
          {buttonText}
          <Arrow />
        </ContainedButton>
      ) : null}
    </Box>

    {isButtonDisplay || isLoading ? (
      children
    ) : (
      <EmptyContainer>
        {isLoading ? (
          <Box justify="center">
            <Spinner />
          </Box>
        ) : (
          <>
            <Empty />
            {emptyDataText}
          </>
        )}
      </EmptyContainer>
    )}
  </Box>
);
