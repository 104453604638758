import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export const usePagination = () => {
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState<number>(
    searchParams.get('page') ? +searchParams.get('page')! : 1
  );

  const handlePageChange = (type: string) => () => {
    switch (type) {
      case 'inc':
        setPage((prev) => prev + 1);
        break;
      case 'dec':
        setPage((prev) => prev - 1);
        break;
    }
  };

  return { handlePageChange, page, setPage };
};
