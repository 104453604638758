import { Box, StyledText, Text } from '../../../common/components';
import { TransactionStatusTypes } from '../../../common/types/transaction.type';
import { priceFormat } from '../../../common/utils';
import { COLORS, FONTS } from '../../../theme';
import { WEIGHTS } from '../../../theme/fonts.const';
import { StyledContainer } from '../../index.styled';
import { Amount } from '../amount';
import { StyledOperation } from './index.styled';

interface IProps {
  name: string;
  ticker: string;
  priceInUsd: number;
  image: string;
  balance: number;
  priceChangePercentage: number;
  border: string;
  isTopCryptos?: boolean;
}

export const History = ({
  image,
  name,
  priceChangePercentage,
  ticker,
  priceInUsd,
  balance,
  border,
  isTopCryptos
}: IProps) => {
  const isIncome = priceChangePercentage > 0;

  return (
    <StyledContainer border={border}>
      <Box>
        <img width="40px" height="40px" src={image} alt="" />
        <Box changeDirection margin="0 0 0 10px">
          <Box>
            <StyledText
              fontSize={FONTS.SIZES.l}
              fontWeight={FONTS.WEIGHTS.medium}
              fontFamily={FONTS.FAMILIES.inter}
            >
              {name}
            </StyledText>
            <StyledOperation isInCome={isIncome}>
              {`${isIncome ? '+' : ''}${priceChangePercentage.toFixed(2)}%`}
            </StyledOperation>
          </Box>

          <Text color={COLORS.coolGrey} fontSize="14px" fontWeight={WEIGHTS.normal}>
            {ticker}
          </Text>
        </Box>
      </Box>

      <Amount
        operation={`${priceFormat(balance.toString(), 4)} ${ticker}`}
        total={priceInUsd.toString()}
        status={TransactionStatusTypes.CONFIRMED}
        showTotal={isTopCryptos}
      />
    </StyledContainer>
  );
};