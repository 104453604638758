import { STORAGE_KEYS } from '../consts/app-keys.const';
import { TTheme, TUserTheme } from '../types';
import { getLocalStorage } from './localstorage.util';

export const getCurrentTheme = (theme?: TUserTheme) => {
  const themeQuery = window.matchMedia('(prefers-color-scheme: light)');
  const systemTheme = themeQuery.media.includes('light') ? 'light' : 'dark';

  if (theme === 'system') {
    return systemTheme;
  }

  const localStorageTheme = getLocalStorage(STORAGE_KEYS.THEME) as null | TTheme;

  return theme || localStorageTheme || systemTheme;
};
