import * as React from 'react';
import { ReactComponent as Close } from '../../../../assets/icons/closeIcon.svg';
import { Box } from '../index.styled';
import { Container, StyledButton, StyledModal } from './index.styled';

interface IModal {
  open: boolean;
  hide?: boolean;
  onClose: () => void;
  header?: React.ReactNode;
  closeButton?: boolean;
  content: React.ReactNode;
  maxWidth?: string;
  background?: 'dark' | 'light';
  modalRef?: React.RefObject<HTMLDivElement>;
}

const Modal = ({
  open,
  onClose,
  header,
  content,
  maxWidth,
  closeButton = true,
  background = 'light',
  hide,
  modalRef
}: IModal) => (
  <StyledModal
    hideBackdrop={hide}
    ref={modalRef}
    open={open}
    hide={hide}
    disableAutoFocus
    disableEnforceFocus
    disableRestoreFocus
    keepMounted={false}
  >
    <Container background={background} maxWidth={maxWidth}>
      <Box className="header" width="100%" justify="space-between" align="start">
        {header}
        {closeButton && (
          <StyledButton type="button" onClick={onClose}>
            <Close />
          </StyledButton>
        )}
      </Box>
      {content}
    </Container>
  </StyledModal>
);
export default Modal;
