import { CURRENCY_OPTIONS } from '../common/consts/fiat-symbol.matcher';
import { UserDTO } from '../common/types/user.type';
import { THEME_OPTIONS } from './constants';

export const findTheme = (userInfo: UserDTO | null) =>
  THEME_OPTIONS.find(({ value }) => value === (userInfo?.theme ?? 'system')) ?? THEME_OPTIONS[0];

export const findCurrency = (userInfo: UserDTO | null) =>
  CURRENCY_OPTIONS.find(({ value }) => value === (userInfo?.currencyConversion ?? 'usd')) ??
  CURRENCY_OPTIONS[0];
