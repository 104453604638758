import { COLORS } from '../../theme';

export const ThemeColors = {
  list: {
    light: {
      color: COLORS.black
    },
    dark: {
      color: COLORS.white
    }
  },
  logo: {
    light: {
      color: COLORS.primaryOrange
    },
    dark: {
      color: COLORS.secondaryOrange
    }
  },
  text: {
    light: {
      color: COLORS.darkGunmetal
    },
    dark: {
      color: COLORS.white
    }
  },
  title: {
    light: {
      color: COLORS.secondaryOrange
    },
    dark: {
      color: COLORS.gargoyleGas
    }
  },
  container: {
    light: {
      border: COLORS.blonde
    },
    dark: {
      border: COLORS.bronzeYellow
    }
  },
  maincontainer: {
    light: {
      background: COLORS.cosmicLatte
    },
    dark: {
      background: COLORS.primary
    }
  },
  modal: {
    light: {
      backgroundDark: COLORS.white,
      backgroundLight: COLORS.white,
      border: COLORS.blonde,
      darkBorder: COLORS.blonde
    },
    dark: {
      backgroundDark: COLORS.primary,
      backgroundLight: COLORS.darkBlue100,
      border: COLORS.primary,
      darkBorder: COLORS.bronzeYellow
    }
  },
  select: {
    light: {
      background: COLORS.ghostWhite,
      border: COLORS.azureishWhite,
      hoverBackground: COLORS.antiFlashWhite
    },
    dark: {
      background: COLORS.primary,
      hoverBackground: COLORS.darkElectricBlue,
      border: '#8D94AB'
    }
  },
  input: {
    light: {
      background: COLORS.ghostWhite,
      border: COLORS.azureishWhite,
      successBorder: COLORS.primaryGreen,
      hoverBackground: COLORS.antiFlashWhite,
      placeholder: COLORS.darkElectricBlue,
      color: COLORS.darkBlue,
      label: COLORS.darkBlue
    },
    dark: {
      background: COLORS.primary,
      successBorder: COLORS.successGreen50,
      hoverBackground: COLORS.darkElectricBlue,
      placeholder: COLORS.coolGrey,
      border: COLORS.darkElectricBlue,
      color: COLORS.white,
      label: COLORS.white
    }
  },
  containedButton: {
    light: {
      background: COLORS.cosmicLatte,
      border: COLORS.mustard,
      textColor: COLORS.black,
      color: COLORS.primary,
      hoverColor: COLORS.secondaryOrange
    },
    dark: {
      background: COLORS.primary,
      border: COLORS.mustard,
      textColor: COLORS.white,
      hoverColor: COLORS.secondaryOrange,
      color: COLORS.white,
      buttonBcackgroundColor: COLORS.primaryOrangeLighter,
      buttonHoverBcackgroundColor: COLORS.orange40
    }
  },
  StyledButton: {
    light: {
      background: COLORS.gargoyleGas,
      border: COLORS.mustard,
      color: COLORS.white,
      disabledBackground: COLORS.orange40,
      disabledBorder: COLORS.mustard
    },
    dark: {
      background: COLORS.gargoyleGas,
      border: COLORS.mustard,
      color: COLORS.independence,
      disabledBackground: COLORS.darkBluePrimary,
      disabledBorder: COLORS.darkBluePrimary
    }
  },
  BorderedButton: {
    light: {
      background: COLORS.ghostWhite,
      border: COLORS.azureishWhite,
      color: COLORS.primary,
      hoverBackground: COLORS.antiFlashWhite
    },
    dark: {
      background: COLORS.primary,
      border: COLORS.lightPeriwinkle,
      color: COLORS.white,
      hoverBackground: COLORS.darkBluePrimary
    }
  },
  outlineButton: {
    light: {
      background: COLORS.ghostWhite,
      border: COLORS.azureishWhite,
      color: COLORS.primary,
      hoverBackground: COLORS.antiFlashWhite
    },
    dark: {
      background: COLORS.primary,
      border: COLORS.independence,
      color: COLORS.white,
      hoverBackground: COLORS.darkBluePrimary
    }
  },
  radio: {
    light: {
      background: COLORS.white,
      border: COLORS.azureishWhite,
      color: COLORS.darkGunmetal
    },
    dark: {
      background: COLORS.primary,
      border: COLORS.darkElectricBlue,
      color: COLORS.white
    }
  },
  setings: {
    light: { color: COLORS.slateGray, hover: COLORS.secondaryOrange },
    dark: { color: COLORS.white, hover: COLORS.secondaryOrange }
  },
  authIcons: {
    light: {
      background: COLORS.white,
      border: COLORS.azureishWhite
    },
    dark: {
      background: COLORS.primary,
      border: COLORS.independence
    }
  },
  emptyBlock: {
    light: {
      background: COLORS.emptyLight,
      border: COLORS.blonde,
      color: COLORS.secondaryOrange
    },
    dark: {
      background: COLORS.emptyDark,
      border: COLORS.bronzeYellow,
      color: COLORS.secondaryOrange
    }
  },
  balance: {
    light: {
      color: COLORS.darkElectricBlue
    },
    dark: {
      color: COLORS.lightPeriwinkle
    }
  },
  status: {
    light: {
      success: COLORS.successGreen,
      error: COLORS.errorRed
    },
    dark: {
      success: COLORS.successGreen50,
      error: COLORS.errorRed30
    }
  },
  cryptoIcon: {
    light: {
      color: COLORS.white,
      sendStroke: COLORS.primaryOrange,
      topUpStroke: COLORS.primaryBlue,
      cashOutStroke: COLORS.primaryGreen
    },
    dark: {
      color: COLORS.primary,
      sendStroke: COLORS.mustard,
      topUpStroke: COLORS.blue40,
      cashOutStroke: COLORS.successGreen50
    }
  }
};
