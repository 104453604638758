import React from 'react';
import { Box, StyledText } from '../../../../../index.styled';
import { CopyButton, StatusText, StyledLink } from './index.styled';
import { WEIGHTS } from '../../../../../../../theme/fonts.const';
import { TransactionStatusTypes } from '../../../../../../types/transaction.type';
import { copyText } from '../../../../../../utils/copy-text.util';
import { TITLES } from '../../../../constants';

interface IProps {
  hash: string;
  type: TransactionStatusTypes;
}

export const Actions = ({ hash, type }: IProps) => (
  <>
    <Box justify="space-between" margin="24px 0 0 ">
      <StyledText fontSize="1rem" fontWeight={WEIGHTS.medium}>
        Status
      </StyledText>
      <StyledLink href={`${process.env.REACT_APP_POLIGON_URL ?? ''}${hash}`} target="_blank">
        View on Polyscan
      </StyledLink>
    </Box>
    <Box justify="space-between" margin="8px 0 0">
      <StatusText status={type}>{TITLES[type].title}</StatusText>
      <CopyButton type="button" onClick={copyText(hash)}>
        Copy Transaction Hash
      </CopyButton>
    </Box>
  </>
);
