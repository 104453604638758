import styled from 'styled-components';
import { ReactComponent as Border } from '../../../../assets/image/border.svg';
import { Box, Text } from '../../../common/components';
import { ThemeColors } from './theme';
import { TTheme } from '../../../common/types';

export const StyledText = styled(Text)`
  color: ${({ theme: { type } }) => ThemeColors.text[type as TTheme].color};
  cursor: pointer;
`;

export const UnderlineText = styled(Text)`
  text-decoration: underline;
  color: ${({ theme: { type } }) => ThemeColors.text[type as TTheme].color};
  cursor: pointer;
`;

export const StyledLink = styled.div`
  text-decoration: none;
  cursor: pointer;
  &:hover {
    div > p {
      opacity: 0.6;
    }
    cursor: pointer;
  }
`;

export const StyledBorder = styled(Border)`
  margin: 0 10px;
`;

export const StyledBox = styled(Box)`
  justify-content: end;

  @media screen and (max-width: 1160px) {
    width: 100%;
  }
`;
