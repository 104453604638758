import React, { FC, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { TTheme, TUserTheme } from '../types';
import * as theme from '../../theme';
import { getCurrentTheme } from '../utils/get-current-theme.util';
import { setLocalStorage } from '../utils/localstorage.util';
import { STORAGE_KEYS } from '../consts/app-keys.const';
import { useAuth } from '../../web3auth/web3auth.provider';
import { UserDTO } from '../types/user.type';
import { userService } from '../../services/user.service';

export interface ThemeContextType {
  changeTheme: (value?: TTheme, pubKey?: string, user?: UserDTO | null) => void;
  currentTheme: TTheme;
  getCurrentTheme: (theme?: TUserTheme | undefined) => 'light' | 'dark';
}

export const ThemeContext = createContext<ThemeContextType>({
  changeTheme: () => null,
  currentTheme: 'dark',
  getCurrentTheme: () => 'dark'
} as ThemeContextType);

export function useTheme() {
  return useContext(ThemeContext as React.Context<ThemeContextType>);
}

type ThemeProviderProps = {
  children: any;
};

export const ThemeContextProvider: FC<ThemeProviderProps> = ({ children }) => {
  const { userInfo, setUser } = useAuth<UserDTO>();

  const [currentTheme, setCurrentTheme] = useState<TTheme>(getCurrentTheme(userInfo?.theme));

  const convertTheme = async (type: TTheme, pubKey?: string, user?: UserDTO | null) => {
    setCurrentTheme(type);
    setLocalStorage(STORAGE_KEYS.THEME, type);

    if (pubKey) {
      userService.updateUserSettings({ pubKey, theme: type });
      user && setUser({ ...user!, theme: type });
    }
  };

  const changeTheme = (cTheme?: TTheme, pubKey?: string, user?: UserDTO | null) => {
    if (cTheme) {
      convertTheme(cTheme, pubKey, user);
      return;
    }

    if (currentTheme === 'light') {
      convertTheme('dark', pubKey, user);
      return;
    }

    convertTheme('light', pubKey, user);
  };

  const memoedData = useMemo(
    () => ({
      currentTheme,
      changeTheme,
      getCurrentTheme
    }),
    [currentTheme]
  );

  useEffect(() => {
    convertTheme(getCurrentTheme(userInfo?.theme));
  }, []);

  return (
    <ThemeContext.Provider value={memoedData}>
      <ThemeProvider
        theme={{
          ...theme,
          colors: theme.COLORS,
          type: currentTheme
        }}
      >
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};
