import { Grid } from '@mui/material';
import { FC, useMemo } from 'react';
import { Box, StyledText } from '../../../../common/components';
import { CoinType } from '../../../../common/types/transaction.type';
import { priceFormat } from '../../../../common/utils';

import { useRate } from '../../../../common/hooks/use-rate.hook';
import { useCurrency } from '../../../../common/providers/currency.provider';
import {
  SecondaryStyledText,
  StyledEstimationSection,
  StyledEstimationWrapper
} from '../index.styled';

type EstimationSectionProps = {
  fee: number | undefined;
  walletFee: number | undefined;
  amount: number;
  token: CoinType;
  fromEthToGWei: (value: string) => string;
};

export const EstimationSection: FC<EstimationSectionProps> = ({
  fee,
  walletFee,
  amount,
  token,
  fromEthToGWei
}) => {
  const { currency, symbol } = useCurrency();
  const { converted: feeInFiat } = useRate(fee, 'MATIC', currency);
  const totalAmount = useMemo(
    () => (walletFee ? Number(amount) + Number(walletFee) : amount),
    [amount, walletFee]
  );
  const { converted: amountInFiat } = useRate(totalAmount, token, currency);
  const feeInGwei = fromEthToGWei(fee?.toString() ?? '');

  return (
    <StyledEstimationWrapper
      container
      paddingX="10px"
      paddingY="8px"
      direction="column"
      borderRadius="12px"
    >
      <StyledEstimationSection>
        {walletFee && (
          <div style={{ paddingBottom: '12px' }}>
            <Box justify="space-between">
              <StyledText fontWeight="400" fontSize="14px">
                Wallet Fee
              </StyledText>
              <StyledText fontWeight="400" fontSize="14px">
                {priceFormat(walletFee.toString(), 6)} {token}
              </StyledText>
            </Box>
          </div>
        )}
        <Box justify="space-between">
          <StyledText fontWeight="400" fontSize="14px">
            Network Fee
          </StyledText>
          <StyledText fontWeight="400" fontSize="14px">
            {priceFormat(feeInGwei, 2)} GWei
          </StyledText>
        </Box>
        <Box justify="space-between">
          <SecondaryStyledText fontWeight="400" fontSize="12px">
            Network Fee
          </SecondaryStyledText>
          <SecondaryStyledText fontWeight="400" fontSize="14px">
            {symbol} {feeInFiat !== undefined ? priceFormat(feeInFiat.toString(), 6) : ''}
          </SecondaryStyledText>
        </Box>
      </StyledEstimationSection>

      <Grid item paddingTop="20px" container justifyContent="space-between">
        <StyledText fontWeight="500" fontSize="16px">
          Total Amount
        </StyledText>
        <StyledText fontWeight="500" fontSize="16px">
          {priceFormat(totalAmount.toString(), 6)} {token} + {priceFormat(feeInGwei, 2)} GWei
        </StyledText>
      </Grid>
      <Grid item container justifyContent="space-between">
        <SecondaryStyledText fontWeight="400" fontSize="12px">
          Amount + Fees
        </SecondaryStyledText>
        <SecondaryStyledText fontWeight="400" fontSize="14px">
          {symbol}
          {feeInFiat !== undefined && amountInFiat !== undefined
            ? priceFormat(Number(feeInFiat + amountInFiat).toString(), 3)
            : ''}
        </SecondaryStyledText>
      </Grid>
    </StyledEstimationWrapper>
  );
};
