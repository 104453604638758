import React from 'react';
import { Grid } from '@mui/material';
import { Box } from '../../../../../common/components';
import { SelectFiatBtn } from '../select-fiat-btn';
import { Converted } from '../converted';
import { ICurrencyOption, TokenBalance } from '../../../../../common/types/balance.type';

interface IProps {
  handleSecondOpen: () => void;
  selectAmount: ICurrencyOption;
  choosedBalance: TokenBalance;
}

export const FiatInput = ({ handleSecondOpen, selectAmount, choosedBalance }: IProps) => (
  <Box justify="space-between">
    <Grid container columnGap={1}>
      <Grid xs={5}>
        <SelectFiatBtn onClick={handleSecondOpen} selectedCoin={selectAmount} />
      </Grid>
      <Grid xs>
        <Converted
          choosedBalance={choosedBalance}
          currency={selectAmount.value}
          selectValue={selectAmount}
        />
      </Grid>
    </Grid>
  </Box>
);
