import React from 'react';
import dayjs from 'dayjs';
import { Box, Text, StyledText } from '../../..';
import { COLORS, FONTS } from '../../../../../theme';

import 'dayjs/locale/en';

interface IProps {
  data: string;
}

export const Data = ({ data }: IProps) => {
  const splitedData = dayjs(data).locale('en').format('D MMM').split(' ');

  return (
    <Box changeDirection margin="0 15px 0 0 ">
      <StyledText
        color={COLORS.darkGunmetal}
        fontSize={FONTS.SIZES.l}
        fontWeight={FONTS.WEIGHTS.medium}
        fontFamily={FONTS.FAMILIES.inter}
        textAlign="center"
      >
        {splitedData[0]}
      </StyledText>
      <Text
        color={COLORS.coolGrey}
        fontSize="14px"
        fontWeight={FONTS.WEIGHTS.normal}
        fontFamily={FONTS.FAMILIES.inter}
      >
        {splitedData[1]}
      </Text>
    </Box>
  );
};
