import { ReactComponent as Arrow } from '../../../../../../../../assets/icons/bigArrowRight.svg';
import { TransactionType } from '../../../../../../types/transaction.type';
import { Avatar } from '../../../../../avatar';
import { Box } from '../../../../../index.styled';
import { Spinner } from '../../../../../loader';
import { AccountSender, FromTitle } from './components';
import { AccountText, ArrowBlock, Container, HeadBlock } from './index.styled';

export interface IProps {
  otherAccount: string;
  isForYou: boolean;
  currentIamge: string;
  otherImage: string;
  isLoading: boolean;
  txType: TransactionType;
}

export const TransactionSender = ({
  otherAccount,
  isForYou,
  currentIamge,
  otherImage,
  isLoading,
  txType
}: IProps) => (
  <Container>
    <HeadBlock>
      <p>FROM</p>
      <p>TO</p>
    </HeadBlock>
    {isLoading ? (
      <Box justify="center" padding="10px 0 0">
        <Spinner width="30px" />
      </Box>
    ) : (
      <Box justify="space-between" align="center" margin="12px 0 0">
        <FromTitle
          otherAccount={otherAccount}
          txType={txType}
          otherImage={otherImage}
          currentIamge={currentIamge}
          isForYou={isForYou}
        />

        <ArrowBlock>
          <Arrow />
        </ArrowBlock>

        {isForYou ? (
          <AccountText>
            <Avatar width={34} src={currentIamge} /> Your Account
          </AccountText>
        ) : (
          <AccountSender senderImage={otherImage} account={otherAccount} />
        )}
      </Box>
    )}
  </Container>
);
