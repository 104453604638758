import styled from 'styled-components';
import { ThemeColors } from '../../../../consts';
import { TTheme } from '../../../../types';

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 2rem 0 0;
  svg {
    fill: ${({ theme: { type } }) => ThemeColors.setings[type as TTheme].color};

    &:hover {
      fill: ${({ theme: { type } }) => ThemeColors.setings[type as TTheme].hover};
    }
  }
`;

export const Styledbutton = styled.button`
  display: flex;
  border: none;
  background: none;
  padding: 0.75rem;
  width: 3rem;
  height: 3rem;
  align-items: center;
  cursor: pointer;
`;
