import { useFormikContext } from 'formik';
import { useEffect, useMemo } from 'react';
import {
  FiatCurrency,
  matchFiatCurrencySymbol
} from '../../../../common/consts/fiat-symbol.matcher';
import { TokenBalance } from '../../../../common/types/balance.type';
import { IValues } from '../types';

import { Input } from '../../../../common/components';
import { rateService } from '../../../../common/services';
import { IOption } from '../../../../common/types/services.type';
import { priceFormat } from '../../../../common/utils';

interface IProps {
  choosedBalance: TokenBalance;
  currency: string;
  selectValue: IOption;
}

export const Converted = ({ choosedBalance, currency, selectValue }: IProps) => {
  const { setFieldValue, values } = useFormikContext<IValues>();
  const symbol = useMemo(() => matchFiatCurrencySymbol[currency as FiatCurrency], [currency]);

  const extraChange = (amount: string) => async () => {
    const data = await rateService.convert(selectValue.value, choosedBalance.symbol, +amount || 0);
    setFieldValue('coin', typeof data === 'number' ? priceFormat(data.toString(), 3) : '0');
  };

  useEffect(() => {
    if (values.amount !== '') {
      rateService
        .convert(selectValue.value, choosedBalance.symbol, +values.amount || 0)
        .then((data) => {
          setFieldValue('coin', typeof data === 'number' ? priceFormat(data.toString(), 3) : '0');
        });
    }
  }, [selectValue.value]);

  return (
    <Input
      height="58px"
      name="amount"
      placeholder="Enter Amount"
      label={symbol}
      pattern="\d*(\.\d{0,3})?"
      paddingRight="35px"
      extraChange={extraChange}
    />
  );
};
