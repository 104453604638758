/* eslint-disable import/no-cycle */
import styled from 'styled-components';
import { Box } from '../../../common/components';
import { ThemeColors } from '../../theme';
import { TTheme } from '../../../common/types';

export const ItemWrapper = styled(Box)`
  cursor: pointer;
`;

export const Container = styled(Box)`
  border-radius: 0.75rem;
  padding: 0.5rem 1.3rem 1.5rem;
  flex-direction: column;
  width: 100%;

  background: ${({ theme: { type } }) => ThemeColors.table[type as TTheme].background};
  border: 1px solid ${({ theme: { type } }) => ThemeColors.table[type as TTheme].border};
`;
