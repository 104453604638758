import React, { FC } from 'react';
import { Li, Ol, Text, Ul } from '../index.styled';

type ListProps = {
  items: (string | string[])[];
};

export const List: FC<ListProps> = ({ items }) => (
  <Ol>
    {items.map((item, idx) => (
      <li key={idx}>{typeof item === 'string' ? <Text>{item}</Text> : <List items={item} />}</li>
    ))}
  </Ol>
);

export const BulletList: FC<ListProps> = ({ items }) => (
  <Ul>
    {items.map((item, idx) => (
      <Li showBullet={typeof item === 'string'} key={idx}>
        {typeof item === 'string' ? <Text>{item}</Text> : <BulletList items={item} />}
      </Li>
    ))}
  </Ul>
);
