import { COLORS } from '../../../../../theme';

export const ThemeColors = {
  status: {
    light: {
      text: COLORS.slateGray,
      success: COLORS.successGreen,
      error: COLORS.errorRed
    },
    dark: {
      text: COLORS.lightPeriwinkle,
      success: COLORS.successGreen50,
      error: COLORS.errorRed30
    }
  },
  container: {
    light: {
      header: COLORS.primary,
      border: COLORS.azureishWhite,
      background: COLORS.ghostWhite,
      grayText: COLORS.slateGray,
      copyIcon: COLORS.slateGray,
      arrowBorder: COLORS.azureishWhite,
      arrowBackground: COLORS.white,
      arrowColor: COLORS.slateGray
    },
    dark: {
      header: COLORS.white,
      border: COLORS.coolGrey,
      background: COLORS.primary,
      grayText: COLORS.lightPeriwinkle,
      copyIcon: COLORS.lightPeriwinkle,
      arrowBorder: COLORS.independence,
      arrowBackground: COLORS.darkBlue100,
      arrowColor: COLORS.lightPeriwinkle
    }
  }
};
