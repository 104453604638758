import { getIn, useFormikContext } from 'formik';
import { useEffect, useMemo } from 'react';
import { Box } from '../../../../../common/components';
import { useCurrency } from '../../../../../common/providers/currency.provider';
import { priceFormat } from '../../../../../common/utils';
import { CustomError, Div, Input, Label, StyledDiv, Value } from './index.styled';

type InputProps = {
  placeholder?: string;
  name: string;
  label: string;
  type?: string;
  pattern?: string;
  width?: string;
  height?: string;
  readonly?: boolean;
  paddingRight?: string;
  calculatedValue: string | number | undefined;
  onChange?: (value: any) => void;
  disabled?: boolean;
  bottomHeight?: string;
};

export const CustomInput = ({
  placeholder,
  name,
  label,
  pattern,
  type,
  height,
  width,
  readonly,
  paddingRight,
  onChange,
  calculatedValue,
  disabled = false,
  bottomHeight
}: InputProps) => {
  const { values, setFieldValue, errors, validateField } = useFormikContext();
  const isError = useMemo(() => getIn(errors, name) !== undefined, [errors]);
  const { symbol } = useCurrency();

  useEffect(() => {
    if (onChange) {
      onChange(getIn(values, name));
    }
  }, [getIn(values, name)]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.validity.valid) {
      setFieldValue(name, e.target.value);
    }
    validateField(name);
  };

  return (
    <Div width={width}>
      <StyledDiv isError={isError}>
        <Box changeDirection>
          <Input
            height={height}
            type={type || 'text'}
            onChange={handleChange}
            value={getIn(values, name) ?? ''}
            pattern={pattern}
            placeholder={placeholder}
            name={name}
            readOnly={readonly}
            isError={isError}
            paddingRight={paddingRight}
            isCalculated={!!calculatedValue}
            disabled={disabled}
          />
          {calculatedValue ? (
            <Value isValue={!!getIn(values, name)}>
              {symbol} {priceFormat(calculatedValue.toString(), 3)}
            </Value>
          ) : null}
        </Box>
        <Label isError={isError} htmlFor={name}>
          {label}
        </Label>
      </StyledDiv>

      <CustomError bottomHeight={bottomHeight}>{getIn(errors, name)}</CustomError>
    </Div>
  );
};
