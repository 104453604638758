import { Typography, TypographyProps } from '@mui/material';
import { FC } from 'react';
import { COLORS } from '../../../theme';

type TColorVariants = 'primary' | 'secondary' | 'inverted' | string;

interface IProps extends TypographyProps {
  colorVariant?: TColorVariants;
}

export const Text: FC<IProps> = ({ colorVariant, variant, align, children, ...props }) => (
  <Typography fontFamily="Inter" color={COLORS.primary} variant={variant} align={align} {...props}>
    {children}
  </Typography>
);
