import { Box } from '../../../common/components';

import { useCurrency } from '../../../common/providers/currency.provider';
import { TransactionStatusTypes } from '../../../common/types/transaction.type';
import { priceFormat } from '../../../common/utils';
import { AmountText, Operation } from './index.styled';

interface IProps {
  operation?: string;
  total: string;
  status: TransactionStatusTypes;
  showTotal?: boolean;
}

export const Amount = ({ operation, total, status, showTotal }: IProps) => {
  const { symbol } = useCurrency();
  return (
    <Box changeDirection justify="center">
      <Operation status={status}>{operation} </Operation>

      {showTotal && (
        <AmountText>
          {symbol} {priceFormat(total, 3)}
        </AmountText>
      )}
    </Box>
  );
};