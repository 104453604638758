import { useMemo, useState } from 'react';
import { ReactComponent as FoxIcon } from '../../../../../../assets/icons/fox.svg';
import { COLORS, FONTS } from '../../../../../theme';
import { UserDTO } from '../../../../types/user.type';
import { addresseFormat } from '../../../../utils';
import { copyText } from '../../../../utils/copy-text.util';
import { Avatar } from '../../../avatar';
import { StyledButton } from '../../../button';
import { Box, StyledText, Text } from '../../../index.styled';
import { ArrowTooltip } from '../../../tooltip';
import { BorderedButton, StyledIcon } from './index.styled';

interface IProps {
  logout: () => void;
  address: string;
  userInfo: UserDTO | null;
}

export const AccountInfo = ({ logout, address, userInfo }: IProps) => {
  const [isActive, setIsActive] = useState(false);

  const tooltipText = useMemo(() => (isActive ? 'Copied' : 'Copy Link'), [isActive]);

  const clickHandler = () => {
    setIsActive(true);

    copyText(address ?? '')();
  };

  const handleMouseLeave = () => {
    setTimeout(() => {
      setIsActive(false);
    }, 200);
  };

  return (
    <div>
      <Box justify="space-between" width="100%">
        <Box padding="2rem 0 0" width="100%" changeDirection align="center">
          <Avatar src={userInfo?.avatar || ''} />
          <Box changeDirection cursor="pointer" margin="10px 0 0">
            <StyledText
              fontWeight={FONTS.WEIGHTS.medium}
              fontSize={FONTS.SIZES.xl}
              textAlign="center"
            >
              {userInfo?.name}
            </StyledText>
            <Box gap="6px" justify="center" cursor="pointer">
              <Text fontSize={FONTS.SIZES.m} color={COLORS.darkElectricBlue}>
                {address && addresseFormat(address)}
              </Text>
              {userInfo?.provider === 'metamask' && <FoxIcon />}
            </Box>
          </Box>
        </Box>
      </Box>

      <Box onMouseLeave={handleMouseLeave} justify="center" margin="27px auto 0" width="100%">
        <ArrowTooltip
          title={
            <Box align="center">
              <StyledIcon isActive={isActive} />
              <StyledText fontSize="12px">{tooltipText}</StyledText>
            </Box>
          }
        >
          <StyledButton
            isActive={isActive}
            width="auto"
            margin="0 12px 0"
            padding="12px 25px"
            onClick={clickHandler}
          >
            Copy Address
          </StyledButton>
        </ArrowTooltip>
        <BorderedButton onClick={logout}>Log Out</BorderedButton>
      </Box>
    </div>
  );
};
