import React from 'react';

import { useQueryClient } from 'react-query';
import { QUERY_KEYS } from '../../../../common/consts/app-keys.const';
import { useTransak } from '../../../../common/hooks/use-transak.hook';
import { ICurrencyOption, TokenBalance } from '../../../../common/types/balance.type';
import { LoadingStatuses } from '../../../../common/types/payment.type';
import { CoinType } from '../../../../common/types/transaction.type';
import { UserDTO } from '../../../../common/types/user.type';
import { findCurrency } from '../../../../setting/utils';
import { useAuth } from '../../../../web3auth/web3auth.provider';
import { IValues } from '../types';
import { useUnlimit } from '../../../../common/hooks/use-unlimit.hook';

const defaultBalance = {
  name: 'Polygon',
  symbol: CoinType.MATIC,
  balance: 0,
  toRateBalance: 0
};

export const useTopup = (
  handleCopleteForm: (statusCheck: LoadingStatuses | null) => void,
  balances: TokenBalance[],
  hideModal: (value: boolean) => void
) => {
  const { balance, userInfo } = useAuth<UserDTO>();
  const [choosedBalance, setBalance] = React.useState<TokenBalance>(defaultBalance);
  const [selectAmount, setSelectAmount] = React.useState<ICurrencyOption>(findCurrency(userInfo));
  const [isDisplayed, setIsDisplayed] = React.useState(false);
  const queryClient = useQueryClient();
  const onWidgetClose = () => {
    hideModal(false);
  };

  const onOrderSuccess = () => {
    hideModal(false);
    queryClient.invalidateQueries([QUERY_KEYS.HISTORY]);
    handleCopleteForm(LoadingStatuses.SUBMITTED);
  };

  const onOrderFailed = () => {
    hideModal(false);
    handleCopleteForm(LoadingStatuses.REJECTED);
  };

  const { initTransak } = useTransak(onWidgetClose, onOrderSuccess, onOrderFailed);
  const { initUnlimit } = useUnlimit();

  const handleSubmit = async (values: IValues) => {
    if (!isDisplayed) {
      setIsDisplayed(true);
    }

    if (isDisplayed && values.group2) {
      if (values.group2 === 'transak') {
        try {
          initTransak({
            ramp: 'BUY',
            cryptoCurrency: choosedBalance.symbol,
            fiatCurrency: selectAmount.value,
            amount: parseFloat(values.amount)
          });
        } catch (e) {
          console.error(e);
          handleCopleteForm(LoadingStatuses.REJECTED);
        }
      }
      if (values.group2 === 'unlimit') {
        try {
          initUnlimit({
            ramp: 'BUY',
            cryptoCurrency: choosedBalance.symbol,
            fiatCurrency: selectAmount.value,
            amount: parseFloat(values.amount)
          });
        } catch (e) {
          console.error(e);
          handleCopleteForm(LoadingStatuses.REJECTED);
        }
      }
    }
  };

  React.useEffect(() => {
    if (balances.length) {
      const maticBalance = balances.find((x) => x.symbol === CoinType.MATIC) as any as TokenBalance;
      setBalance(maticBalance);
    }
  }, [balances]);

  return {
    balance,
    isLoading: false,
    choosedBalance,
    setBalance,
    selectAmount,
    setSelectAmount,
    isDisplayed,
    setIsDisplayed,
    handleSubmit,
    tranferLoading: false
  };
};
