import styled from 'styled-components';
import { FONTS } from '../../../theme';
import { TransactionStatusTypes } from '../../../common/types/transaction.type';
import { ThemeColors } from './theme';
import { TTheme } from '../../../common/types';

export const Operation = styled.h3<{ status: TransactionStatusTypes }>`
  font-size: 18px;
  font-weight: ${FONTS.WEIGHTS.medium};
  font-family: ${FONTS.FAMILIES.inter};
  text-align: end;
  color: ${({ status, theme: { type } }) => ThemeColors.operation[type as TTheme][status]};
`;

export const AmountText = styled.h4`
  font-size: 14px;
  font-weight: ${FONTS.WEIGHTS.normal};
  font-family: ${FONTS.FAMILIES.inter};
  text-align: end;
  color: ${({ theme: { type } }) => ThemeColors.amount[type as TTheme].color};
`;
