import React, { useEffect, useState } from 'react';

type Props<T> = {
  each: T[];
  filter?: (arg: T) => boolean;
  children: (item: T) => React.ReactNode;
};

export function For<T>({ each, filter = () => true, children }: Props<T>) {
  const [filteredEach, setFilteredEach] = useState<T[]>([]);

  useEffect(() => {
    const filtered = each.filter(filter);
    if (
      filtered.length !== filteredEach.length ||
      !filteredEach.every((item, index) => item === filtered[index])
    ) {
      setFilteredEach(filtered);
    }
  }, [each, filter, filteredEach]);

  return <>{filteredEach.map((item) => children(item))}</>;
}
