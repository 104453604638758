// Local storage keys
export const STORAGE_KEYS = {
  JWT_TOKEN_STUDENT: 'JWT_TOKEN_STUDENT',
  JWT_TOKEN_INSTRUCTOR: 'JWT_TOKEN_INSTRUCTOR',
  ADDRESS: 'ADDRESS',
  LAST_OPENED_BALANCE: 'LAST_OPENED_BALANCE',
  LAST_OPENED_COIN_BALANCE: 'LAST_OPENED_COIN_BALANCE',
  TOKEN: 'TOKEN',
  WALLET_ADAPTER: 'Web3Auth-cachedAdapter',
  DEFAULT_CURRENCY: 'DEFAULT_CURRENCY',
  THEME: 'THEME'
};

// React-query keys
export const QUERY_KEYS = {
  EXAMPLE: 'EXAMPLE',
  BALANCE: 'BALANCE',
  RATE: 'RATE',
  TOKEN: 'TOKEN',
  STATISTIC: 'statistic',
  TRENDING: 'trending',
  RESERVATION: 'reservation',
  PAYMENT: 'payment',
  HISTORY: 'HISTORY'
};

// Backend Routes
export const BACKEND_KEYS = {
  EXAMPLE: 'example',
  COURSES: 'courses',
  ARTICLES: 'articles',
  STATISTIC: 'statistic',
  TRENDING: 'trending',
  FEATURED_ARTICLES: 'featured_articles',
  PAYMENTS: 'payments'
};

export const ROUTER_KEYS = {
  ROOT: '/',
  HOME: '/home',
  AUTH: '/auth',
  SETTINGS: '/settings',
  TOP_CRYPTOS: '/top-cryptos',
  TERMS_AND_CODITIONS: '/terms-and-conditions',
  PRIVACY_POLICY: '/privacy-policy',
  SUPPORT: '/support',
  HISTORY: '/history',
  AUTHORIZED: 'authorized',
  AUTH_CALLBACK: 'auth-callback'
};
