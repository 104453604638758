import { COLORS } from '../../../theme/colors.const';

export const ThemeColors = {
  text: {
    light: {
      color: COLORS.darkBluePrimary
    },
    dark: {
      color: COLORS.orange70
    }
  }
};
