import { Grid } from '@mui/material';
import { FC } from 'react';
import { StyledText } from '../../../common/components';
import { ContainedButton, StyledButton } from '../../../common/components/button';
import Modal from '../../../common/components/modal';

type WarningModalProps = {
  isOpen: boolean;
  onClose: () => void;
  message: string;
};

export const WarningModal: FC<WarningModalProps> = ({ isOpen, onClose, message }) => {
  const handleClose = async () => {
    onClose();
  };

  return (
    <Modal
      header={
        <Grid container justifyContent="center" alignItems="center">
          <StyledText textAlign="center" fontSize="22px" lineHeight="27px" fontWeight="500">
            Something went wrong
          </StyledText>
        </Grid>
      }
      open={isOpen}
      closeButton={false}
      onClose={handleClose}
      content={
        <Grid
          container
          paddingTop={3}
          direction="column"
          rowGap={2}
          justifyContent="center"
          alignItems="center"
        >
          <StyledText>{message}</StyledText>
          <StyledButton margin="0" onClick={handleClose}>
            Ok
          </StyledButton>
        </Grid>
      }
    />
  );
};
