import { COLORS } from '../../../theme';

export const ThemeColors = {
  button: {
    light: {
      background: COLORS.white,
      border: COLORS.blonde
    },
    dark: {
      background: COLORS.primary,
      border: COLORS.bronzeYellow
    }
  },
  text: {
    light: {
      color: COLORS.darkBlue
    },
    dark: {
      color: COLORS.white
    }
  },
  icon: {
    light: {
      color: COLORS.white,
      sendStroke: COLORS.primaryOrange,
      topUpStroke: COLORS.primaryBlue,
      cashOutStroke: COLORS.primaryGreen
    },
    dark: {
      color: COLORS.primary,
      sendStroke: COLORS.mustard,
      topUpStroke: COLORS.blue40,
      cashOutStroke: COLORS.successGreen50
    }
  }
};
