import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { Box, ContainedButton } from '../common/components';

import { ReactComponent as Arrow } from '../../assets/icons/arrowLeft.svg';
import { StyledBox, StyledTitle } from './index.styled';
import { Footer } from '../home/components';
import { For } from '../common/components/for/for.component';
import { Section } from './components/Section';
import { ROUTER_KEYS } from '../common/consts/app-keys.const';
import { TermsAndConditionsContent } from './content/terms-and-conditions.content';
import { useAuth } from '../web3auth/web3auth.provider';

export const TermsAndConditions = () => {
  const { address } = useAuth();
  const [auth, setAuth] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (address) {
      setAuth(true);
    } else {
      setAuth(false);
    }
  }, [address]);

  const clickHandler = () => {
    const route = auth ? ROUTER_KEYS.HOME : ROUTER_KEYS.AUTH;
    navigate(route);
  };

  const BackBtnContent = useMemo(() => (auth ? 'Back to Home' : 'Back to Auth'), [auth]);
  return (
    <Box changeDirection width="100%" zIndex={1} justify="space-between">
      <Box justify="space-between" changeDirection height="100%">
        <StyledBox changeDirection gap="30px">
          <Box justify="start" align="center">
            <ContainedButton onClick={clickHandler} iconMargin="0px 10px 0px 0px" type="button">
              <Arrow />
              {BackBtnContent}
            </ContainedButton>
            <Box justify="center" width="80%">
              <StyledTitle>Terms And Conditions</StyledTitle>
            </Box>
          </Box>
          <For each={TermsAndConditionsContent}>
            {({ id, ...props }) => <Section key={`pp-${id}`} {...props} />}
          </For>
        </StyledBox>
      </Box>
      <Footer />
    </Box>
  );
};

export default TermsAndConditions;
