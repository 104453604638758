import { Grid } from '@mui/material';
import { useFormikContext } from 'formik';

import { useEffect } from 'react';
import { Box, Input, SelectModalButton, StyledText } from '../../../../../common/components';
import { rateService } from '../../../../../common/services';
import { TokenBalance } from '../../../../../common/types/balance.type';
import { priceFormat } from '../../../../../common/utils';
import { WEIGHTS } from '../../../../../theme/fonts.const';
import { IValues } from '../../types';

interface IProps {
  handleOpen: () => void;
  choosedBalance: TokenBalance;
  currency: string;
}

export const AmountInput = ({ handleOpen, choosedBalance, currency }: IProps) => {
  const { setFieldValue, values } = useFormikContext<IValues>();

  const extraChange = (amount: string) => async () => {
    const data = await rateService.convert(choosedBalance.symbol, currency, +amount);
    setFieldValue('amount', typeof data === 'number' ? priceFormat(data.toString(), 3) : '0');
  };

  useEffect(() => {
    if (values.coin !== '') {
      rateService.convert(choosedBalance.symbol, currency, +values.coin).then((data) => {
        setFieldValue('amount', typeof data === 'number' ? priceFormat(data.toString(), 3) : '0');
      });
    }
  }, [choosedBalance.symbol]);

  return (
    <>
      <StyledText fontWeight={WEIGHTS.normal}>Amount</StyledText>
      <Box margin="7.5px 0 12px 0" justify="space-between">
        <Grid container columnGap={1}>
          <SelectModalButton onClick={handleOpen} selectedCoin={choosedBalance} />
          <Grid xs>
            <Input
              height="58px"
              name="coin"
              placeholder="Enter Amount"
              pattern="\d*(\.\d{0,3})?"
              label={choosedBalance.symbol}
              paddingRight="78px"
              extraChange={extraChange}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
