import { ReactNode } from 'react';
import { Box, StyledText, Text } from '../../../common/components';
import { Spinner } from '../../../common/components/loader/styles.styled';
import { SelectModalButton } from '../../../common/components/select-modal/modal-button';
import { TokenBalance } from '../../../common/types/balance.type';
import { COLORS, FONTS } from '../../../theme';
import { Container } from './index.styled';

interface IProps {
  title: string;
  disabled?: boolean;
  children?: ReactNode;
  showArrow?: boolean;
  handleClickSelect: () => void;
  selectValue: TokenBalance;
  isBattery?: boolean;
}

export const CardContainer = ({
  title,
  children,
  showArrow = true,
  selectValue,
  handleClickSelect,
  disabled,
  isBattery
}: IProps) => (
  <Container>
    <StyledText textAlign="center" fontWeight={FONTS.WEIGHTS.medium} margin="0 0 0.875rem">
      {title}
    </StyledText>

    {disabled ? (
      <Box justify="center">
        <Spinner />
      </Box>
    ) : (
      <Box justify="space-between">
        <SelectModalButton
          showArrow={showArrow}
          disabled={disabled}
          isBattery={isBattery}
          onClick={handleClickSelect}
          selectedCoin={selectValue}
        />
        <Box changeDirection>
          {children}
          <Text color={COLORS.coolGrey} fontSize={FONTS.SIZES.m}>
            Balance
          </Text>
        </Box>
      </Box>
    )}
  </Container>
);
