import { ROUTER_KEYS } from '../../common/consts/app-keys.const';

export const DESCRIPTION_LINKS = [
  {
    text: 'Privacy policy',
    src: '#',
    isBorder: true,
    showOnlyRoute: { isShow: false },
    route: ROUTER_KEYS.PRIVACY_POLICY
  },
  {
    text: 'Terms and Conditions ',
    src: '#',
    isBorder: true,
    showOnlyRoute: { isShow: false },
    route: ROUTER_KEYS.TERMS_AND_CODITIONS
  },
  {
    text: 'Need help? Contact ',
    secondText: 'Watt2Trade Support',
    showOnlyRoute: { route: ROUTER_KEYS.SUPPORT, isShow: true },
    src: 'mailto:contact@watt2trade.com',
    isBorder: false,
    route: null
  }
];
