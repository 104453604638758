import { Grid } from '@mui/material';
import React, { FC } from 'react';
import { Box, StyledText } from '..';
import { useCurrency } from '../../providers/currency.provider';
import { priceFormat } from '../../utils';
import * as Styled from './index.styled';
import { useTheme } from '../../providers/theme.provider';
import { ReactComponent as Watt } from '../../../../assets/image/watt.svg';
import { COLORS } from '../../../theme';

type CoinWrapperType = {
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  name: string;
  symbol: string;
  isDexBalance?: boolean;
  balance?: number;
  toRateBalance?: number;
  active?: boolean;
  onClick: () => void;
  isBalance?: boolean;
};

export const CoinWrapper: FC<CoinWrapperType> = ({
  Icon,
  name,
  symbol,
  isDexBalance,
  balance,
  toRateBalance,
  active,
  onClick,
  isBalance = true
}) => {
  const { symbol: currencySymbol } = useCurrency();
  const { currentTheme } = useTheme();
  return (
    <Styled.CoinWrapper onClick={onClick} active={active} container justifyContent="space-between">
      <Grid item xs={8} container alignItems="center" columnGap={1}>
        <Icon />
        <Grid item xs={6} container direction="column" rowGap={1}>
          <Styled.CoinName fontWeight="500" fontSize="16px">
            {name}
          </Styled.CoinName>
          <Styled.SmallText fontWeight="400" fontSize="14px">
            {symbol}
          </Styled.SmallText>
        </Grid>
      </Grid>
      {isBalance && (
        <Grid
          item
          xs={3}
          container
          alignItems="end"
          justifyContent="center"
          direction="column"
          rowGap={1}
        >
          <Box align="center" gap="5px">
            <StyledText fontWeight="500" fontSize="18px">
              {priceFormat(Number(balance).toFixed(2))}
            </StyledText>
            {isDexBalance ? (
              <Watt
                width="16px"
                height="16px"
                fill={currentTheme === 'light' ? COLORS.primary : COLORS.white}
              />
            ) : (
              <StyledText fontWeight="500" fontSize="18px">
                {symbol}
              </StyledText>
            )}
          </Box>

          {toRateBalance !== undefined && (
            <Styled.SmallText fontWeight="400" fontSize="14px">
              {`${currencySymbol}${priceFormat(Number(toRateBalance).toFixed(2))}`}
            </Styled.SmallText>
          )}
        </Grid>
      )}
    </Styled.CoinWrapper>
  );
};
