import { Grid } from '@mui/material';
import styled, { css } from 'styled-components';
import { StyledText, Text } from '..';
import { TTheme } from '../../types';
import { ThemeColors } from './theme';

export const CoinWrapper = styled(Grid)<{ active?: boolean }>`
  width: 100%;
  ${(props) =>
    props.active &&
    css`
      background-color: ${({ theme: { type } }) =>
        ThemeColors.CoinWrapper[type as TTheme].hoverBackground};
    `}

  &:hover {
    background-color: ${({ theme: { type } }) =>
      ThemeColors.CoinWrapper[type as TTheme].hoverBackground};
  }

  transition: background-color 0.5s ease;
  border-radius: 12px;
  padding: 12px;
  cursor: pointer;
`;

export const SelectButtonWrapper = styled('button')<{
  isBattery?: boolean;
  isSmaller?: boolean;
  height?: string;
}>`
  cursor: pointer;
  width: 100%;
  background-color: ${({ theme: { type } }) =>
    ThemeColors.SelectButtonWrapper[type as TTheme].background};

  &:hover {
    background-color: ${({ theme: { type } }) =>
      ThemeColors.SelectButtonWrapper[type as TTheme].hoverBackground};
  }

  &:disabled {
    filter: opacity(0.6);
    cursor: default;
    &:hover {
      background-color: ${({ theme: { type } }) =>
        ThemeColors.SelectButtonWrapper[type as TTheme].background};
    }
  }

  svg {
    fill: ${({ theme: { type } }) => ThemeColors.SelectButtonWrapper[type as TTheme].svg};
    width: ${({ isSmaller }) => (isSmaller ? '24px' : '40px')};
    height: ${({ isSmaller }) => (isSmaller ? '24px' : '40px')};
  }

  padding: 8px;
  border-radius: 12px;
  border: 1px solid
    ${({ theme: { type } }) => ThemeColors.SelectButtonWrapper[type as TTheme].borderBattery};

  ${({ isBattery, theme }) =>
    isBattery &&
    `
      border: none;
      &:after {
    content: '';
    display: block;
    position: absolute;
    width: 5px;
    height: 23px;
    right: -8px;
    bottom: 16px;
    background: linear-gradient(135deg, #ffd63f 0%, #ffb11a 52.89%, #ffd63f 100%);
    border-radius: 0px 5px 5px 0px;
  }
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 12px;
    padding: 1px;
    width: 100%;
    background: linear-gradient(135deg, #ffd63f 0%, #ffb11a 52.89%, #ffd63f 100%);
    -webkit-mask: linear-gradient(${theme.colors.white} 0 0) content-box,
      linear-gradient(${theme.colors.white} 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
  }
    `}
  position: relative;
  height: ${({ height }) => height ?? 'auto'};
`;

export const SmallText = styled(Text)`
  color: ${({ theme: { type } }) => ThemeColors.coin[type as TTheme].color};
`;

export const ArrowContainer = styled(Grid)`
  svg {
    width: 15px;
    height: 10.76px;
  }
`;

export const CoinName = styled(StyledText)`
  white-space: nowrap;
`;
