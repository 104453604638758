import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { transactionService } from '../../services/transaction.service';
import { useAuth } from '../../web3auth/web3auth.provider';
import { useCurrency } from '../providers/currency.provider';

const MAX_RETRY_COUNT = 10;

export const useTopCryptos = (page: number, isHome: boolean = true) => {
  const [, setSearchParams] = useSearchParams();
  const { balance, authState } = useAuth();

  const { currency } = useCurrency();

  const { data, isLoading } = useQuery(
    ['TOP_CRYPTOS', isHome, page, currency],
    async () =>
      transactionService.getTopCryptos(
        '',
        (page - 1) * (page === 1 ? 1 : 10),
        Number(balance),
        currency
      ),
    {
      retry: (failureCount, error: any) => {
        console.error(error);
        return failureCount < MAX_RETRY_COUNT;
      },
      enabled: balance !== undefined && authState === 'LOGGED'
    }
  );

  useEffect(() => {
    if (!isHome) {
      setSearchParams({ page: page.toString() });
    }
  }, [page]);

  return { data, isLoading };
};
