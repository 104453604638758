import { Grid } from '@mui/material';
import { FC, useMemo } from 'react';
import { StyledText } from '..';
import { COLORS } from '../../../theme';
import { DexCoin, coinIconMatcher } from '../../consts/icons.const';
import { IToken, TokenBalance } from '../../types/balance.type';
import { getBalance } from '../../utils/get-balance.util';

import { ReactComponent as ArrowDown } from '../../../../assets/icons/arrow-down.svg';

import { CoinType } from '../../types/transaction.type';
import * as Styled from './index.styled';

type SelectModalButtonProps = {
  selectedCoin?: TokenBalance | IToken | null;
  disabled?: boolean;
  isBattery?: boolean;
  showBalance?: boolean;
  onClick: () => void;
  placeholder?: string;
  showArrow?: boolean;
  isSmaller?: boolean;
  height?: string;
  width?: number;
  defaultIcon?: string;
};

export const SelectModalButton: FC<SelectModalButtonProps> = ({
  selectedCoin,
  onClick,
  disabled,
  showBalance,
  isBattery,
  placeholder,
  showArrow = true,
  isSmaller,
  width = 5,
  height,
  defaultIcon = 'USDC'
}) => {
  const Icon = useMemo(() => {
    if (selectedCoin) {
      if (coinIconMatcher[selectedCoin?.symbol]) {
        return coinIconMatcher[selectedCoin?.symbol];
      }

      return DexCoin;
    }
    if (defaultIcon === 'coin') {
      return DexCoin;
    }
    return coinIconMatcher[CoinType.USDC];
  }, [selectedCoin, defaultIcon]);

  const iconStyle = useMemo(() => {
    if (isBattery) {
      return { fill: 'none', width: '41px', height: '41px' };
    }
    return {};
  }, [isBattery]);

  return (
    <Grid container item xs={width}>
      <Styled.SelectButtonWrapper
        isBattery={isBattery}
        isSmaller={isSmaller}
        type="button"
        height={height}
        disabled={disabled}
        onClick={onClick}
      >
        <Grid container justifyContent="space-between" flexWrap="nowrap">
          <Grid xs={10} item container alignItems="center" columnGap={1} flexWrap="nowrap">
            <Icon style={iconStyle} />
            <StyledText
              style={{
                minWidth: 0,
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden'
              }}
              fontWeight="500"
              fontSize="16px"
            >
              {selectedCoin?.name ?? placeholder}
            </StyledText>
          </Grid>

          {showArrow && (
            <Styled.ArrowContainer paddingRight="5px" item alignSelf="center">
              <ArrowDown />
            </Styled.ArrowContainer>
          )}
        </Grid>
      </Styled.SelectButtonWrapper>

      {showBalance && (
        <StyledText margin="10px 0 0 0" color={COLORS.coolGrey} fontSize="12px" fontWeight="400">
          {getBalance(selectedCoin)}
        </StyledText>
      )}
    </Grid>
  );
};