import styled from 'styled-components';
import { Text } from '..';
import { ThemeColors } from './theme';
import { TTheme } from '../../types';

export const StyledText = styled(Text)`
  color: ${({ theme: { type } }) => ThemeColors.text[type as TTheme].color};

  @media screen and (max-width: 1160px) {
    display: none;
  }
`;

export const Container = styled.footer`
  display: flex;
  justify-content: space-between;
  margin: 42px 150px;

  @media screen and (max-width: 1000px) {
    margin: 42px 40px;
  }
`;
