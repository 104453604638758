import { ReactComponent as Coin } from '../../../../assets/image/coins/coin.svg';
import { ReactComponent as SendCrypto } from '../../../../assets/image/sendCrypto.svg';
import { TransactionStatusTypes } from '../../types/transaction.type';

export const TITLES = {
  [TransactionStatusTypes.CANCELED]: {
    title: 'Canceled'
  },
  [TransactionStatusTypes.SUBMITED]: {
    title: 'Submitted'
  },
  [TransactionStatusTypes.CONFIRMED]: {
    title: 'Confirmed'
  }
};

export const ERC_20_IMAGES = [SendCrypto, Coin];
export { Coin };
