import styled from 'styled-components';
import { ThemeColors } from '../../consts';
import { Box } from '../index.styled';
import { TTheme } from '../../types';

export const Div = styled(Box)`
  svg {
    fill: ${({ theme: { type } }) => ThemeColors.logo[type as TTheme].color};

    path:nth-child(6),
    path:nth-child(7),
    path:nth-child(8),
    path:nth-child(9),
    path:nth-child(10) {
      fill: ${({ theme: { type } }) => ThemeColors.text[type as TTheme].color};
    }
  }
`;
