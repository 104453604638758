/* eslint-disable import/no-cycle */
import { ReactComponent as Bitcoin } from '../../../assets/image/coins/btc.svg';
import { ReactComponent as Ethereum } from '../../../assets/image/coins/eth.svg';
import { ReactComponent as Tether } from '../../../assets/image/coins/tether.svg';
import { ReactComponent as USDCIcon } from '../../../assets/image/coins/usdc.svg';
import { ReactComponent as Polygon } from '../../../assets/image/coins/polygon.svg';
import { Text } from '../../common/components';
import { CoinType } from '../../common/types/transaction.type';

import { ItemWrapper } from '../components/card/index.styled';

export const COINS = [
  {
    value: 'Matic',
    label: (
      <ItemWrapper align="center">
        <Polygon height="40px" width="40px" />
        <Text margin="0 0 0 0.375rem">Matic</Text>
      </ItemWrapper>
    ),
    key: CoinType.MATIC
  },
  {
    value: 'Bitcoin',
    label: (
      <ItemWrapper align="center">
        <Bitcoin height="40px" width="40px" />
        <Text margin="0 0 0 0.375rem">Bitcoin</Text>
      </ItemWrapper>
    ),
    key: CoinType.WBTC
  },
  {
    value: 'Ethereum',
    label: (
      <ItemWrapper align="center">
        <Ethereum height="40px" width="40px" />
        <Text margin="0 0 0 0.375rem">Ethereum</Text>
      </ItemWrapper>
    ),
    key: CoinType.ETH
  },
  {
    value: 'DAI',
    label: (
      <ItemWrapper align="center">
        <Tether height="40px" width="40px" />
        <Text margin="0 0 0 0.375rem">Dai</Text>
      </ItemWrapper>
    ),
    key: CoinType.DAI
  },
  {
    value: 'USD Coin',
    label: (
      <ItemWrapper align="center">
        <USDCIcon height="40px" width="40px" />
        <Text margin="0 0 0 0.375rem">USD Coin</Text>
      </ItemWrapper>
    ),
    key: CoinType.USDC
  }
];
