import { Route, BrowserRouter, Routes, Navigate } from 'react-router-dom';
import { useEffect } from 'react';
import { AuthContainer } from '../auth';
import { MainContainer } from '../common/components';
import { Loader } from '../common/components/loader';
import { APP_KEYS } from '../common/consts';
import { useAuth } from '../web3auth/web3auth.provider';
import { Container } from '../common/components/container';
import { PRIVATE_ROUTES } from './constants';
import { ReactComponent as TopShadow } from '../../assets/image/topShadow.svg';
import { ReactComponent as LeftShadow } from '../../assets/image/leftShadow.svg';
import { ReactComponent as RightShadow } from '../../assets/image/rightShadow.svg';
import { TermsAndConditions } from '../app-info/TermsAndConditions';
import { PrivacyPolicy } from '../app-info/PrivacyPolicy';
import { useVerify } from '../common/hooks/use-verify.hooks';
import { AuthCallback } from '../auth/auth-callback';

export const MainRouter = () => {
  const { authState } = useAuth();
  const { verify } = useVerify();

  useEffect(() => {
    if (window.location.pathname !== '/auth-callback') {
      verify();
    }
  }, []);
  return (
    <MainContainer>
      <TopShadow className="top" />
      <LeftShadow className="left" />
      <RightShadow className="right" />
      <Loader isLoading={authState === 'LOADING'} />
      <BrowserRouter>
        <Routes>
          <Route element={<TermsAndConditions />} path={APP_KEYS.ROUTER_KEYS.TERMS_AND_CODITIONS} />
          <Route element={<PrivacyPolicy />} path={APP_KEYS.ROUTER_KEYS.PRIVACY_POLICY} />
          <Route element={<AuthContainer />} path={APP_KEYS.ROUTER_KEYS.AUTH} />
          <Route element={<AuthCallback />} path={APP_KEYS.ROUTER_KEYS.AUTH_CALLBACK} />
          <Route path={`${APP_KEYS.ROUTER_KEYS.ROOT}`} element={<Container />}>
            {PRIVATE_ROUTES.map(({ path, element }) => (
              <Route key={path} path={path} element={element} />
            ))}
          </Route>
          <Route path="*" element={<Navigate to={APP_KEYS.ROUTER_KEYS.ROOT} />} />
        </Routes>
      </BrowserRouter>
    </MainContainer>
  );
};
