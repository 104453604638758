import { COLORS } from '../../../theme';

export const ThemeColors = {
  filter: {
    light: {
      background: COLORS.ghostWhite,
      border: COLORS.azureishWhite,
      text: COLORS.primary,
      lineBorder: COLORS.azureishWhite
    },
    dark: {
      background: COLORS.darkBlue100,
      border: COLORS.bronzeYellow,
      text: COLORS.white,
      lineBorder: COLORS.independence
    }
  }
};
