import { Field } from 'formik';
import { ChangeEvent } from 'react';
import { StyledLabelBorderless } from './index.styled';

interface IProps {
  id: string;
  name: string;
  label: string;
  value: string;
  handleChange: (e: string | ChangeEvent<any>) => void;
  checked?: boolean;
}
export const RadioButtonBorderless = ({
  handleChange,
  id,
  name,
  label,
  value,
  checked
}: IProps) => (
  <StyledLabelBorderless htmlFor={id}>
    <Field
      type="radio"
      id={id}
      name={name}
      checked={checked}
      value={value}
      onChange={handleChange}
    />
    <label htmlFor={id}>{label}</label>
  </StyledLabelBorderless>
);