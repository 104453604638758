import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { Text } from '../index.styled';
import { COLORS } from '../../theme';
import { useTheme } from '../../common/providers/theme.provider';

type TableRowType = string[];
type TableProps = {
  headers: string[];
  rows: TableRowType[];
};
export const TableComponent: FC<TableProps> = ({ headers, rows }) => {
  const { currentTheme } = useTheme();
  const tableColor = useMemo(() => {
    if (currentTheme === 'dark') {
      return COLORS.white;
    }
    return COLORS.black;
  }, [currentTheme]);
  return (
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead sx={{ border: `1px solid ${tableColor}` }}>
        <TableRow>
          {headers.map((x) => (
            <TableCell sx={{ border: `1px solid ${tableColor}` }} key={x}>
              <Text>{x}</Text>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody sx={{ border: `1px solid ${tableColor}` }}>
        {rows.map((rowItems, idx) => (
          <TableRow key={`${idx}-row`}>
            {rowItems.map((x) => (
              <TableCell key={x} sx={{ border: `1px solid ${tableColor}` }}>
                <Text>{x}</Text>
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
