import { IToken } from '../../../common/types/balance.type';
import { CoinType } from '../../../common/types/transaction.type';

export const COINS: IToken[] = [
  { name: 'USD Coin(Bridged)', symbol: CoinType.USDC_E },
  { name: 'USD Coin', symbol: CoinType.USDC },
  { name: 'DAI', symbol: CoinType.DAI },
  { name: 'Ether', symbol: CoinType.ETH },
  { name: 'Wrapped Bitcoin', symbol: CoinType.WBTC },
  { name: 'Polygon', symbol: CoinType.MATIC }
];
