import styled from 'styled-components';

import { Text } from '../../../../../common/components';
import { ThemeColors } from '../../../../../common/consts';
import { TTheme } from '../../../../../common/types';
import { FAMILIES, WEIGHTS } from '../../../../../theme/fonts.const';

export const Input = styled('input')<{
  isError: boolean;
  paddingRight?: string;
  isCalculated: boolean;
}>`
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -webkit-outer-spin-button: none;

  -moz-appearance: textfield;

  outline: none;
  width: 95%;
  border: none;
  background: ${({ theme: { type } }) => ThemeColors.input[type as TTheme].background};

  color: ${({ theme: { type } }) => ThemeColors.input[type as TTheme].color};

  &::placeholder {
    font-family: ${FAMILIES.inter};
    font-weight: ${WEIGHTS.normal};
    font-size: 16px;
    line-height: 19px;
    color: ${({ theme: { type } }) => ThemeColors.input[type as TTheme].placeholder};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:active,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: ${({ theme: { type } }) =>
      ThemeColors.input[type as TTheme].color} !important;
    -webkit-box-shadow: 0 0 0 1000px
      ${({ theme: { type } }) => ThemeColors.input[type as TTheme].background} inset !important;
  }
`;

export const Label = styled.label<{ isError: boolean }>`
  position: absolute;
  font-weight: ${WEIGHTS.medium};
  font-size: 18px;
  text-align: center;
  color: ${({ theme: { type } }) => ThemeColors.input[type as TTheme].label};
  right: 12px;
  top: ${({ isError }) => (isError ? '15px' : '15px')};
`;

export const Value = styled.span<{ isValue: boolean }>`
  font-family: ${FAMILIES.inter};
  font-weight: ${WEIGHTS.normal};
  font-size: 14px;
  color: ${({ theme }) => theme.colors.coolGrey};
  display: ${({ isValue }) => (isValue ? 'block' : 'none')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 16px - 3px);
  padding-left: 3px;
`;

export const Div = styled.div<{ width?: string }>`
  position: relative;
  width: ${({ width }) => width || '100%'};
`;

export const CustomError = styled(Text)<{ bottomHeight?: string }>`
  font-size: 12px;
  margin-top: 8px;
  color: ${({ theme }) => theme.colors.red};
  position: absolute;
  right: 0;
  bottom: ${({ bottomHeight }) => bottomHeight || '-23px'};
`;

export const StyledDiv = styled.div<{ isError: boolean }>`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 14px 16px;
  gap: 10px;
  height: 55px;
  border: ${({ isError, theme: { type, colors } }) =>
    !isError
      ? `1px solid ${ThemeColors.input[type as TTheme].border}}`
      : `1px solid ${colors.red}`};

  background: ${({ theme: { type } }) => ThemeColors.input[type as TTheme].background};
  border-radius: 8px;
`;
