import { ReactComponent as DiscordSvg } from '../../assets/icons/discord.svg';
import { ReactComponent as FacebookSvg } from '../../assets/icons/facebook.svg';
import { ReactComponent as GoogleSvg } from '../../assets/icons/google.svg';
import { ReactComponent as TwitterSvg } from '../../assets/icons/twitter.svg';
import { LOGIN_PROVIDER } from '../common/consts/login-providers.const';
import { match } from '../common/utils/match.util';

export const loginProviders = [
  {
    type: LOGIN_PROVIDER.FACEBOOK,
    Icon: GoogleSvg
  },
  {
    type: LOGIN_PROVIDER.GOOGLE,
    Icon: TwitterSvg
  },
  {
    type: LOGIN_PROVIDER.TWITTER,
    Icon: FacebookSvg
  },
  {
    type: LOGIN_PROVIDER.DISCORD,
    Icon: DiscordSvg
  }
];

export enum SocialLogin {
  FACEBOOK = 'FACEBOOK',
  GOOGLE = 'GOOGLE',
  TWITTER = 'TWITTER',
  DISCORD = 'DISCORD'
}

export const loginProviderMatcher = match({
  [SocialLogin.FACEBOOK]: { type: LOGIN_PROVIDER.FACEBOOK, Icon: FacebookSvg },
  [SocialLogin.GOOGLE]: { type: LOGIN_PROVIDER.GOOGLE, Icon: GoogleSvg },
  [SocialLogin.TWITTER]: { type: LOGIN_PROVIDER.TWITTER, Icon: TwitterSvg },
  [SocialLogin.DISCORD]: { type: LOGIN_PROVIDER.DISCORD, Icon: DiscordSvg }
});
