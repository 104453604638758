import { ChangeEvent } from 'react';
import { Box, StyledText } from '../../../common/components';
import { RadioButtonBorderless } from '../../../common/components/radio-button/borderless';
import { SIZES, WEIGHTS } from '../../../theme/fonts.const';

interface IProps {
  handleChange: (e: string | ChangeEvent<any>) => void;
  active: string;
}

export const RadioGroup = ({ handleChange, active }: IProps) => (
  <Box changeDirection>
    <StyledText fontSize={SIZES.l} fontWeight={WEIGHTS.normal}>
      Select Transaction Type
    </StyledText>

    <Box role="group" width="100%">
      <RadioButtonBorderless
        id="1"
        name="group2"
        value="all"
        label="All"
        checked={active === 'all'}
        handleChange={handleChange}
      />
      <RadioButtonBorderless
        id="2"
        name="group2"
        value="crypto"
        label="Crypto"
        checked={active === 'crypto'}
        handleChange={handleChange}
      />
      <RadioButtonBorderless
        handleChange={handleChange}
        id="3"
        name="group2"
        value="coin"
        checked={active === 'coin'}
        label="Coin"
      />
    </Box>
  </Box>
);
