import { useState } from 'react';
import { Box, StyledText } from '..';
import { ReactComponent as Arrow } from '../../../../assets/image/arrowRight.svg';
import { Amount } from '../../../home/components/amount';
import { OrderId } from '../../../home/components/orderId';
import { COLORS, FONTS } from '../../../theme';
import { WEIGHTS } from '../../../theme/fonts.const';
import { ITransaction, TransactionType } from '../../types/transaction.type';
import { toggleState } from '../../utils';
import Modal from '../modal';
import { Data } from './components/data';
import { DetailsModal } from './components/details-modal';
import { RenderImages } from './components/render-images';
import { TITLES } from './constants';
import { Action, Container, StyledTitle, ViewDetails } from './index.styled';
import { getValue } from './utils';

interface IProps extends ITransaction {
  border: string;
}

export const Transaction = ({ border, titles, ...el }: IProps) => {
  const operation = `${el.type === 'outcome' ? '-' : '+'} ${getValue(el)} `;
  const [open, setOpen] = useState(false);

  return (
    <Container border={border}>
      <Box className="static">
        <Data data={el.data} />
        <RenderImages first={titles.action} second={titles.currency} txType={el.txType} />
        <div>
          <StyledText
            color={COLORS.darkGunmetal}
            fontSize={FONTS.SIZES.l}
            fontWeight={FONTS.WEIGHTS.medium}
            fontFamily={FONTS.FAMILIES.inter}
          >
            {titles.title}
          </StyledText>
          <StyledTitle status={el.status}>{TITLES[el.status]?.title}</StyledTitle>
        </div>
      </Box>
      <Action className="action" isDetails>
        {el.txType !== TransactionType.DEX_INTERACTION ? (
          <Amount
            operation={operation}
            total={el.total}
            showTotal={el.txType !== TransactionType.DEX_ERC0_TRANSFER}
            status={el.status}
          />
        ) : (
          <OrderId orderId={el.dexEvent.orderId ?? ''} status={el.status} />
        )}
        <ViewDetails onClick={toggleState(setOpen)} type="button" className="view" isDetails>
          View details
          <Arrow />
        </ViewDetails>
      </Action>

      <Modal
        open={open}
        onClose={toggleState(setOpen)}
        header={
          <StyledText fontWeight={WEIGHTS.medium} fontSize="22px">
            {titles.title}
          </StyledText>
        }
        content={<DetailsModal isOpen={open} transaction={{ ...el, titles }} />}
      />
    </Container>
  );
};
