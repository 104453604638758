import { ReactComponent as BigLogo } from '../../../../assets/image/bigLogo.svg';
import { ReactComponent as Logo } from '../../../../assets/image/logo.svg';
import { Div } from './index.styled';

interface IProps {
  margin?: string;
  isBig?: boolean;
}

export const LogoComponent = ({ margin, isBig }: IProps) => (
  <Div cursor="pointer" margin={margin}>
    {isBig ? <BigLogo /> : <Logo />}
  </Div>
);
