import React, { ChangeEvent, ReactNode } from 'react';
import { StyledLabel } from './index.styled';

interface IProps {
  id: string;
  name: string;
  label?: string;
  value: string;
  handleChange: (e: string | ChangeEvent<any>) => void;
  children?: ReactNode;
  checked?: boolean;
}
export const RadioButton = ({
  handleChange,
  id,
  name,
  label,
  value,
  children,
  checked
}: IProps) => (
  <StyledLabel htmlFor={id}>
    <input
      type="radio"
      id={id}
      name={name}
      value={value}
      defaultChecked={checked}
      onChange={handleChange}
    />
    {label && <label htmlFor={id}>{label}</label>}
    {children}
  </StyledLabel>
);
