import styled from 'styled-components';
import { Box } from '../common/components';
import { ThemeColors } from './theme';
import { TTheme } from '../common/types';

export const TableWrapper = styled.div`
  border: 1px solid ${({ theme: { type } }) => ThemeColors.table[type as TTheme].border};
  border-radius: 13px;
`;

export const StyledContainer = styled(Box)<{ border: string }>`
  width: 100%;
  justify-content: space-between;
  background-color: ${({ theme: { type } }) =>
    ThemeColors.StyledContainer[type as TTheme].background};

  padding: 12px;
  border-radius: ${({ border }) => border};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme: { type } }) =>
      ThemeColors.StyledContainer[type as TTheme].hoverBackground};
  }
`;

export const StyledBox = styled(Box)`
  margin: 0 9.375rem;

  @media screen and (max-width: 1000px) {
    margin: 0 2.25rem;
  }
`;
