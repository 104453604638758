import { Formik } from 'formik';

import { LoadingStatuses } from '../../../common/types/payment.type';
import { cashOutFormValidationSchema } from '../../../common/validation-schemas/cash-out-form.validation-schema';

import { initialValues } from './constants';
import { useCashout } from './hooks/use-chashout.hook';

import { TokenBalance } from '../../../common/types/balance.type';
import { Form } from './components/form';

interface IProps {
  handleCopleteForm: (statusCheck: LoadingStatuses | null) => void;
  balances: TokenBalance[];
  hideModal: (value: boolean) => void;
}

export const CashOutForm = ({ handleCopleteForm, balances, hideModal }: IProps) => {
  const { isDisplayed, handleSubmit } = useCashout(handleCopleteForm, hideModal);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={cashOutFormValidationSchema}
      onSubmit={handleSubmit}
    >
      <Form balances={balances} isDisplayed={isDisplayed} />
    </Formik>
  );
};
