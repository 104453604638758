import { CreateUserDTO, UserDTO } from '../types/user.type';
import { EnhancedWithAuthHttpService } from './http-auth.service';
import { HttpFactoryService } from './http-factory.service';

class AuthService {
  constructor(private readonly httpService: EnhancedWithAuthHttpService) {}

  async verifyToken() {
    return this.httpService.get<UserDTO>('auth/verify');
  }

  async login(payload: CreateUserDTO) {
    return this.httpService.post<UserDTO & { jwtToken: string }, CreateUserDTO>(
      'auth/login',
      payload
    );
  }

  async logout() {
    return this.httpService.get('auth/logout');
  }
}

const httpService = HttpFactoryService.createAuthHttpService();
export const authService = new AuthService(httpService);
