import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Box } from '../index.styled';

export const Avatar = styled.img`
  border-radius: 50%;
  margin-right: 10px;
`;

export const StyledBox = styled(Box)`
  margin: 25px 9.375rem 0.625rem 2rem;

  @media screen and (max-width: 1000px) {
    margin: 25px 2.25rem 0.625rem 2rem;
  }
`;

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;
