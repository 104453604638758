import { COLORS } from '../theme';

export const ThemeColors = {
  StyledContainer: {
    light: {
      background: COLORS.white,
      hoverBackground: COLORS.ghostWhite
    },
    dark: {
      background: COLORS.primary,
      hoverBackground: COLORS.darkBlue100
    }
  },
  text: {
    light: {
      color: COLORS.darkGunmetal
    },
    dark: {
      color: COLORS.white
    }
  },
  table: {
    light: {
      border: COLORS.blonde,
      background: COLORS.white
    },
    dark: {
      border: COLORS.bronzeYellow,
      background: COLORS.primary
    }
  },
  balance: {
    light: {
      svg: COLORS.primary,
      hover: COLORS.secondaryOrange
    },
    dark: {
      hover: COLORS.secondaryOrange,
      svg: COLORS.white
    }
  },
  transactionIcon: {
    light: {
      color: COLORS.white,
      sendStroke: COLORS.primaryOrange,
      topUpStroke: COLORS.primaryBlue,
      cashOutStroke: COLORS.primaryGreen
    },
    dark: {
      color: COLORS.primary,
      sendStroke: COLORS.mustard,
      topUpStroke: COLORS.blue40,
      cashOutStroke: COLORS.successGreen50
    }
  }
};
