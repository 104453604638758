import styled from 'styled-components';
import { ThemeColors } from './theme';
import { TTheme } from '../../../../../common/types';
import { Text } from '../../../../../common/components';
import { WEIGHTS } from '../../../../../theme/fonts.const';

export const Container = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  padding: 8px 10px;
  width: 100%;
  height: 72px;
  background: ${({ theme: { type } }) => ThemeColors.block[type as TTheme].background};
  border: 1px solid ${({ theme: { type } }) => ThemeColors.block[type as TTheme].borderColor};
  border-radius: 12px;
`;

export const SecondaryText = styled(Text)`
  color: ${({ theme: { type } }) => ThemeColors.block[type as TTheme].secondaryTextColor};
  font-weight: ${WEIGHTS.normal};
`;

export const StyledText = styled(Text)`
  color: ${({ theme: { type } }) => ThemeColors.block[type as TTheme].color};
  font-weight: ${WEIGHTS.medium};
`;
