import { TransactionType } from '../../../../../../../../types/transaction.type';
import { Avatar } from '../../../../../../../avatar';
import { AccountText } from '../../index.styled';
import { AccountSender } from '../account-sender';

interface IProps {
  isForYou: boolean;
  otherImage: string;
  otherAccount: string;
  currentIamge: string;
  txType: TransactionType;
}

export const FromTitle = ({ txType, isForYou, otherImage, otherAccount, currentIamge }: IProps) => {
  if (txType === TransactionType.CASH_OUT || txType === TransactionType.TOP_UP) {
    return (
      <AccountText>
        Transak {txType === TransactionType.TOP_UP ? 'Top-Up' : 'Cash-Out'} Service
      </AccountText>
    );
  }

  if (isForYou) {
    return <AccountSender senderImage={otherImage} account={otherAccount} />;
  }

  return (
    <AccountText>
      <Avatar width={34} src={currentIamge} /> Your Account
    </AccountText>
  );
};
