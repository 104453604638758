/* eslint-disable prettier/prettier */
/* eslint-disable no-useless-escape */
import styled from 'styled-components';
import { ReactComponent as WattSVG } from '../../../../assets/image/watt.svg';

const Watt = styled(WattSVG)<{ color: string }>`
  width: 16px;
  height: 16px;
  fill: ${(props) => props.color};
`;

export const INPUTS_FILTER = [
  {
    placeholder: 'from',
    width: '150px',
    height: '42px',
    type: 'text',
    lableTop: '9px'
  },
  {
    placeholder: 'to',
    width: '150px',
    height: '42px',
    lableTop: '9px'
  }
];

export const DEX_INPUTS_FILTER = (color: string) => [
  {
    placeholder: 'from',
    width: '150px',
    height: '42px',
    type: 'text',
    label: <Watt color={color} />,
    lableTop: '9px'
  },
  {
    placeholder: 'to',
    width: '150px',
    height: '42px',
    label: <Watt color={color} />,
    lableTop: '9px'
  }
];
