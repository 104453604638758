import { Grid } from '@mui/material';
import { DexCoin, coinIconMatcher } from '../../consts/icons.const';
import { TokenBalance } from '../../types/balance.type';
import { For } from '../for/for.component';
import Modal from '../modal';
import { CoinWrapper } from './coin';

import { Header } from '../../../home/components/crypto-block/index.styled';

interface IProps {
  setSelectValue: (option: TokenBalance) => void;
  selectValue?: TokenBalance;
  options: TokenBalance[];
  handleClose: () => void;
  isDexBalance?: boolean;
  open: boolean;
  isBalance?: boolean;
  title?: string;
}

export const SelectBalanceModal = ({
  selectValue,
  setSelectValue,
  options,
  handleClose,
  isDexBalance,
  open,
  isBalance = true,
  title
}: IProps) => {
  const handleChange = (newValue: TokenBalance) => () => {
    setSelectValue(newValue);
    handleClose();
  };

  return (
    <Modal
      open={open}
      header={<Header>{title ?? 'Crypto Balance'}</Header>}
      onClose={handleClose}
      maxWidth="492px"
      background="dark"
      content={
        <Grid paddingTop="34px" container>
          <For each={options}>
            {(tokenOpts) => (
              <CoinWrapper
                isDexBalance={isDexBalance}
                key={`${tokenOpts.symbol}-crypto-balance`}
                active={selectValue?.symbol === tokenOpts.symbol}
                onClick={handleChange(tokenOpts)}
                Icon={coinIconMatcher[tokenOpts.symbol] || DexCoin}
                isBalance={isBalance}
                {...tokenOpts}
              />
            )}
          </For>
        </Grid>
      }
    />
  );
};
