import styled from 'styled-components';

import { ThemeColors } from '../theme';
import { TTheme } from '../../../../common/types';

export const StyledButton = styled.button`
  color: ${({ theme: { type } }) => ThemeColors.underlineText[type as TTheme].color};
  border: none;
  background: none;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  text-decoration-line: underline;
  transition: 0.2ms in-out;

  &:hover {
    opacity: 0.7;
  }
`;
