import { useQuery } from 'react-query';
import { QUERY_KEYS } from '../consts/app-keys.const';
import { rateService } from '../services';
import { useDebounce } from './use-debounce.hook';

export const useRate = (original: number | undefined, from: string, to: string) => {
  const debouncedQueryKey = useDebounce([original, from, to], 500);
  const { data: converted } = useQuery(
    [QUERY_KEYS.RATE, debouncedQueryKey],
    () => rateService.convert(from, to, original || 0),
    {
      enabled: original !== 0 && original !== undefined
    }
  );

  return {
    converted
  };
};
