export function isCapital(letter: string) {
  return letter.charCodeAt(0) >= 65 && letter.charCodeAt(0) <= 90;
}

export function splitByCapitalLetters(input: string): string {
  let result = '';

  for (let i = 0; i < input.length; i += 1) {
    const currentChar = i === 0 ? input[i].toUpperCase() : input[i];
    const nextChar = input[i + 1];
    const nextNextChar = input[i + 2];

    result += currentChar;

    if (nextChar && isCapital(nextChar) && !isCapital(currentChar)) {
      result += ' ';
    }
    if (
      nextChar &&
      nextNextChar &&
      isCapital(currentChar) &&
      isCapital(nextChar) &&
      !isCapital(nextNextChar)
    ) {
      result += ' ';
    }
  }

  return result;
}
