import { COLORS } from '../../../theme';

export const ThemeColors = {
  EstimationSection: {
    light: {
      background: COLORS.ghostWhite,
      borderColor: COLORS.azureishWhite,
      secondaryTextColor: COLORS.slateGray
    },
    dark: {
      background: COLORS.primary,
      borderColor: COLORS.independence,
      secondaryTextColor: COLORS.lightPeriwinkle
    }
  },
  underlineText: {
    light: {
      color: COLORS.primaryBlue
    },
    dark: {
      color: COLORS.blue40
    }
  }
};
