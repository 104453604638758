import { COLORS } from '../../../theme';

export const ThemeColors = {
  text: {
    light: {
      color: COLORS.darkBluePrimary
    },
    dark: {
      color: COLORS.orange70
    }
  }
};
