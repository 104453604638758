import { COLORS } from '../../../../../theme';

export const ThemeColors = {
  block: {
    light: {
      background: COLORS.ghostWhite,
      borderColor: COLORS.azureishWhite,
      secondaryTextColor: COLORS.slateGray,
      color: COLORS.primary
    },
    dark: {
      background: COLORS.primary,
      borderColor: COLORS.independence,
      secondaryTextColor: COLORS.lightPeriwinkle,
      color: COLORS.white
    }
  }
};
