import { ESort } from '.';

export enum TransactionStatusTypes {
  CANCELED = 'cancelled',
  CONFIRMED = 'confirmed',
  SUBMITED = 'pending'
}

export enum TransactionType {
  TOP_UP = 'top up',
  CASH_OUT = 'cash out',
  ERC_20 = 'erc20_transfer',
  DEX_ERC0_TRANSFER = 'dex_erc20_transfer',
  TRANSFER = 'transfer',
  DEX_INTERACTION = 'dex_interaction'
}

export interface ITransaction {
  from: string;
  to: string;
  txHash: string;
  value: string;
  status: TransactionStatusTypes;
  _id: string;
  data: string;
  type: 'income' | 'outcome';
  id: string;
  txType: TransactionType;
  fee: string;
  total: string;
  gasPrice: string;
  confirmedDate: string;
  submittedDate: string;
  transferType: string;
  tokenTicker?: number;
  tokensAmount?: string;
  appFee?: number;

  titles: { action: EFormsCrypto; title: string; currency: CoinType };

  dexEvent: {
    eventName?: string;
    orderId?: string;
    userAddress?: string;
    sellOrderId?: string;
    quantity?: string;
    value?: string;
    liquidationDate?: number;
    market?: string;
    spread?: number | string;
    instrument?: string;
    kilowattsConsumed?: string;
  };
}

export interface IFilters {
  from: string;
  to: string;
  relatedActivity: boolean;
  coin: string;
  txType: 'ALL' | 'COIN' | 'CRYPTO';
}

export interface IHistoryResponse {
  total: number;
  transactions: ITransaction[];
}
export interface CryptoAnalytics {
  name: string;
  ticker: string;
  priceInUsd: number;
  priceChangePercentage: number;
  image: string;
  balance: number;
}
export interface TopCryptosResponse {
  count: number;
  data: CryptoAnalytics[];
}

export enum EFormsCrypto {
  SEND = 'sendStroke',
  TOP = 'topUpStroke',
  OUT = 'cashOutStroke'
}

export interface IGetTransactionBody {
  pubKey: string;
  skip: number;
  limit: number;
  sort: ESort;
  volumeFrom?: number;
  volumeTo?: number;
  selectedCoin?: CoinType;
  onlyDex?: boolean;
  txType?: 'ALL' | 'COIN' | 'CRYPTO';
}

export enum CoinType {
  WBTC = 'WBTC',
  ETH = 'ETH',
  MATIC = 'MATIC',
  DAI = 'DAI',
  USDC = 'USDC',
  USDC_E = 'USDC.e'
}
