import { TxParams } from '../../web3auth/tx-params.type';
import { BalanceDTO } from '../types/balance.type';
import { EnhancedWithAuthHttpService } from './http-auth.service';
import { HttpFactoryService } from './http-factory.service';

class UserService {
  constructor(private readonly httpService: EnhancedWithAuthHttpService) {}

  async getTokenBalances(pubKey: string, nativeBalance: number, fiatCurrency: string) {
    return this.httpService.post<BalanceDTO, { pubKey: string; nativeBalance: number }>(
      `user/balances?rate=${fiatCurrency}`,
      {
        pubKey,
        nativeBalance
      }
    );
  }

  async getERC20transferTxData(pubKey: string, amount: number, to: string, symbol: string) {
    return this.httpService.post<
      TxParams & { walletFee: number },
      { pubKey: string; amount: number; to: string }
    >(`user/send-erc20-txdata?symbol=${symbol}`, {
      pubKey,
      amount,
      to
    });
  }

  async approveERC20(pubKey: string, amount: number, symbol: string) {
    return this.httpService.post<TxParams, { pubKey: string; amount: number }>(
      `user/approve-erc20-transfer?symbol=${symbol}`,
      {
        pubKey,
        amount
      }
    );
  }

  async getTransferTxData(pubKey: string, amount: number, to: string) {
    return this.httpService.post<
      TxParams & { walletFee: number },
      { pubKey: string; amount: number; to: string }
    >('user/send-transfer-txdata', {
      pubKey,
      amount,
      to
    });
  }

  async getFeePercantage() {
    return this.httpService.get<number>('fee');
  }
}

const httpService = HttpFactoryService.createAuthHttpService();
export const userService = new UserService(httpService);
