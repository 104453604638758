import styled from 'styled-components';
import { StyledText } from '../../../../../index.styled';
import { TransactionStatusTypes } from '../../../../../../types/transaction.type';
import { ThemeColors } from '../../../../theme';
import { TTheme } from '../../../../../../types';
import { FAMILIES, WEIGHTS } from '../../../../../../../theme/fonts.const';

export const CopyButton = styled.button`
  color: ${({ theme: { type } }) => ThemeColors.blueText[type as TTheme].color};
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  font-family: ${FAMILIES.inter};

  &:hover {
    opacity: 0.5;
  }
`;

export const StyledLink = styled.a`
  color: ${({ theme: { type } }) => ThemeColors.blueText[type as TTheme].color};
  border: none;
  background: none;
  cursor: pointer;
  text-decoration: none;
  font-family: ${FAMILIES.inter};

  &:hover {
    opacity: 0.5;
  }
`;

export const StatusText = styled(StyledText)<{ status: TransactionStatusTypes }>`
  color: ${({ status, theme: { type } }) => {
    if (status === TransactionStatusTypes.CANCELED) {
      return ThemeColors.status[type as TTheme].error;
    }
    if (status === TransactionStatusTypes.SUBMITED) {
      return ThemeColors.status[type as TTheme].submit;
    }
    return ThemeColors.status[type as TTheme].success;
  }};

  font-weight: ${WEIGHTS.medium};
  font-size: 16px;
  font-family: ${FAMILIES.inter};
`;
