import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { COLORS } from '../../../theme';
import { useTheme } from '../../providers/theme.provider';

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: COLORS.white,
    boxShadow: COLORS.blonde
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: COLORS.white,
    color: COLORS.darkElectricBlue,
    boxShadow: COLORS.blonde,
    fontSize: 12
  }
}));

export const DarkTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: COLORS.darkBlue,
    boxShadow: 'rgba(0, 0, 0, 0.2)'
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: COLORS.darkBlue,
    color: COLORS.white,
    boxShadow: 'rgba(0, 0, 0, 0.2)',
    fontSize: 12
  }
}));

export const ToolTip = (props: TooltipProps) => {
  const { currentTheme } = useTheme();

  return currentTheme === 'dark' ? <DarkTooltip {...props} /> : <LightTooltip {...props} />;
};
