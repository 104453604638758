import { useQuery } from 'react-query';
import { QUERY_KEYS } from '../../common/consts/app-keys.const';
import { useCurrency } from '../../common/providers/currency.provider';
import { userService } from '../../common/services';
import { useAuth } from '../../web3auth/web3auth.provider';

export const useBalance = () => {
  const { balance, authState } = useAuth();
  const { currency } = useCurrency();

  const { isLoading, data } = useQuery(
    [QUERY_KEYS.BALANCE, balance, currency],
    () => userService.getTokenBalances('', Number(balance) || 0, currency),
    {
      enabled: balance !== undefined && authState === 'LOGGED'
    }
  );

  return {
    isLoading,
    data
  };
};
