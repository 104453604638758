import styled from 'styled-components';

import { ReactComponent as CashOut } from '../../../assets/image/cashOut.svg';
import { ReactComponent as SendCrypto } from '../../../assets/image/sendCrypto.svg';
import { ReactComponent as TopUp } from '../../../assets/image/topUp.svg';
import { EFormsCrypto } from '../../common/types/transaction.type';

import { ReactComponent as CashOutHover } from '../../../assets/image/cache-out-hover.svg';
import { ReactComponent as SendCryptoHover } from '../../../assets/image/send-crypto-hover.svg';
import { ReactComponent as TopUpHover } from '../../../assets/image/top-up-hover.svg';

import { TTheme } from '../../common/types';

import { CashOutForm } from '../components/cash-out-form';
import { SendCryptoForm } from '../components/send-crypto-form';
import { TopUpForm } from '../components/top-up-form';
import { ThemeColors } from '../theme';

const styleIcon = (icon: React.FC<React.SVGProps<SVGSVGElement>>) => styled(icon)`
  width: 48px;
  height: 48px;

  fill: ${({ theme: { type } }) => ThemeColors.transactionIcon[type as TTheme].color} !important;

  path {
    fill: ${({ theme: { type } }) => ThemeColors.transactionIcon[type as TTheme].color} !important;
  }
`;

export const CRYPTO_BLOCKS = [
  {
    el: styleIcon(SendCrypto),
    elHover: styleIcon(SendCryptoHover),
    text: 'Send Crypto',
    form: SendCryptoForm,
    header: 'Send Crypto to',
    type: EFormsCrypto.SEND
  },
  {
    el: styleIcon(TopUp),
    elHover: styleIcon(TopUpHover),
    text: 'Top-Up Crypto Funds',
    form: TopUpForm,
    header: 'Top-Up Crypto Funds',
    type: EFormsCrypto.TOP
  },
  {
    el: styleIcon(CashOut),
    elHover: styleIcon(CashOutHover),
    text: 'Cash-Out Crypto Funds',
    form: CashOutForm,
    header: 'Cash-Out Crypto Funds',
    type: EFormsCrypto.OUT
  }
];
