import { useEffect, useRef } from 'react';

type EventListener = (event: MouseEvent | TouchEvent) => void;

export const useOutsideClick = (
  ref: React.RefObject<HTMLElement>,
  callback: EventListener,
  excludedRefs: React.RefObject<HTMLElement>[] = []
): void => {
  const callbackRef = useRef<EventListener>(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent | TouchEvent) => {
      const isExcluded = excludedRefs.some(
        (excludedRef) => excludedRef.current && excludedRef.current.contains(event.target as Node)
      );
      const isOutsideClick = ref.current && !ref.current.contains(event.target as Node);

      if (isOutsideClick && !isExcluded) {
        callbackRef.current(event);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    document.addEventListener('touchstart', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('touchstart', handleOutsideClick);
    };
  }, [ref, excludedRefs]);
};
