import { useEffect } from 'react';
import { useQuery } from 'react-query';

import { userService } from '../../services/user.service';

export const useFetchUserAvatar = (wallet: string, id: string, enabled: boolean = true) => {
  const { data, refetch, isLoading, isFetching } = useQuery(
    ['avatar'],
    async () => userService.getUserAvatarByWallet(wallet),
    { enabled }
  );

  useEffect(() => {
    refetch();
  }, [wallet, id]);

  return { avatar: data, isLoading: isLoading || isFetching, refetch };
};
