import { useAuth } from '../../../../../../../web3auth/web3auth.provider';
import { ReactComponent as Watt } from '../../../../../../../../assets/image/watt.svg';
import { useCurrency } from '../../../../../../providers/currency.provider';
import { ITransaction, TransactionType } from '../../../../../../types/transaction.type';
import { priceFormat } from '../../../../../../utils';
import { Box } from '../../../../../index.styled';
import { ArrowTooltip } from '../../../../../tooltip';
import { ExtraInfo } from '../extra-info';
import {
  Amount,
  Container,
  ConvertedAmount,
  ConvertedAmountBox,
  HeadBlock,
  Text,
  Total,
  TotalContainer
} from './index.styled';
import { useTheme } from '../../../../../../providers/theme.provider';
import { COLORS } from '../../../../../../../theme';

interface IProps extends ITransaction {
  value: string;
  appFee?: number;
  isWyre: boolean;
  token: string;
}

export const TransactionDetails = ({
  fee,
  gasPrice,
  value,
  total,
  isWyre,
  txType,
  dexEvent,
  appFee,
  token
}: IProps) => {
  const { symbol } = useCurrency();
  const { fromEthToGWei } = useAuth();
  const { currentTheme } = useTheme();

  const gasInGwei = fromEthToGWei(gasPrice);
  const feeInGwei = fromEthToGWei(fee);

  return (
    <Container changeDirection>
      <HeadBlock>
        <p>Transaction</p>
      </HeadBlock>

      {txType !== TransactionType.DEX_INTERACTION && (
        <Box justify="space-between" margin="12px 10px 12px">
          <Text>Value</Text>
          <Text>{value}</Text>
        </Box>
      )}

      {isWyre ? (
        <Box justify="space-between" margin="0 10px 12px ">
          <Text>Provider fee</Text>
          <Text>{fee} USD</Text>
        </Box>
      ) : (
        <>
          {appFee && (
            <Box justify="space-between" margin="0 10px 12px">
              <Text>Wallet Fee</Text>
              <Text>
                {priceFormat(appFee.toString(), 4)} {token}
              </Text>
            </Box>
          )}
          <Box justify="space-between" margin="12px 10px 0px ">
            <Text>Network Fee</Text>
            <ArrowTooltip title={`${priceFormat(fee, 10)} MATIC`}>
              <Text>{priceFormat(feeInGwei, 2)} GWei</Text>
            </ArrowTooltip>
          </Box>
          <Box justify="space-between" margin="12px 10px 12px">
            <Text>Gas Price</Text>
            <ArrowTooltip title={`${priceFormat(gasPrice, 10)} MATIC`}>
              <Text>{priceFormat(gasInGwei, 2)} GWei</Text>
            </ArrowTooltip>
          </Box>

          {txType === TransactionType.DEX_INTERACTION ? <ExtraInfo {...dexEvent} /> : null}
        </>
      )}

      {txType !== TransactionType.DEX_INTERACTION && (
        <TotalContainer>
          <Total>Total</Total>
          <Box changeDirection align="start">
            <Amount>
              {value} +{isWyre ? `${fee} ${'USD'}` : ` ${priceFormat(feeInGwei, 2)} GWei`}
            </Amount>
            <ConvertedAmountBox>
              {/* {txType === TransactionType.DEX_INTERACTION ? (
                <Watt
                  width="16px"
                  height="16px"
                  fill={currentTheme === 'light' ? COLORS.primary : COLORS.white}
                />
              ) : (
              )} */}
              <ConvertedAmount>{symbol}</ConvertedAmount>
              <ConvertedAmount>{priceFormat(total, 3)}</ConvertedAmount>
            </ConvertedAmountBox>
          </Box>
        </TotalContainer>
      )}
    </Container>
  );
};

