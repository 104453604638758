import * as Yup from 'yup';

export const topUpFormValidationSchema = Yup.object().shape({
  amount: Yup.string().required('Required'),

  group2: Yup.string().test('checkGroup2', 'Required', function () {
    const amount = parseFloat(this.parent.amount);
    const coin = parseFloat(this.parent.coin);
    return !!(amount || coin);
  })
});
