import Modal from '@mui/material/Modal';
import styled, { css } from 'styled-components';
import { ThemeColors } from '../../consts';
import { TTheme } from '../../types';

export const StyledModal = styled(Modal)<{ hide?: boolean }>`
  ${({ hide }) =>
    hide &&
    css`
      visibility: hidden;
    `}
  height: 100%;
  width: 100%;
`;

export const Container = styled.div<{ maxWidth?: string; background: 'dark' | 'light' }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${({ background, theme: { type } }) =>
    background === 'light'
      ? ThemeColors.modal[type as TTheme].backgroundLight
      : ThemeColors.modal[type as TTheme].backgroundDark} !important;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : '492px')} !important;
  width: 100% !important;
  margin: 15px !important;
  border: 1px solid ${({ theme: { type } }) => ThemeColors.modal[type as TTheme].border}!important;
  box-shadow: 0px 1px 4px rgba(255, 132, 39, 0.08) !important;
  border-radius: 12px !important;
  padding: 22px 22px 32px !important;
  max-height: calc(100% - 58px);
  overflow: auto;
`;

export const StyledButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  padding-top: 10px;

  svg {
    fill: ${({ theme: { type } }) => ThemeColors.text[type as TTheme].color};
  }
`;
