import { FONTS } from '../../../../../theme';
import { useCurrency } from '../../../../providers/currency.provider';
import { priceFormat } from '../../../../utils';
import { StyledText } from '../../../index.styled';
import { Container, TotalBalance } from './index.styled';

interface IProps {
  balance: number;
}

export const Balance = ({ balance }: IProps) => {
  const { symbol } = useCurrency();

  return (
    <Container>
      <TotalBalance>Total Balance:</TotalBalance>
      <StyledText fontWeight={FONTS.WEIGHTS.medium} fontSize={FONTS.SIZES.xl}>
        {`${symbol} ${priceFormat(Number(balance).toFixed(2))}`}
      </StyledText>
    </Container>
  );
};
