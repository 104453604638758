import { Grid } from '@mui/material';
import styled, { css } from 'styled-components';

import { Link } from 'react-router-dom';
import { ThemeColors } from '../common/consts';
import { TTheme } from '../common/types';

const AuthContainer = styled(Grid)`
  height: 100vh;

  overflow: hidden;
  background: ${({ theme: { type } }) => ThemeColors.maincontainer[type as TTheme].background};
  height: 100%;
  position: relative;
  min-height: 100vh;

  & .top {
    position: absolute;
    width: 670px;

    left: 425.09px;
    top: -413.17px;
  }

  & .left {
    position: absolute;
    left: -300px;
    top: 83px;
    height: 1055px;
  }

  & .right {
    position: absolute;
    left: calc(100% - 421px);
    top: calc(100% - 549px);
    width: 670px;
  }
`;

const FormContainer = styled(Grid)`
  background-color: ${({ theme: { type } }) => ThemeColors.modal[type as TTheme].backgroundLight};
  border-radius: 12px;
  box-shadow: 0px 1px 4px rgba(255, 132, 39, 0.08);
  border: 1px solid ${({ theme: { type } }) => ThemeColors.modal[type as TTheme].darkBorder};
  padding: 29px;
  z-index: 1;
`;

const SocialBtn = styled('div')<{ disabled?: boolean }>`
  cursor: pointer;
  background: ${({ theme: { type } }) => ThemeColors.authIcons[type as TTheme].background};
  display: flex;
  padding: 14px;
  width: 52px;
  height: auto;
  border: 1px solid ${({ theme: { type } }) => ThemeColors.authIcons[type as TTheme].border};
  border-radius: 100px;
  ${(props) =>
    props.disabled &&
    css`
      filter: grayscale(1);
      cursor: default;
    `}
`;

const StyledLink = styled(Link)`
  text-decoration: underline;
  color: #ffd63f;
`;

export { AuthContainer, FormContainer, SocialBtn, StyledLink };
