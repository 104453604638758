import React from 'react';
import { AvatarImage } from './index.styled';
import defaultAvatar from '../../../../assets/image/defaultAvatar.png';

interface IProps {
  src: string | null;
  width?: number;
}
export const Avatar = ({ src, width }: IProps) => (
  <AvatarImage width={width ?? 48} height={width ?? 48} src={`${src}` || defaultAvatar} />
);
