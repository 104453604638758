/* eslint-disable max-len */
import { Term, TermType } from '../components/content.type';

type Section = {
  title: string;
  terms: Term[];
};

const content: Section[] = [
  {
    title: 'LAST UPDATE 08/18/2023',
    terms: [
      {
        type: TermType.PARAGRAPH,
        content:
          'This privacy policy (“Policy”) describes how Watt2Trade LLC. (“Company”, “we”, “our”, or “us”) collects, uses, shares, and stores personal information of users of its websites https://www.watt2trade.com; https://wallet.watt2trade.com	 (collectively, “Services”) on or in which it is posted, linked, or referenced.'
      }
    ]
  },
  {
    title: 'CONSENT',
    terms: [
      {
        type: TermType.PARAGRAPH,
        content:
          'By using the Services, you accept the terms of this Policy and our Terms of Use, and consent to our collection, use, disclosure, and retention of your information as described in this Policy.  If you have not done so already, please also review our terms of use. The terms of use contain provisions that limit our liability to you and require you to resolve any dispute with us on an individual basis and not as part of any class or representative action. IF YOU DO NOT AGREE WITH ANY PART OF THIS PRIVACY POLICY OR OUR TERMS OF USE, THEN PLEASE DO NOT USE ANY OF THE SERVICES.'
      }
    ]
  },
  {
    title: 'WHAT WE COLLECT',
    terms: [
      {
        type: TermType.PARAGRAPH,
        content: 'We get information about you in a range of ways.'
      },
      {
        type: TermType.SUBTITLE,
        content: 'Information You Give Us.'
      },
      {
        type: TermType.PARAGRAPH,
        content:
          'Information we collect from you may include:Information we collect from you may include:'
      },
      {
        type: TermType.LIST,
        content: [
          'Identity information, such as your first name, last name, username or similar identifier, title, date of birth and gender;',
          'Contact information, such as your postal address, email address and telephone number;',
          'Profile information, such as your username and password, interests, preferences, feedback and survey responses;',
          'Feedback and correspondence, such as information you provide in your responses to surveys, when you participate in market research activities, report a problem with Service, receive customer support or otherwise correspond with us;',
          'Financial information, such as your credit card or other payment card details;',
          'Transaction information, such details about purchases you make through the Service and billing details;',
          'Usage information, such as information about how you use the Service and interact with us;',
          'Marketing information, such as your preferences for receiving marketing communications and details about how you engage with them;',
          'Financial information, such as bank account number and bank routing number; financial assets holdings; and',
          'Technical information, such as your Ethereum wallet address, application programming interface (API)-key and network information regarding transactions.'
        ]
      },
      {
        type: TermType.SUBTITLE,
        content:
          'Further Information You Give Us In Relation to the Watt2Trade Site and Watt2Trade Services.'
      },
      {
        type: TermType.PARAGRAPH,
        content:
          'Further information we collect from you in relation to https://wallet.watt2trade.com/ may include:'
      },
      {
        type: TermType.LIST,
        content: [
          'Further identity information, such as your country of birth, nationality, social security number, place of birth, employer and occupation;',
          'Passport and/or photo ID for identity verification purposes;',
          'Information required to comply with anti-money laundering (AML) laws and know-your-customer (KYC) requirements (such as nationality and place of birth);',
          'Source of funds for participating in token launches; and',
          'Information that you give us in relation to your purchased token holdings, such as earnings received from staking, trading, and the number of tokens in your wallet.'
        ]
      },
      { type: TermType.SUBTITLE, content: 'Log Files' },
      {
        type: TermType.PARAGRAPH,
        content:
          'We may automatically record certain information about how you use our Sites (we refer to this information as “Log Data“). Log Data may include information such as a user’s Internet Protocol (IP) address, device and browser type, Internet Service Provider (ISP), operating system, the pages or features of our Sites to which a user browsed and the time spent on those pages or features, the frequency with which the Sites are used by a user, search terms, the links on our Sites that a user clicked on or used, and other statistics. We use this information to administer the Service and we analyze (and may engage third parties to analyze) this information to improve and enhance the Service by expanding its features and functionality and tailoring it to our users’ needs and preferences.'
      },
      {
        type: TermType.PARAGRAPH,
        content:
          'We may use cookies, local storage or similar technologies to analyze trends, administer the Sites, track users’ movements around the Sites, and to gather demographic information about our user base as a whole. Users can control the use of cookies and local storage at the individual browser level. '
      },
      {
        type: TermType.SUBTITLE,
        content: 'Cookies and Web Beacons'
      },
      {
        type: TermType.PARAGRAPH,
        content:
          "Like any other website, Watt2Trade uses \"cookies\". These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information."
      },
      {
        type: TermType.SUBTITLE,
        content: 'Information we will never collect.'
      },
      {
        type: TermType.PARAGRAPH,
        content:
          'We will never ask you to share your private keys or wallet seed. Never trust anyone or any site that asks you to enter your private keys or wallet seed.'
      }
    ]
  },
  {
    title: 'USE OF PERSONAL INFORMATION',
    terms: [
      {
        type: TermType.SUBTITLE,
        content: 'To provide our service'
      },
      {
        type: TermType.PARAGRAPH,
        content: 'We will use your personal information in the following ways:'
      },
      {
        type: TermType.LIST,
        content: [
          'To enable you to access and use the Services',
          'To process and complete transactions, and send you related information, including purchase confirmations and invoices',
          'To send information, including confirmations, technical notices, updates, security alerts, and support and administrative messages.',
          'Provide, operate, and maintain our website',
          'Improve, personalize, and expand our website',
          'Understand and analyze how you use our website',
          'Develop new products, services, features, and functionality',
          'Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes',
          'Find and prevent fraud'
        ]
      },
      {
        type: TermType.SUBTITLE,
        content: 'To comply with law'
      },
      {
        type: TermType.PARAGRAPH,
        content:
          'We use your personal information as we believe necessary or appropriate to comply with applicable laws (including anti-money laundering (AML) laws and know-your-customer (KYC) requirements), lawful requests and legal process, such as to respond to subpoenas or requests from government authorities.'
      },
      {
        type: TermType.SUBTITLE,
        content: 'To communicate with you'
      },
      {
        type: TermType.PARAGRAPH,
        content:
          'We use your personal information to communicate about promotions, upcoming events, and other news about products and services offered by us and our selected partners.'
      },
      {
        type: TermType.SUBTITLE,
        content: 'To optimize our platform'
      },
      {
        type: TermType.PARAGRAPH,
        content:
          'In order to optimize your user experience, we may use your personal information to operate, maintain, and improve our Services. We may also use your information to respond to your comments and questions regarding the Services, and to provide you and other users with general customer service.'
      },
      {
        type: TermType.SUBTITLE,
        content: 'With your consent'
      },
      {
        type: TermType.PARAGRAPH,
        content:
          'We may use or share your personal information with your consent, such as when you consent to let us post your testimonials or endorsements on our Sites, you instruct us to take a specific action with respect to your personal information, or you opt into third party marketing communications.'
      },
      {
        type: TermType.SUBTITLE,
        content: 'For compliance, fraud prevention, and safety'
      },
      {
        type: TermType.PARAGRAPH,
        content:
          'We may use your personal information to protect, investigate, and deter against fraudulent, unauthorized, or illegal activity.'
      }
    ]
  },
  {
    title: 'SHARING OF PERSONAL INFORMATION',
    terms: [
      {
        type: TermType.PARAGRAPH,
        content:
          'We do not share the personal information that you provide us with other organizations without your express consent, except as described in this Privacy Policy. We disclose personal information to third parties under the following circumstances:'
      },
      {
        type: TermType.LIST,
        content: [
          'Affiliates. We may disclose your personal information to our subsidiaries and corporate affiliates (i.e. our family of companies that are related by common ownership or control) for purposes consistent with this Privacy Policy.',
          'Business Transfers. We may share personal information when we do a business deal, or negotiate a business deal, involving the sale or transfer of all or a part of our business or assets. These deals can include any merger, financing, acquisition, or bankruptcy transaction or proceeding.',
          'Compliance with Laws and Law Enforcement; Protection and Safety. We may share personal information for legal, protection, and safety purposes.',
          [
            'We may share information to comply with laws, including KYC and AML requirements.',
            'We may share information to respond to lawful requests and legal processes.',
            'We may share information to protect the rights and property of the Company, our agents, customers, and others. This includes enforcing our agreements, policies, and terms of use.',
            'We may share information in an emergency. This includes protecting the safety of our employees and agents, our customers, or any person.'
          ],
          'Professional Advisors and Service Providers. We may share information with those who need it to do work for us. These recipients may include third party companies and individuals to administer and provide the Service on our behalf (such as bill and credit card payment processing, customer support, hosting, email delivery and database management services), as well as lawyers, bankers, auditors, and insurers.',
          'Other. You may permit us to share your personal information with other companies or entities of your choosing. Those uses will be subject to the privacy policies of the recipient entity or entities.'
        ]
      },
      {
        type: TermType.PARAGRAPH,
        content:
          'We may also share aggregated and/or anonymized data with others for their own uses.'
      }
    ]
  },
  {
    title: 'INTERNATIONAL TRANSFER',
    terms: [
      {
        type: TermType.PARAGRAPH,
        content:
          'The Company has offices outside of the EU and has affiliates and service providers in the United States and in other countries. Your personal information may be transferred to or from the United States or other locations outside of your state, province, country or other governmental jurisdiction where privacy laws may not be as protective as those in your jurisdiction.'
      },
      {
        type: TermType.PARAGRAPH,
        content:
          'EU users should read the important information provided below about transfer of personal information outside of the European Economic Area (EEA).'
      }
    ]
  },
  {
    title: 'HOW INFORMATION IS SECURED',
    terms: [
      {
        type: TermType.PARAGRAPH,
        content:
          'We retain information we collect as long as it is necessary and relevant to fulfill the purposes outlined in this privacy policy. In addition, we retain personal information to comply with applicable law where required, prevent fraud, resolve disputes, troubleshoot problems, assist with any investigation, enforce our Terms of Use, and other actions permitted by law. To determine the appropriate retention period for personal information, we consider the amount, nature, and sensitivity of the personal information, the potential risk of harm from unauthorized use or disclosure of your personal information, the purposes for which we process your personal information and whether we can achieve those purposes through other means, and the applicable legal requirements.'
      },
      {
        type: TermType.PARAGRAPH,
        content:
          'In some circumstances we may anonymize your personal information (so that it can no longer be associated with you) in which case we may use this information indefinitely without further notice to you.'
      },
      {
        type: TermType.PARAGRAPH,
        content:
          'We employ industry standard security measures designed to protect the security of all information submitted through the Services. However, the security of information transmitted through the internet can never be guaranteed. We are not responsible for any interception or interruption of any communications through the internet or for changes to or losses of data. Users of the Services are responsible for maintaining the security of any password, biometrics, user ID or other form of authentication involved in obtaining access to password protected or secure areas of any of our digital services. In order to protect you and your data, we may suspend your use of any of the Services, without notice, pending an investigation, if any breach of security is suspected.'
      }
    ]
  },
  {
    title: 'INFORMATION CHOICES AND CHANGES',
    terms: [
      {
        type: TermType.SUBTITLE,
        content: 'Accessing, Updating, Correcting, and Deleting your Information'
      },
      {
        type: TermType.PARAGRAPH,
        content:
          'You may access information that you have voluntarily provided through your account on the Services, and to review, correct, or delete it by sending a request to privacy@watt2trade.com You can request to change contact choices, opt-out of our sharing with others, and update your personal information and preferences.'
      },
      {
        type: TermType.SUBTITLE,
        content: "Children's Information"
      },
      {
        type: TermType.PARAGRAPH,
        content: `Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.
If you are under the age of majority in your jurisdiction of residence, you may use the Services only with the consent of or under the supervision of your parent or legal guardian. Consistent with the requirements of the Children’s Online Privacy Protection Act (COPPA), if we learn that we have received any information directly from a child under age 13 without first receiving his or her parent’s verified consent, we will use that information only to respond directly to that child (or his or her parent or legal guardian) to inform the child that he or she cannot use the Sites and subsequently we will delete that information.`
      },
      {
        type: TermType.PARAGRAPH,
        content:
          'Watt2Trade does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.'
      }
    ]
  },
  {
    title: 'MARKETING COMMUNICATIONS AND SHARING',
    terms: [
      {
        type: TermType.PARAGRAPH,
        content:
          'You may instruct us not to use your contact information to contact you regarding services, promotions, surveys and special events that might appeal to your interests by contacting us using the information below. You can also opt out by following the instructions located at the bottom of any commercial emails messages you may receive.'
      },
      {
        type: TermType.PARAGRAPH,
        content:
          'Please note that, regardless of your request, we may still use and share certain information as permitted by applicable law. For example, you may not opt out of certain operational emails, such as those reflecting our relationship or transactions with you, or important notifications regarding the Services we are providing to you, such as service-related announcements (e.g., if our Services are temporarily suspended for maintenance).'
      },
      {
        type: TermType.PARAGRAPH,
        content:
          'Or, if you have downloaded our mobile application and enabled push notifications on your mobile device, we may send you alerts and notifications through push notifications, for example, to communicate status updates on our Services. However, you may choose to disable these notifications (except for the initial notification intended to verify your identity).'
      }
    ]
  },
  {
    title: 'THIRD PARTY LINKS AND WEBSITES',
    terms: [
      {
        type: TermType.PARAGRAPH,
        content:
          "Watt2Trade's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options. "
      },
      {
        type: TermType.PARAGRAPH,
        content:
          "You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites."
      }
    ]
  },
  {
    title: 'CO-BRANDED WEBSITES',
    terms: [
      {
        type: TermType.PARAGRAPH,
        content:
          'In the event that our Sites link to other websites that include our branding, this Privacy Notice does not apply to those other websites. Visitors to those websites are advised to carefully read the notices on those individual websites.'
      }
    ]
  },
  {
    title: 'CHANGES TO THIS PRIVACY POLICY',
    terms: [
      {
        type: TermType.PARAGRAPH,
        content:
          'We may change this privacy policy at any time. We encourage you to periodically review this page for the latest information on our privacy practices. If we make any changes, we will change the Last Updated date above.'
      },
      {
        type: TermType.PARAGRAPH,
        content:
          'Any modifications to this Privacy Policy will be effective upon our posting of the new terms and/or upon implementation of the changes to the Sites (or as otherwise indicated at the time of posting). In all cases, your continued use of the Sites or Services after the posting of any modified Privacy Policy indicates your acceptance of the terms of the modified Privacy Policy.'
      }
    ]
  },
  {
    title: 'CONTACT US',
    terms: [
      {
        type: TermType.PARAGRAPH,
        content:
          'We welcome your comments or questions about this Policy, and you may contact us at: '
      },
      {
        type: TermType.PARAGRAPH,
        content: 'Email: privacy@watt2trade.com'
      },
      {
        type: TermType.PARAGRAPH,
        content: 'or write to us at:'
      },
      {
        type: TermType.PARAGRAPH,
        content: 'Watt2Trade LLC'
      },
      {
        type: TermType.PARAGRAPH,
        content: '1980 Post Oak Blvd #100, suite 222'
      },
      {
        type: TermType.PARAGRAPH,
        content: 'Houston, Texas'
      },
      {
        type: TermType.PARAGRAPH,
        content: '77056'
      }
    ]
  },
  {
    title: 'NOTICE TO CALIFORNIA RESIDENTS',
    terms: [
      {
        type: TermType.PARAGRAPH,
        content:
          'Under California Civil Code Section 1789.3, California users are entitled to the following consumer rights notice: California residents may reach the Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs by mail at 1625 North Market Blvd., Sacramento, CA 95834, or by telephone at (916) 445-1254 or (800) 952-5210.'
      },
      {
        type: TermType.PARAGRAPH,
        content:
          'This section provides additional details about the personal information we collect about California consumers and the rights afforded to them under the California Consumer Privacy Act or “CCPA.”'
      },
      {
        type: TermType.PARAGRAPH,
        content:
          'For more details about the personal information, we collect from you, please see the “What We Collect” section above. We collect this information for the business and commercial purposes described in the “Use of Personal Information” section above. We share this information with the categories of third parties described in the “Sharing of Personal Information” section above. Company does not sell (as such term is defined in the CCPA) the personal information we collect (and will not sell it without providing a right to opt out).'
      },
      {
        type: TermType.PARAGRAPH,
        content:
          'Subject to certain limitations, the CCPA provides California consumers the right to request to know more details about the categories or specific pieces of personal information we collect (including how we use and disclose this information), to delete their personal information, to opt out of any “sales” that may be occurring, and to not be discriminated against for exercising these rights.'
      },
      {
        type: TermType.PARAGRAPH,
        content:
          'California consumers may make a request pursuant to their rights under the CCPA by contacting us at notices@watt2trade.com Please note that you must verify your identity and request before further action is taken. As a part of this process, government identification may be required. Consistent with California law, you may designate an authorized agent to make a request on your behalf. In order to designate an authorized agent to make a request on your behalf, you must provide a valid power of attorney, the requester’s valid government issued identification, and the authorized agent’s valid government issued identification.'
      }
    ]
  },
  {
    title: 'NOTICE TO EU DATA SUBJECTS',
    terms: [
      {
        type: TermType.SUBTITLE,
        content: 'Personal Information'
      },
      {
        type: TermType.PARAGRAPH,
        content:
          'With respect to EU data subjects, “personal information,” as used in this Privacy Policy, is equivalent to “personal data” as defined in the European Union General Data Protection Regulation (GDPR). '
      },
      {
        type: TermType.SUBTITLE,
        content: 'Sensitive Data'
      },
      {
        type: TermType.PARAGRAPH,
        content:
          'Some of the information you provide us may constitute sensitive data as defined in the GDPR (also referred to as special categories of personal data), including identification of your race or ethnicity on government-issued identification documents.'
      },
      {
        type: TermType.SUBTITLE,
        content: 'Legal Bases for Processing'
      },
      {
        type: TermType.PARAGRAPH,
        content:
          'We only use your personal information as permitted by law. We are required to inform you of the legal bases of our processing of your personal information, which are described in the table below. If you have questions about the legal bases under which we process your personal information, contact privacy@watt2trade.com '
      },
      {
        type: TermType.TABLE,
        content: {
          headers: ['Processing Purpose', 'Legal Basis'],
          rows: [
            [
              'To provide our service',
              'Our processing of your personal information is necessary to perform the contract governing our provision of the Services or to take steps that you request prior to signing up for the Service.'
            ],
            [
              'To communicate with you To optimize our platform For compliance, fraud prevention, and safety To provide our service',
              'These processing activities constitute our legitimate interests. We make sure we consider and balance any potential impacts on you (both positive and negative) and your rights before we process your personal information for our legitimate interests. We do not use your personal information for activities where our interests are overridden by any adverse impact on you (unless we have your consent or are otherwise required or permitted to by law).'
            ],
            [
              'To comply with law',
              'We use your personal information to comply with applicable laws and our legal obligations, including anti-money laundering (AML) laws and know-your-customer (KYC) requirements.'
            ],
            [
              'With your consent',
              'Where our use of your personal information is based upon your consent, you have the right to withdraw it anytime in the manner indicated in the Service or by contacting us at privacy@watt2trade.com'
            ]
          ]
        }
      },
      {
        type: TermType.SUBTITLE,
        content: 'Use for New Purposes'
      },
      {
        type: TermType.PARAGRAPH,
        content:
          'We may use your personal information for reasons not described in this Privacy Policy, where we are permitted by law to do so and where the reason is compatible with the purpose for which we collected it. If we need to use your personal information for an unrelated purpose, we will notify you and explain the applicable legal basis for that use. If we have relied upon your consent for a particular use of your personal information, we will seek your consent for any unrelated purpose.'
      },
      {
        type: TermType.SUBTITLE,
        content: 'Your Rights'
      },
      {
        type: TermType.PARAGRAPH,
        content:
          'Under the GDPR, you have certain rights regarding your personal information. You may ask us to take the following actions in relation to your personal information that we hold:'
      },
      {
        type: TermType.LIST,
        content: [
          'Opt-out. Stop sending you direct marketing communications which you have previously consented to receive. We may continue to send you Service-related and other non-marketing communications.',
          'Access. Provide you with information about our processing of your personal information and give you access to your personal information.',
          'Correct. Update or correct inaccuracies in your personal information.',
          'Delete. Delete your personal information.',
          'Transfer. Transfer a machine-readable copy of your personal information to you or a third party of your choice.',
          'Restrict. Restrict the processing of your personal information.',
          'Object. Object to our reliance on our legitimate interests as the basis of our processing of your personal information that impacts your rights.'
        ]
      },
      {
        type: TermType.PARAGRAPH,
        content:
          'You can submit these requests by email to privacy@watt2trade.com We may request specific information from you to help us confirm your identity and process your request. Applicable law may require or permit us to decline your request. If we decline your request, we will tell you why, subject to legal restrictions. If you would like to submit a complaint about our use of your personal information or response to your requests regarding your personal information, you may contact us at privacy@watt2trade.com or submit a complaint to the data protection regulator in your jurisdiction. You can find your data protection regulator here.'
      },
      {
        type: TermType.SUBTITLE,
        content: 'Cross-Border Data Transfer'
      },
      {
        type: TermType.PARAGRAPH,
        content:
          'Please be aware that your personal data will be transferred to, processed, and stored in the United States. Data protection laws in the U.S. may be different from those in your country of residence. You consent to the transfer of your information, including personal information, to the U.S. as set forth in this Privacy Policy by visiting our Sites or using our service.'
      },
      {
        type: TermType.PARAGRAPH,
        content: `Whenever we transfer your personal information out of the EEA to the U.S. or countries not deemed by the European Commission to provide an adequate level of personal information protection, the transfer will be based on a data transfer mechanism recognized by the European Commission as providing adequate protection for personal information.
Please contact us if you want further information on the specific mechanism used by us when transferring your personal information out of the EEA.`
      }
    ]
  },
  {
    title: 'AVISO A LOS SUJETOS DE DATOS EN MÉXICO',
    terms: []
  },
  {
    title: 'QUIÉNES SOMOS',
    terms: [
      {
        type: TermType.PARAGRAPH,
        content:
          'Watt2Trade, S.A.P.I. DE C.V (en lo sucesivo “Watt2Trade") con domicilio Boulevard Manuel Ávila Camacho 118 Piso 1703, Col. Lomas de Chapultepec V Sección, Del. Miguel Hidalgo, C.P. 11000 en la Ciudad de México, México, le hace saber a los usuarios del sitio Web https://www.watt2trade.com; https://wallet.watt2trade.com clientes y quienes nos contactan vía telefónica o correo electrónico, que Watt2Trade obtiene sus datos personales, en virtud del llenado de los formularios del sitio Web, la prestación o contratación de algún servicio y/o su contacto directo vía correo electrónico, telefónico o redes sociales.'
      },
      {
        type: TermType.PARAGRAPH,
        content:
          'Por lo que, en términos de lo señalado por la LEY FEDERAL DE PROTECCIÓN DE DATOS PERSONALES EN POSESIÓN DE LOS PARTICULARES (la “Ley”), manifiesta que los datos personales que sean proporcionados a Watt2Trade tendrán un tratamiento legítimo, controlado e informado, a efecto de garantizar la privacidad y el derecho a la autodeterminación de los Usuarios.'
      }
    ]
  },
  {
    title: 'CONSENTIMIENTO',
    terms: [
      {
        type: TermType.PARAGRAPH,
        content:
          'Al usar los Servicios, usted acepta los términos de esta Política de Términos de Usos y Condiciones, y da su consentimiento para que recopilemos, usemos, divulguemos y retengamos su información como se describe en esta Política. Si aún no lo ha hecho, consulte también nuestras condiciones de uso. Los términos de uso contienen disposiciones que limitan nuestra responsabilidad hacia usted y requieren que resuelva cualquier disputa con nosotros de forma individual y no como parte de una acción colectiva o representativa. SI NO ESTÁ DE ACUERDO CON ALGUNA PARTE DE ESTA POLÍTICA DE PRIVACIDAD O NUESTROS TÉRMINOS DE USO, ENTONCES NO UTILICE NINGUNO DE LOS SERVICIOS.'
      }
    ]
  },
  {
    title: '¿QUÉ DATOS RECABAMOS Y PARA QUÉ LOS UTILIZAMOS?',
    terms: [
      {
        type: TermType.PARAGRAPH,
        content:
          'El Titular ha designado un Delegado de Protección de datos personales (DPD), a quien podrá dirigirse escribiendo a la siguiente dirección de correo electrónico notices@watt2trade.com'
      },
      {
        type: TermType.PARAGRAPH,
        content:
          'Recuerde que usted es responsable de enviar o completar los datos personales a través de las solicitudes, preguntas, registros, formularios, formatos, correos electrónicos que al efecto se soliciten o pongan a disposición a través de este sitio'
      },
      {
        type: TermType.PARAGRAPH,
        content:
          'Web, redes, Internet o de forma telefónica. Watt2Trade podrá solicitar y tratar los siguientes datos dependiendo la finalidad:'
      },
      {
        type: TermType.LIST,
        content: [
          'Permitir el uso de todas las funcionalidades del Sitio.',
          'Ejecución de un contrato.',
          'Controlar el funcionamiento correcto del Sitio.',
          'Verificación de responsabilidad en el caso de delitos informáticos en perjuicio del Sitio; reconocimiento, prevención, mitigación y verificación de actividades fraudulentas o ilegales relacionadas con los servicios que provee el Sitio; desarrollo de los controles de seguridad establecidos por ley.',
          'Interés legítimo.',
          'Dar respuesta a una pregunta o a una solicitud adelantada del interesado.',
          'Ejecución de medidas precontractuales adoptadas según la solicitud del interesado.',
          'Desempeño de Watt2Trade, empresas matrices, filiales o vinculadas a ella, o socios comerciales de Watt2Trade, para realizar estudios de mercado, ventas directas, también por teléfono, para la venta de productos o servicios, para comunicación comercial o actividades de marketing. Estas actividades podrán realizarse con el envío de material publicitario, informativo, promocional o con invitaciones a través de los canales tradicionales (por ejemplo, correo en papel) o sistemas automatizados de contacto (por ejemplo, SMS o e-mail).',
          'Consentimiento del interesado'
        ]
      }
    ]
  },
  {
    title: 'DATOS TRATADOS',
    terms: [
      {
        type: TermType.LIST,
        content: [
          'Ofrecerle una experiencia personalizada en nuestro Sitio Web.',
          'Cookies, dirección IP, web beacons.',
          'Atenderle en la prestación de algún servicio de consultoría, desarrollo y/o uso de aplicaciones.',
          'Nombre, teléfono, RFC, domicilio, correo electrónico.',
          'Su interés de ponernos en contacto o hacerle llegar alguna información de Watt2Trade o los esquemas de distribución de nuestras soluciones.',
          'Nombre, teléfono, correo electrónico y comentarios.',
          'Hacerles llegar información publicitaria de los productos y servicios de Watt2Trade, así como de las franquicias bajo su marca.',
          'Nombre, dirección, teléfono, mail.'
        ]
      },
      {
        type: TermType.PARAGRAPH,
        content:
          'Usted puede oponerse a la finalidad de hacerle llegar información publicitaria (finalidad secundaria) en cualquier momento sin que por ello afecte el cumplimiento a las otras finalidades o relación jurídica con usted.'
      },
      {
        type: TermType.PARAGRAPH,
        content:
          'Watt2Trade en ningún momento recabará información que sea considerada como Datos Personales Sensibles. Así mismo hacemos de su conocimiento que no toda la Información que se solicita es obligatoria, sino únicamente aquella que así se le haga saber a través de las solicitudes, registros, formularios, formatos, contratos o correos electrónicos a través de la cual se recabe la información.'
      },
      {
        type: TermType.PARAGRAPH,
        content:
          'Al usar los servicios tenemos su consentimiento de utilizar su información personal cuando creemos que es necesario o apropiado para cumplir con las leyes aplicables (incluidas las leyes contra el lavado de dinero (AML) y los requisitos de conocimiento de su cliente (KYC)), solicitudes legales y procesos legales, como para responder a citaciones o solicitudes de las autoridades gubernamentales.'
      }
    ]
  },
  {
    title: 'COOKIES y WEB BEACONS',
    terms: [
      {
        type: TermType.PARAGRAPH,
        content:
          'Las cookies son pequeños archivos de texto que un servidor de web envía a la computadora del Usuario al entra al sitio Web de Watt2Trade mismas que sirven para poder brindarle una mejor atención respecto de nuestros productos y/o servicios. Las cookies de una sesión recuerdan la actividad que Usted llevó a cabo anteriormente en el sitio web de Watt2Trade. Las cookies persistentes incluso hacen posible guardar sus preferencias en las diversas sesiones en que visite el sitio web, lo cual nos permite personalizar, por ejemplo, la manera en que dicho sitio ponga a disposición del Usuario la información de su interés.'
      },
      {
        type: TermType.PARAGRAPH,
        content:
          'Así mismo, las cookies pueden utilizarse para evitar que mostremos avisos, noticias y/o recomendaciones que pudieran interesarle, de conformidad con sus actividades anteriores en nuestro sitio web. Las cookies también se usan para implementar estrategias que nos permitan elevar la calidad de nuestros productos y/o servicios.'
      },
      {
        type: TermType.PARAGRAPH,
        content:
          'Los contadores de visita o web beacons, son por lo general imágenes gráficas que se ponen en un sitio web y se utilizan para contar el número de visitantes a un sitio web o incluso pueden permitir el acceso a algunas cookies. El uso de beacons en nuestro sitio web tiene la finalidad de reflejar estadísticas respecto de los productos y/o servicios que son de su interés. Estos contadores de visita normalmente no recopilan información diferente de la que el navegador proporciona como parte estándar de cualquier navegación en internet. Si Usted eligiera la opción de desactivación de las cookies en su explorador de internet, el contador de visitas no podrá seguir rastreando específicamente su actividad.'
      },
      {
        type: TermType.PARAGRAPH,
        content:
          'Algunos sitios web o aplicaciones de nuestra página web podrán utilizar objetos almacenados localmente, como son las cookies de Flash o almacenamiento local con HTML5. Este tipo de objetos almacenados localmente se utilizan para fines similares a los de las cookies pero generalmente pueden contener mayor cantidad de datos diferentes a los de las cookies del navegador. Usted puede modificar la configuración de su reproductor Flash mediante el Administrador de configuración de conformidad con su preferencia sobre el almacenamiento de objetos locales compartidos, inclusive desactivar objetos locales compartidos solamente para determinados sitios web o desactivar totalmente el almacenamiento de objetos locales compartidos para todos los sitios web.'
      },
      {
        type: TermType.PARAGRAPH,
        content:
          'La mayoría de los navegadores o exploradores de internet le permiten desactivar o permitir el uso de cookies. Usted también puede eliminar las cookies de su computadora si su navegador se lo permite. Si desactiva las cookies, es posible que no pueda usar determinadas partes de nuestro sitio web y que sea necesario reinstalar una cookie de rechazo. En todo caso, Usted contará con diversas opciones para limitar el acceso de las cookies y web beacons de nuestro sitio web en su computadora.'
      }
    ]
  },
  {
    title: '¿CÓMO LOS PROTEGEMOS?',
    terms: [
      {
        type: TermType.PARAGRAPH,
        content:
          'Watt2Trade utiliza la tecnología más avanzada a su alcance para proteger los Datos Personales proporcionados. Esta tecnología, en el caso del sitio Web, almacena de forma segura y previene la intercepción de los Datos Personales suministrados. Watt2Trade, a través de la tecnología más avanzada a su alcance, establecerá medidas de seguridad: administrativas, técnicas y físicas que permitan proteger los datos personales contra daño, pérdida, alteración, destrucción, o el uso, acceso o tratamiento no autorizado.'
      }
    ]
  },
  {
    title: '¿CON QUIÉNES LOS COMPARTIMOS?',
    terms: [
      {
        type: TermType.PARAGRAPH,
        content:
          'Para cumplir las finalidades descritas sus datos podrán ser remitidos a proveedores, clientes, distribuidores o partes relacionadas o socios comerciales de Watt2Trade, ya sean nacionales o extranjeros, los cuales, en su caso, destinarán los datos personales a las mismas finalidades descritas en el presente Aviso de Privacidad.'
      },
      {
        type: TermType.PARAGRAPH,
        content:
          'El consentimiento expreso y/o por escrito para el tratamiento de los datos o transferencias que así lo requieran será recabado a través de las solicitudes, registros, formatos, contratos o cualquier otro medio que para el efecto establezca Watt2Trade. Las remisiones para las finalidades aquí señaladas no requieren de su consentimiento por situarse dentro de las excepciones del artículo 37 de la Ley, sin embargo, es muy importante para Watt2Trade, que los usted las conozca.'
      }
    ]
  },
  {
    title: 'SUS DERECHOS',
    terms: [
      {
        type: TermType.PARAGRAPH,
        content:
          'Usted tendrá derecho a ejercer en todo momento su derecho de acceso, rectificación, cancelación u oposición, así como revocar su consentimiento según lo establecido en la Ley y en el presente Aviso de Privacidad.'
      },
      {
        type: TermType.PARAGRAPH,
        content:
          'Para ejercitar dichos derechos deberán hacernos llegar un correo electrónico a: privacy@watt2trade.com'
      },
      {
        type: TermType.PARAGRAPH,
        content:
          'Watt2Trade dará respuesta a las solicitudes para el ejercicio de los derechos a que se refiere el presente Aviso de Privacidad, en un máximo de 20 días hábiles o en su caso notificará a usted la ampliación de este término cuando existan causas que lo justifiquen. Para la atención de cualquiera de los derechos o la revocación del consentimiento será necesario:'
      },
      {
        type: TermType.ORDERED_LIST,
        content: [
          'Se acredite la identidad del titular o su representante legal con copia de identificación oficial, y documento donde consten las facultades del representante.',
          'Se adjunte a la solicitud los requisitos del artículo 29 de la Ley.'
        ]
      },
      {
        type: TermType.PARAGRAPH,
        content:
          'El medio por el cual requiere se le notifique, obtenga información y de respuesta a su solicitud de entre los cuales podrá elegir: a) vía correo electrónico (mensajes de datos) o; b) vía física (copias simples); en este último caso el titular o su representante deberán recoger personalmente las notificaciones en las instalaciones de Watt2Trade.'
      },
      {
        type: TermType.PARAGRAPH,
        content:
          'Para el caso del derecho de acceso Watt2Trade podrá poner a su disposición copias simples, certificadas, archivos de audio, video, imágenes o mensajes de datos según corresponda.'
      },
      {
        type: TermType.PARAGRAPH,
        content:
          'Usted en cualquier momento puede limitar el uso o transferencia de sus datos informando Watt2Trade dicha circunstancia Sin embargo, estos datos son necesarios para las finalidades descritas en el presente Aviso de Privacidad por lo que al no contar con ellos o no permitir su obtención, impediría estar en condiciones para cumplir las finalidades .Para el caso de las finalidades de publicidad y mercadotecnia el Usuario puede, adicional a lo contenido en el párrafo anterior, inscribirse en el Registro Público de Consumidores previsto en la Ley Federal de Protección al Consumidor.'
      }
    ]
  },
  {
    title: 'ACTUALIZACIONES Y DUDAS',
    terms: [
      {
        type: TermType.PARAGRAPH,
        content:
          'Watt2Trade mantiene revisiones y actualizaciones constantes al presente Aviso de Privacidad, por lo que es necesario reservarse sus modificaciones. Su última actualización fue realizada: el 18 de agosto de 2023.'
      },
      {
        type: TermType.PARAGRAPH,
        content:
          'Cualquier modificación al presente Aviso de Privacidad será publicada y estará disponible publicado en el sitio Web de Watt2Trade y podrá ser solicitada en cualquier momento por usted ante Watt2Trade.'
      },
      {
        type: TermType.PARAGRAPH,
        content:
          'SI TIENE ALGUNA DUDA O PREGUNTA RESPECTO AL PRESENTE AVISO DE PRIVACIDAD, POR FAVOR CONTÁCTENOS EN LA SIGUIENTE DIRECCIÓN: privacy@watt2trade.com'
      }
    ]
  }
];
export const PrivacyPolicyContent = content.map((x, idx) => ({ ...x, id: idx + 1 }));
