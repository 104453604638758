import { COLORS } from '../../../theme';

export const ThemeColors = {
  Tooltip: {
    light: {
      borderColor: COLORS.blonde
    },
    dark: {
      borderColor: '#AD8800'
    }
  }
};
