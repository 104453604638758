import { Grid } from '@mui/material';
import styled from 'styled-components';
import { Box, StyledText } from '../../../common/components';
import { TTheme } from '../../../common/types';
import { ThemeColors } from './theme';

export const StyledForm = styled.form`
  width: 100%;
  margin-top: 30px;
`;

export const StyledEstimationWrapper = styled(Grid)`
  border: 1px solid
    ${({ theme: { type } }) => ThemeColors.EstimationSection[type as TTheme].borderColor};
  background-color: ${({ theme: { type } }) =>
    ThemeColors.EstimationSection[type as TTheme].background};
`;

export const StyledEstimationSection = styled(Box)`
  flex-direction: column;
  border-bottom: 1px solid
    ${({ theme: { type } }) => ThemeColors.EstimationSection[type as TTheme].borderColor};
  padding-bottom: 12px;
`;

export const SecondaryStyledText = styled(StyledText)`
  color: ${({ theme: { type } }) =>
    ThemeColors.EstimationSection[type as TTheme].secondaryTextColor};
`;
