import { EnhancedWithAuthHttpService } from './http-auth.service';
import { HttpService } from './http.service';
import { MockDB, MockHttpClient } from './mock-http.service';
import { axiosInstance } from './main-axios';

export class HttpFactoryService {
  static createHttpService(): HttpService {
    return process.env.REACT_APP_MOCK === 'enabled'
      ? new HttpService(new MockHttpClient(MockDB.connection()))
      : new HttpService(axiosInstance);
  }

  static createAuthHttpService() {
    return new EnhancedWithAuthHttpService(this.createHttpService());
  }
}
