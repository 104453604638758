export const COLORS = {
  primary: '#1E212B',
  primaryOrange: '#FFC800',
  primaryOrangeLighter: '#FFD63F',
  darkBlue: '#1E212B',
  orange40: '#FFE58A',
  secondary: '#141414',
  white: '#ffffff',
  black: '#000000',
  grey: '#e9e9e9',
  green: '#00A40B',
  blue: '#0157FC',
  transparent: 'transparent',
  darkGrey: '#313131',
  red: '#c61825',
  orange: '#fed101',
  darkOrange: '#ef7300',
  primaryBlue: '#0089D6',
  primaryGreen: '#4BB543',
  purple: '#E40040',
  discord: '#7289da',
  semiTransparentWhite: 'rgba(0, 0, 0, 0.1)',
  semiTransparentGrey: 'rgba(0, 0, 0, 0.16)',
  halfTransparentGrey: 'rgba(145, 143, 145, 0.5)',
  lightGrey: '#f5f5f5',
  darkerGrey: '#8e8e8e',
  lightBlue: '#455aeb',
  violetBlue: '#4344ce',
  philippineYellow: '#FFC800',
  princetonOrange: '#FF8427',
  vividCerulean: '#00A3FF',
  darkGunmetal: '#1E212B',
  cosmicLatte: '#FFF9E5',
  lust: '#E42121',
  apple: '#4BB543',
  darkElectricBlue: '#5D6378',
  blonde: '#FFEFB7',
  azureishWhite: '#DFE5F7',
  ghostWhite: '#FAFBFD',
  antiFlashWhite: '#EDF0FA',
  coolGrey: '#8D94AB',
  darkBluePrimary: '#8D94AA',
  secondaryOrange: '#FFB11A',
  mustard: '#ffdc5c',
  errorRed: '#FF8F8F',
  successGreen: '#36932F',
  lightPeriwinkle: '#c3c9de',
  slateGray: '#747b91',
  gargoyleGas: '#ffd63f',
  darkBlue100: '#323645',
  orange70: '#D6A800',
  bronzeYellow: '#856800',
  independence: '#464C5E',
  emptyDark: 'rgba(30, 33, 43, 0.5)',
  emptyLight: 'rgba(255, 255, 255, 0.5)',
  periwinkle: '#C3C9DD',
  successGreen50: '#7EF975',
  errorRed30: '#FF8F8F',
  blue40: '#5CC4FF'
};
