import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../common/components';
import { ROUTER_KEYS } from '../common/consts/app-keys.const';
import { useVerify } from '../common/hooks/use-verify.hooks';

export const AuthCallback: React.FC = () => {
  const { handleRedirectResult } = useVerify();
  const navigate = useNavigate();

  React.useEffect(() => {
    handleRedirectResult()
      .then(() => navigate(ROUTER_KEYS.HOME))
      .catch(() => navigate(ROUTER_KEYS.AUTH));
  }, []);
  return <Loader isLoading isLoader />;
};
