import styled from 'styled-components';
import { Text } from '../../../common/components';
import { FAMILIES, WEIGHTS } from '../../../theme/fonts.const';
import income from '../../../../assets/image/incomeArrow.svg';
import outcome from '../../../../assets/image/outcomeArrow.svg';
import { TTheme } from '../../../common/types';
import { ThemeColors } from '../../../common/consts';

export const StyledOperation = styled(Text)<{ isInCome: boolean }>`
  color: ${({ isInCome, theme: { type } }) =>
    isInCome
      ? ThemeColors.status[type as TTheme].success
      : ThemeColors.status[type as TTheme].error};

  font-family: ${FAMILIES.inter};
  font-weight: ${WEIGHTS.normal};
  font-size: 14px;
  margin-left: 10px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: 59%;
    right: -12px;
    width: 6px;
    height: 3px;
    background-image: ${({ isInCome }) => (isInCome ? `url(${income})` : `url(${outcome})`)};
    background-repeat: no-repeat;
    background-size: contain;
  }
`;
