import Zoom from '@mui/material/Zoom';
import { Arrow } from './index.styled';

interface IProps {
  title: string | JSX.Element;
  children: JSX.Element;
}

export const ArrowTooltip = ({ title, children }: IProps) => (
  <Arrow title={title} arrow placement="top" TransitionComponent={Zoom}>
    {children}
  </Arrow>
);
