import { CoinType } from '../../../common/types/transaction.type';

export const defaultBalance = {
  name: 'Polygon',
  symbol: CoinType.MATIC,
  balance: 0,
  toRateBalance: 0
};

export const initialValues = { address: '', token: CoinType.MATIC, amount: undefined };
