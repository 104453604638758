import styled from 'styled-components';
import { Box } from '../../../../../index.styled';
import { ThemeColors } from '../../theme';
import { TTheme } from '../../../../../../types';
import { FAMILIES, WEIGHTS } from '../../../../../../../theme/fonts.const';

export const Container = styled(Box)`
  background: ${({ theme: { type } }) => ThemeColors.container[type as TTheme].background};
  border: 1px solid ${({ theme: { type } }) => ThemeColors.container[type as TTheme].border};
  border-radius: 12px;
`;

export const HeadBlock = styled.div`
  display: flex;
  border-bottom: 1px solid ${({ theme: { type } }) => ThemeColors.container[type as TTheme].border};
  padding: 8px 0 12px;
  justify-content: space-between;
  margin: 0 7px;
  color: ${({ theme: { type } }) => ThemeColors.container[type as TTheme].header};

  p > {
    margin-left: 10px;
    font-weight: ${WEIGHTS.medium};
    font-family: ${FAMILIES.inter};
    font-size: 18px;
    line-height: 22px;
  }
`;

export const Text = styled.p`
  font-weight: ${WEIGHTS.normal};
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme: { type } }) => ThemeColors.container[type as TTheme].grayText};
  font-family: ${FAMILIES.inter};
`;

export const TotalContainer = styled(Box)`
  justify-content: space-between;
  align-items: center;
  padding: 27px 0 23px;
  border-top: 1px solid ${({ theme: { type } }) => ThemeColors.container[type as TTheme].border};
  margin: 0 10px;
`;

export const Total = styled.p`
  font-weight: ${WEIGHTS.medium};
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme: { type } }) => ThemeColors.container[type as TTheme].header};
  font-family: ${FAMILIES.inter};
`;

export const ConvertedAmountBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const ConvertedAmount = styled(Text)`
  text-align: end;
  font-size: 14px;
  font-family: ${FAMILIES.inter};
`;

export const Amount = styled.p`
  color: ${({ theme: { type } }) => ThemeColors.container[type as TTheme].header};
  font-weight: ${WEIGHTS.normal};
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 3px;
  font-family: ${FAMILIES.inter};
`;
