import styled from 'styled-components';
import { TTheme } from '../../types';
import { ThemeColors } from './theme';
import { ToolTip } from './tooltip';

interface Props {
  noSpace?: boolean;
}

export const Arrow = styled(ToolTip)<Props>`
  .MuiTooltip-tooltip {
    border: 1px solid ${({ theme: { type } }) => ThemeColors.Tooltip[type as TTheme].borderColor};
  }
  .MuiTooltip-arrow::before {
    border: 1px solid ${({ theme: { type } }) => ThemeColors.Tooltip[type as TTheme].borderColor};
  }
  .css-1k51tf5-MuiTooltip-tooltip {
    margin-bottom: ${(props) => (props.noSpace ? '0px !important' : '8px !important')};
  }
`;
