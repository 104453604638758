import { ITransaction } from '../../../../../../types/transaction.type';
import { splitByCapitalLetters } from '../../../../../../utils/split-by-capital.util';
import { toFixedWithoutRounding } from '../../../../../../utils/to-fixed-without-rounding.util';
import { generateDateWithIndicator } from '../../utils/date.utils';

const unixTimestampKeys = [
  'withdrawalstart',
  'withdrawalend',
  'liquidationstart',
  'liquidationend',
  'date'
];

export const generateExtraData = (props: ITransaction['dexEvent']) =>
  Object.keys(props)
    .filter(
      (x) =>
        x !== 'eventName' &&
        !x.includes('value') &&
        x !== 'wattsMatched' &&
        x !== 'placementIndex' &&
        x !== 'startKilowatt' &&
        x !== 'startWatt'
    )
    .map((x) => {
      let title = '';
      title = splitByCapitalLetters(x);
      if (title.includes(' Id')) {
        title = title.replaceAll(' Id', ' ID');
      }
      if (title.includes('Fbt ')) {
        title = title.replaceAll('Fbt ', 'FBT ');
      }
      if (x === 'wattsConsumed') title = 'Energy Coins Used';
      let text = props[x as keyof typeof props] ?? '';
      if (unixTimestampKeys.find((key) => x.toLowerCase().includes(key)) !== undefined) {
        text = generateDateWithIndicator(props[x as keyof typeof props] as number);
      }
      if (x.toLowerCase() === 'kilowattsconsumed') title = 'Wattoins Used';
      if (x.toLowerCase() === 'finaltradingfee') text = `${text} USDC`;
      if (x.toLowerCase() === 'quantitymatched') text = `${text} KWh`;
      if (x.toLowerCase() === 'spread' || x.toLowerCase() === 'totalspread') {
        text = `${toFixedWithoutRounding(Number(text), 2)} $`;
      }
      if (x.toLowerCase() === 'quantity') text = `${text} kWh`;
      if (x.toLowerCase() === 'liquidationprice') text = `${text} $/MWh`;
      if (x.toLowerCase() === 'liquidityrequirements') text = `${text} USDC`;

      if (x.toLowerCase() === 'strikeprice' || x.toLowerCase() === 'prime') text = `${text} $/MWh`;
      return {
        title,
        text,
        condition: props[x as keyof typeof props] !== undefined,
        margin: '12px 0 12px'
      };
    });
