import { useMemo } from 'react';
import { Box } from '../../../../../common/components';
import {
  FiatCurrency,
  matchFiatCurrencySymbol
} from '../../../../../common/consts/fiat-symbol.matcher';
import { useCurrency } from '../../../../../common/providers/currency.provider';
import { priceFormat } from '../../../../../common/utils';
import { SIZES } from '../../../../../theme/fonts.const';
import { Container, SecondaryText, StyledText } from './index.styled';

interface IProps {
  total: string;
  fees: string;
  coin: string;
}

export const TotalAmount = ({ total, fees, coin }: IProps) => {
  const { currency } = useCurrency();
  const icon = useMemo(() => matchFiatCurrencySymbol[currency as FiatCurrency], [currency]);
  return (
    <Container>
      <Box justify="space-between">
        <StyledText fontSize={SIZES.l}>Total Amount</StyledText>
        <StyledText fontSize="18px">
          {priceFormat(total, 3)} {coin}
        </StyledText>
      </Box>
      <Box justify="space-between">
        <SecondaryText fontSize="12px">Fees apply at checkout</SecondaryText>
        <SecondaryText fontSize="14px">
          {icon} {priceFormat(fees)}
        </SecondaryText>
      </Box>
    </Container>
  );
};
