import styled from 'styled-components';
import { FAMILIES, SIZES, WEIGHTS } from '../../../theme/fonts.const';
import { ThemeColors } from '../../consts';
import { TTheme } from '../../types';

const DefaulContainedButton = styled('button')`
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme: { type } }) => ThemeColors.StyledButton[type as TTheme].background};
  border: 1px solid ${({ theme: { type } }) => ThemeColors.StyledButton[type as TTheme].border};
  color: ${({ theme: { type } }) => ThemeColors.StyledButton[type as TTheme].color};
  font-size: 18px;
  width: 100%;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background: linear-gradient(135deg, #ffd63f 0%, #ffb11a 52.89%, #ffd63f 100%);
  }

  &:disabled {
    background: ${({ theme: { type } }) =>
      ThemeColors.StyledButton[type as TTheme].disabledBackground};
    border: 1px solid
      ${({ theme: { type } }) => ThemeColors.StyledButton[type as TTheme].disabledBorder};
  }

  svg {
    margin-left: 10px;
    fill: ${({ theme: { type } }) => ThemeColors.containedButton[type as TTheme].textColor};
  }

  &:hover {
    svg {
      fill: ${({ theme: { type } }) => ThemeColors.containedButton[type as TTheme].hoverColor};
    }
  }
`;

const DefaultOutlinedButton = styled('button')`
  width: 100%;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme: { type } }) => ThemeColors.outlineButton[type as TTheme].color};
  background-color: ${({ theme: { type } }) =>
    ThemeColors.outlineButton[type as TTheme].background};
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  border-radius: 8px;
  text-align: center;
  border: 1px solid ${({ theme: { type } }) => ThemeColors.outlineButton[type as TTheme].border};
  cursor: pointer;

  &:disabled {
    background-color: ${({ theme: { type } }) =>
      ThemeColors.outlineButton[type as TTheme].hoverBackground};
    cursor: default;
  }
`;

const ContainedButton = styled('button')<{ iconMargin?: string }>`
  border: 1px solid ${({ theme: { type } }) => ThemeColors.containedButton[type as TTheme].border};
  padding: 10px 12px;
  background: ${({ theme: { type } }) => ThemeColors.containedButton[type as TTheme].background};
  font-weight: ${WEIGHTS.medium};
  font-size: ${SIZES.l};
  color: ${({ theme: { type } }) => ThemeColors.containedButton[type as TTheme].textColor};
  border-radius: 12px;
  cursor: pointer;
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  transition: color 275ms ease;
  justify-content: center;

  svg {
    margin: ${(props) => (props.iconMargin ? props.iconMargin : '0 0 0 10px')};
    fill: ${({ theme: { type } }) => ThemeColors.containedButton[type as TTheme].textColor};
  }

  &:hover {
    color: ${({ theme: { type } }) => ThemeColors.containedButton[type as TTheme].hoverColor};

    svg {
      fill: ${({ theme: { type } }) => ThemeColors.containedButton[type as TTheme].hoverColor};
    }
  }
`;

const OutlinedButton = styled('button')`
  color: ${({ theme: { type } }) => ThemeColors.outlineButton[type as TTheme].color};
  background-color: ${({ theme: { type } }) =>
    ThemeColors.outlineButton[type as TTheme].background};
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  width: 100%;
  border-radius: 8px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
  border: 1px solid ${({ theme: { type } }) => ThemeColors.outlineButton[type as TTheme].border};
  cursor: pointer;

  &:disabled {
    background-color: ${({ theme: { type } }) =>
      ThemeColors.outlineButton[type as TTheme].hoverBackground};
    cursor: default;
  }
`;

const StyledButton = styled.button<{
  padding?: string;
  margin?: string;
  width?: string;
  isActive?: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 46px;
  align-items: center;
  padding: ${({ padding }) => padding || '0 53px'};
  margin: ${({ margin }) => margin || '48px auto 0'};
  background: ${({ theme: { type }, isActive }) =>
    isActive
      ? ' linear-gradient(135deg, #FFD63F 0%, #FFB11A 100%, #FFD63F 100%)'
      : ThemeColors.StyledButton[type as TTheme].background};
  border: 1px solid ${({ theme: { type } }) => ThemeColors.StyledButton[type as TTheme].border};
  color: ${({ theme: { type } }) => ThemeColors.StyledButton[type as TTheme].color};
  font-size: 18px;
  border-radius: 8px;
  cursor: pointer;
  width: ${({ width }) => width || 'auto'};
  font-family: ${FAMILIES.inter};

  &:hover {
    background: linear-gradient(135deg, #ffd63f 0%, #ffb11a 52.89%, #ffd63f 100%);
  }

  &:disabled {
    cursor: default;
    background: ${({ theme: { type } }) =>
      ThemeColors.StyledButton[type as TTheme].disabledBackground};
    border: 1px solid
      ${({ theme: { type } }) => ThemeColors.StyledButton[type as TTheme].disabledBorder};
  }
`;

export {
  ContainedButton,
  DefaulContainedButton,
  DefaultOutlinedButton,
  OutlinedButton,
  StyledButton
};
